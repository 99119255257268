import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEqual } from 'lodash';
import {
  exportCustomReportQuestions,
  importReports,
  fetchUserListFilter,
  listReports,
  updateUserListFilter,
  archiveIncidents
} from '../../api/v4';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';
import { clearUploadedAttachments } from '../../actions/attachments';
import { addMessage } from '../../actions/messages';
import {
  fetchIncidentBasicsRequest,
  setActiveIncidentRequest
} from '../../actions/incidents';
import {
  TYPE_NAMES,
  FROI_BULK_UPLOAD_TEMPLATE,
  VEHICLE_UPLOAD_TEMPLATE,
  CLAIM_STAGES,
  REACTIVE_REPORT_TYPES
} from '../../constants/constants';
import {
  getAttachmentLoadingSelector,
  getAddedAttachmentsSelector
} from '../../selectors/attachments';
import { getIsPerrp } from '../../selectors/company';
import { getLoggedInUser } from '../../selectors/users';
import reportTemplateMapper from '../../utils/reportTemplateMapper';
import { usePrevious } from '../../utils/hooks';
import AnalyticsPeriodPicker from '../../components/AnalyticsPeriodPicker';
import Button from '../../components/inputs/Button';
import CSVUploader from '../../components/CSVUploader';
import DashboardButtons from '../../components/Modal/dashboardButtons';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import List from '../../components/List';
import Modal from '../../components/Modal';
import TypeSelection from '../../components/TypeSelection';

export default function ReportStages() {
  const dispatch = useDispatch();
  const { templateId } = useParams();
  const [data, setData] = useState([]);
  const [openImportFroiModal, setOpenImportFroiModal] = useState(false);
  const [openImportProblemsModal, setOpenImportProblemsModal] = useState(false);
  const [messages, setMessages] = useState([]);
  const [openReportSelector, setOpenReportSelector] = useState(false);
  const [reportTemplateIds, setReportTemplateIds] = useState([]);
  const [empPeriod, setEmpPeriod] = useState();
  const [currentPeriod, setCurrentPeriod] = useState();
  const [listType, setListType] = useState('Active');
  const [loading, setLoading] = useState(false);

  const prevPeriod = usePrevious(currentPeriod);
  const prevTemplateId = usePrevious(templateId);
  const prevType = usePrevious(listType);
  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();
  const isPerrp = useSelector(getIsPerrp);
  const attachmentLoading = useSelector(getAttachmentLoadingSelector);
  const attachments = useSelector(getAddedAttachmentsSelector);
  const user = useSelector(getLoggedInUser);
  const loadIncident = payload => dispatch(setActiveIncidentRequest(payload));
  const reportType = reportTemplateMapper(templateId).listType;
  const modalProps = {
    reports: {
      title: 'Create New Incident Report',
      text: 'Select one or more if applicable',
      buttons: REACTIVE_REPORT_TYPES?.filter(
        rt => activeCompany?.showGeneral || rt.value !== 19
      )?.map(rt => ({
        ...rt,
        disabled:
          rt.value === 19
            ? reportTemplateIds?.length && !reportTemplateIds.includes(19)
            : reportTemplateIds?.includes(19)
      })),
      onClick: v => {
        reportTemplateIds?.includes(v)
          ? setReportTemplateIds(reportTemplateIds.filter(x => x !== v))
          : setReportTemplateIds(
              !reportTemplateIds ? [].concat(v) : reportTemplateIds.concat(v)
            );
      },
      modalSubmit: () => {
        dispatch(fetchIncidentBasicsRequest({ reportTemplateIds }));
      }
    }
  };

  useEffect(() => {
    setReportTemplateIds([parseInt(templateId, 10)]);
    fetchUserListFilter(user._id, reportType).then(r => {
      setCurrentPeriod(r);
      setEmpPeriod(r);
    });

    if (templateId === '19' && !activeCompany.showGeneral)
      setReportTemplateIds([]);
  }, [templateId, activeCompany, reportType, user]);

  useEffect(() => {
    setLoading(true);
    if (
      currentPeriod &&
      (!isEqual(prevPeriod, currentPeriod) ||
        !isEqual(prevTemplateId, templateId) ||
        !isEqual(prevType, listType))
    )
      listReports({
        templateId,
        ...currentPeriod,
        selectedType: listType
      }).then(v => {
        setData(v);
        setLoading(false);
      });
  }, [
    currentPeriod,
    prevPeriod,
    templateId,
    listType,
    prevTemplateId,
    prevType
  ]);

  const getReports = templateId =>
    listReports({ templateId, ...currentPeriod, selectedType: listType }).then(
      v => {
        setData(v);
        setLoading(false);
      }
    );

  const handleImport = () => {
    try {
      if (!attachments[0]) {
        dispatch(addMessage({ error: true, message: 'File Required' }));
      } else {
        importReports(attachments[0]?.source_url, templateId).then(
          data => {
            dispatch(
              addMessage({
                error: false,
                message: 'Imported successfully!'
              })
            );
            listReports({
              templateId,
              ...currentPeriod,
              selectedType: listType
            }).then(setData);
          },
          error => {
            setMessages(error.data);
            setOpenImportProblemsModal(true);
          }
        );
      }
    } catch (e) {
      if (e.status !== 400) throw e;

      let messages = e.data;
      if (!Array.isArray(messages)) messages = [messages];
      setMessages(messages);
      setOpenImportProblemsModal(true);
    }

    getReports(templateId);
    dispatch(clearUploadedAttachments());
    setOpenImportFroiModal(false);
  };

  const handleExport = async templateId => {
    exportCustomReportQuestions({
      templateId,
      incidents: [...data]
    }).then(src => {
      window.location = src;
    });
  };

  const columns = [
    {
      key: 'reportStage',
      label: 'Status',
      accessor: r => (r.reportStage?.label === 'Closed' ? 'Completed' : 'Open'),
      enum: ['Open', 'Completed']
    },
    {
      key: 'claimStage',
      label: 'Report Status',
      accessor: r => r.reportStage?.label,
      enum: CLAIM_STAGES
    },
    { key: 'incidentNumber' },
    {
      key: 'createdByUserId',
      label: 'Reporter',
      datatype: 'user'
    },
    {
      key: 'dateIncidentOccured',
      label: 'Date Occurred',
      datatype: 'date'
    }
  ];

  switch (templateId) {
    case '1': {
      columns.splice(2, 0, {
        key: 'driver',
        label: 'Driver',
        datatype: 'users'
      });
      break;
    }
    case '2': {
      columns.splice(
        2,
        0,
        {
          key: 'injuredEmployeeIds',
          label: 'Injured Employee(s)',
          datatype: 'users'
        },
        {
          key: 'oshaStatus',
          label: `${isPerrp ? 'PERRP' : 'OSHA'} Status`,
          enum: [
            { value: 'notStarted', label: 'Not Started' },
            { value: 'incomplete', label: 'Incomplete' },
            { value: 'recordable', label: 'Recordable' },
            { value: 'notApplicable', label: 'Not Recordable' }
          ]
        }
      );
      break;
    }
    case '3': {
      columns.splice(2, 0, {
        key: 'involvedEmployee',
        datatype: 'users'
      });
      columns.push({
        key: 'lossCategory',
        label: 'Category of Loss',
        datatype: 'list'
      });
      break;
    }
    case '4':
    case '16':
    case '18': {
      columns.splice(2, 0, {
        key: 'involvedEmployee',
        datatype: 'users'
      });
      break;
    }
    case '5': {
      columns.splice(2, 0, {
        key: 'injuredPerson',
        label: 'Injured Person'
      });
      break;
    }
    case '7': {
      columns.splice(2, 0, {
        key: 'involvedEmployee',
        datatype: 'users'
      });
      columns.push({
        key: 'nearMissCategory',
        label: 'Category of Near Miss',
        datatype: 'list'
      });
      break;
    }
    default:
  }

  if (!activeProject) {
    columns.unshift({
      key: 'projectId',
      label: 'Area',
      datatype: 'project'
    });
  }

  if (!activeGroup) {
    columns.unshift({
      key: 'locationId',
      label: 'Group/Est.',
      datatype: 'group'
    });
  }

  const header = (
    <Header
      title={TYPE_NAMES[templateId]}
      pageActionOptions={[
        {
          label:
            templateId === '2'
              ? 'Upload Employee Injury Template'
              : 'Upload Vehicle Template',
          visible: templateId === '2' || templateId === '1',
          color: 'blueOutline',
          onClick: () => setOpenImportFroiModal(true)
        },
        {
          label: 'Export Custom Report Questions',
          color: 'blueOutline',
          onClick: () => handleExport(templateId),
          visible: data?.length
        },
        {
          label: 'Create Report(s)',
          color: 'blueOutline',
          onClick: () => setOpenReportSelector('reports'),
          visible: !activeGroup?.isHidden && !activeProject?.isHidden
        }
      ]}
    />
  );
  let saveKey = `${
    listType === 'Archived' ? 'archived' : ''
  }reportStages-${templateId}`;
  const newSaveKey = data?.length
    ? !activeGroup
      ? `${saveKey}Groups`
      : activeGroup && !activeProject
      ? `${saveKey}Areas`
      : saveKey
    : null;

  return (
    <HeaderAndFooter Header={header}>
      {user.accessLevel > 400 ? (
        <TypeSelection
          selectedArray={['Active', 'Archived']}
          onClick={v => {
            setData([]);
            setListType(v);
          }}
          selected={listType}
        />
      ) : null}
      <AnalyticsPeriodPicker
        period={currentPeriod}
        onChange={value => {
          setData([]);
          setCurrentPeriod(value);
        }}
        onSave={v =>
          updateUserListFilter({
            empId: user._id,
            type: reportType,
            period: v
          }).then(r => setEmpPeriod(v))
        }
        allowAll
        savedPeriod={empPeriod}
        listPicker
      />
      <List
        saveKey={newSaveKey}
        rowClick={row =>
          loadIncident({
            _id: row._id,
            returnPath: `/app/reportStages/${templateId}`
          })
        }
        dataIsHash
        data={data?.sort((a, b) => {
          return (
            new Date(b.dateIncidentOccured) - new Date(a.dateIncidentOccured)
          );
        })}
        settings={columns}
        getRowId={r => r._id}
        actions={
          user.accessLevel > 400
            ? [
                {
                  label: `${
                    listType === 'Archived' ? 'Un-' : ''
                  }Archive Incidents`,
                  onClick: ids => {
                    archiveIncidents({
                      incidentIds: ids,
                      isArchived: listType === 'Active'
                    }).then(() => getReports(templateId));
                  },
                  visible: true
                }
              ]
            : null
        }
        loading={loading}
      />
      <Modal
        title={`Import Historical ${
          templateId === '2' ? 'FROI and Recordables' : 'Vehicle Reports'
        }`}
        titleClassName="blueHeader"
        isOpen={openImportFroiModal}
        className="modalSmall"
        onRequestClose={() => {
          dispatch(clearUploadedAttachments());
          setOpenImportFroiModal(false);
        }}
        submitButtonColor="blue"
        submitActions={handleImport}
        disableSubmit={attachmentLoading}
      >
        <Button
          color="blue"
          onClick={() => {
            var win = window.open(
              templateId === '2'
                ? FROI_BULK_UPLOAD_TEMPLATE
                : VEHICLE_UPLOAD_TEMPLATE,
              '_blank'
            );
            win.focus();
          }}
          text="Download CSV Template"
        />
        <CSVUploader documentType="FroiUpload" className="dropzone" />
      </Modal>
      <Modal
        title={`Issues with ${templateId === '2' ? 'FROI' : 'Vehicle'} Upload`}
        titleClassName="redHeader"
        isOpen={openImportProblemsModal}
        className="modalMedium"
        onRequestClose={() => setOpenImportProblemsModal(false)}
        hideCancelButton={true}
        submitActions={() => setOpenImportProblemsModal(false)}
        submitButtonText="Close"
        showXButton={true}
      >
        <ul>
          {messages?.map((issue, index) => (
            <li key={index}>{issue}</li>
          ))}
        </ul>
      </Modal>
      <DashboardButtons
        modalOpen={openReportSelector}
        modalProps={modalProps}
        handleCloseModal={() => {
          setOpenReportSelector(null);
          templateId === '19' && !activeCompany?.showGeneral
            ? setReportTemplateIds([])
            : setReportTemplateIds([parseInt(templateId, 10)]);
        }}
        modalSelection={reportTemplateIds}
        setModalSelection={setReportTemplateIds}
        company={activeCompany}
      />
    </HeaderAndFooter>
  );
}
