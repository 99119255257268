import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import history from '../../history';
import {
  fetchSafetyMeetings,
  archiveMany,
  fetchUserListFilter,
  updateUserListFilter
} from '../../api/v4';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';
import { getLoggedInUser } from '../../selectors/users';
import { usePrevious } from '../../utils/hooks';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import List from '../../components/List';
import TypeSelection from '../../components/TypeSelection';
import AnalyticsPeriodPicker from '../../components/AnalyticsPeriodPicker';

export default function SafetyMeetingList() {
  const { activeGroup, activeProject } = useActiveHeirarchy();
  const [list, setList] = useState([]);
  const [empPeriod, setEmpPeriod] = useState();
  const [currentPeriod, setCurrentPeriod] = useState();
  const [listType, setListType] = useState('Active');
  const user = useSelector(getLoggedInUser);
  const prevPeriod = usePrevious(currentPeriod);
  const prevType = usePrevious(listType);

  useEffect(() => {
    fetchUserListFilter(user._id, 'safetyMeeting').then(r => {
      setCurrentPeriod(r);
      setEmpPeriod(r);
    });
  }, [user._id]);
  useEffect(() => {
    if (
      currentPeriod &&
      (currentPeriod.mode !== 'absolute' ||
        (currentPeriod.startDate && currentPeriod.endDate)) &&
      (!isEqual(prevPeriod, currentPeriod) || !isEqual(prevType, listType))
    )
      fetchSafetyMeetings({ ...currentPeriod, selectedType: listType }).then(
        setList
      );
  }, [prevPeriod, currentPeriod, listType, prevType]);

  const handleArchive = ids => {
    if (!ids.length) return;
    archiveMany({
      type: 'safetyMeeting',
      ids: ids,
      isArchived: listType === 'Active'
    }).then(() =>
      fetchSafetyMeetings({ ...currentPeriod, selectedType: listType }).then(
        setList
      )
    );
  };

  const header = (
    <Header
      title="Safety Meetings"
      pageActionOptions={[
        {
          label: 'Create Safety Meeting',
          color: 'blueOutline',
          onClick: () => history.push(`/app/safetyMeeting/container`),
          visible: !activeGroup?.isHidden && !activeProject?.isHidden
        }
      ]?.filter(o => o.visible)}
    />
  );
  const columns = [
    {
      label: 'Meeting Title',
      key: 'title'
    },
    {
      label: 'Date Occurred',
      key: 'dateOccurred',
      datatype: 'date'
    },
    {
      label: 'Admin',
      key: 'admin',
      datatype: 'user'
    },
    {
      label: 'Attendees',
      key: 'attendees',
      datatype: 'users'
    }
  ];
  if (!activeProject && activeGroup) {
    columns.splice(1, 0, {
      key: 'projectId',
      label: 'Area',
      datatype: 'project'
    });
  }
  if (!activeGroup) {
    columns.splice(1, 0, {
      key: 'groupId',
      label: 'Group/Est.',
      datatype: 'group'
    });
  }

  return (
    <HeaderAndFooter Header={header}>
      {user.accessLevel > 400 ? (
        <TypeSelection
          selectedArray={['Active', 'Archived']}
          onClick={setListType}
          selected={listType}
        />
      ) : null}
      <AnalyticsPeriodPicker
        period={currentPeriod}
        onChange={setCurrentPeriod}
        onSave={v =>
          updateUserListFilter({
            empId: user._id,
            type: 'safetyMeeting',
            period: v
          }).then(() => setEmpPeriod(v))
        }
        allowAll
        savedPeriod={empPeriod}
        listPicker
      />
      <List
        settings={columns}
        data={list}
        dataIsHash
        saveKey="safetyMeetings"
        rowClick={row =>
          history.push(`/app/safetyMeeting/container/${row._id}`)
        }
        getRowId={row => row._id}
        actions={
          user.accessLevel > 400
            ? [
                {
                  color: 'blue',
                  label: `${
                    listType === 'Archived' ? 'Un-' : ''
                  }Archive Meetings`,
                  onClick: handleArchive
                }
              ]
            : null
        }
      />
    </HeaderAndFooter>
  );
}
