import React, { useState } from 'react';
import moment from 'moment';
import history from '../../../history';
import { updateEquipmentRequired } from '../../../api/v4';
import { SAFETY_AUDIT_STAGES } from '../../../constants/constants';
import Card from '../../Card';
import { Dropdown } from '../../inputs';
import List from '../../List';
import Modal from '../../Modal';

export default function EquipmentAuditList(props) {
  const [selectedTab, setState] = useState(0);
  const [modal, openModal] = useState(false);
  const [selectedTemplates, setSelectedTemplates] = useState([]);

  const {
    activeEquipment,
    cardClassName,
    childGroupDropdown,
    childProjectDropdown,
    activeGroupId,
    activeProjectId,
    type,
    setEquipment,
    disabled
  } = props;

  const pageType = type === 'Safety Audits' ? 'safety' : 'loto';
  const activeEquipmentField =
    pageType === 'loto' ? 'linkedLOTOTemplates' : 'linkedSafetyAuditTemplates';
  const requiredFields =
    pageType === 'loto'
      ? 'requiredLOTOTemplates'
      : 'requiredSafetyAuditTemplates';

  const listContent =
    type === 'Safety Audits'
      ? [
          activeEquipment?.linkedSafetyAudits?.filter(sa => !sa.isDeleted),
          activeEquipment?.linkedRepeatables?.filter(
            sa => sa.type === 'safetyAudits' && !sa.isDeleted
          ),
          activeEquipment?.linkedSafetyAuditTemplates
        ]
      : [
          activeEquipment?.linkedLockoutTagouts?.filter(sa => !sa.isDeleted),
          activeEquipment?.linkedRepeatables?.filter(
            sa => sa.type === 'lotoAudits' && !sa.isDeleted
          ),
          activeEquipment?.linkedLOTOTemplates
        ];

  const activeSettings = [
    { key: 'title' },
    { key: 'description' },
    {
      key: 'assignedTo',
      datatype: 'users',
      label: 'Assignee(s)'
    },
    {
      key: 'dueDate',
      label: 'Due By',
      datatype: 'date'
    },
    {
      key: 'currentWfStage',
      label: 'Stage',
      accessor: r =>
        SAFETY_AUDIT_STAGES.find(s => s.value === r.currentWfStage)?.label ??
        'Completed',
      enum: SAFETY_AUDIT_STAGES?.map(s => s.label)
    },
    {
      key: 'completedDate',
      label: 'Completed Date',
      datatype: 'date'
    }
  ];

  const repeatingColumns = [
    {
      key: 'title',
      label: `Title`
    },
    {
      key: 'assignedTo',
      datatype: 'users'
    },
    {
      key: 'lastUpdatedAt',
      label: 'Last Updated',
      datatype: 'date'
    },
    {
      key: 'repeatingOn',
      label: 'Repeat On',
      datatype: 'dow',
      frequency: 'frequency'
    }
  ];

  const templateSettings = [
    { key: 'title', label: 'Title' },
    {
      label: 'Required',
      key: activeEquipmentField,
      accessor: r => {
        const isRequired = activeEquipment[requiredFields].includes(r._id);
        return isRequired ? 'Required' : 'Not Required';
      },
      enum: ['Required', 'Not Required']
    },
    {
      key: 'lastPerformed',
      label: `${
        type === 'Safety Audits' ? 'Audit' : 'Lockout/Tagout'
      } Last Performed`,
      accessor: r => {
        const completionDates = activeEquipment.linkedSafetyAudits
          ?.filter(a => a.template === r._id && a.completedDate)
          .map(t => moment(t.completedDate));
        return completionDates?.length ? moment.max(completionDates) : null;
      },
      datatype: 'date'
    }
  ];
  const tabList = {
    selected: selectedTab,
    onClick: idx => setState(idx),
    list: [
      {
        name: 'Active',
        color: 'blue',
        count: listContent[0]?.length
      },
      {
        name: 'Repeating',
        color: 'blue',
        count: listContent[1]?.length
      },
      {
        name: 'Templates',
        color: 'blue',
        count: listContent[2]?.length
      }
    ]
  };
  const templateActions = [
    {
      label: `Set ${type} To Required List`,
      color: 'blue',
      onClick: () => {
        setSelectedTemplates(activeEquipment[requiredFields]);
        openModal(true);
      }
    }
  ];

  return (
    <>
      <Card
        title={type}
        testID={`${type} Card`}
        className={cardClassName}
        rightButton={{
          text: 'Assign',
          testID: 'Assign',
          color: 'blue',
          onClick: () =>
            history.push(`/app/audits/${pageType}/create/`, {
              audit: {
                ownerType: 'equipment',
                ownerId: activeEquipment?._id,
                groupId: activeGroupId
                  ? activeGroupId
                  : childGroupDropdown?.length === 1
                  ? childGroupDropdown[0]?.value
                  : '',
                projectId: activeProjectId
                  ? activeProjectId
                  : childProjectDropdown?.length === 1
                  ? childProjectDropdown?.projectIds[0]?.value
                  : '',
                schedule: 'immediate',
                type: type
              },
              linkedAuditTemplates:
                pageType === 'safety'
                  ? activeEquipment.linkedSafetyAuditTemplates.map(t => t._id)
                  : activeEquipment.linkedLOTOTemplates.map(t => t._id),
              equipmentGroupDropdown: childGroupDropdown,
              equipmentProjectDropdown: childProjectDropdown
            }),
          disabled: disabled
        }}
        showHeader
      >
        {selectedTab === 2 ? (
          <List
            saveKey="equipmentAudits"
            data={listContent[selectedTab]}
            dataIsHash
            settings={templateSettings}
            hideCheckboxes
            tabs={tabList}
            actions={templateActions}
          />
        ) : (
          <List
            saveKey="equipmentAudits"
            rowClick={row =>
              !selectedTab || selectedTab === 0
                ? row.isCompleted
                  ? history.push(`/app/audits/${pageType}/summary/${row._id}`)
                  : history.push(`/app/audits/${pageType}/perform/${row._id}`)
                : history.push(
                    `/app/audits/${pageType}/create/${row._id}/repeatable`,
                    {
                      linkedAuditTemplates:
                        pageType === 'safety'
                          ? activeEquipment.linkedSafetyAuditTemplates.map(
                              t => t._id
                            )
                          : activeEquipment.linkedLOTOTemplates.map(t => t._id),
                      audit: { ownerType: 'equipment' }
                    }
                  )
            }
            data={listContent[selectedTab]}
            dataIsHash
            settings={selectedTab === 1 ? repeatingColumns : activeSettings}
            tabs={tabList}
          />
        )}
      </Card>
      <Modal
        title={`Required ${type}`}
        titleClassName="blueHeader"
        isOpen={modal}
        submitButtonColor="blue"
        onRequestClose={() => openModal(false)}
        submitActions={() =>
          updateEquipmentRequired({
            _id: activeEquipment._id,
            type,
            required: selectedTemplates
          }).then(r => {
            setEquipment(r);
            openModal(false);
            setSelectedTemplates();
          })
        }
      >
        <Dropdown
          options={activeEquipment[activeEquipmentField]}
          fieldLabel={`${type} Template`}
          currentValue={selectedTemplates}
          multi
          valueKey="_id"
          labelKey="title"
          onChange={setSelectedTemplates}
          placeholder="Select a template"
          searchable
        />
      </Modal>
    </>
  );
}
