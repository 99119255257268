const base = {
  gaTrackingID: 'UA-130877160-1',
  featureToggles: {
    customDocuments: true,
    customInitialReports: true
  },
  MAX_ATTACHMENT_SIZE: 5000000,
  staticIds: {
    incidentDescription: '5b1e935ec3d1ba378af86331',
    incidentLocation: '5b1e935ec3d1ba378af8632b',
    froiInjuredEmployeeQuestion: '5b1e935ec3d1ba378af8633d',
    timeIncidentOccured: '5b1e935ec3d1ba378af86330',
    froiSpecificAction: '5b1e935ec3d1ba378af86335',
    froiBodyPartAffected: '5b1e935ec3d1ba378af86337',
    froiWhichSideOfBody: '5b1e935ec3d1ba378af86336',
    froiInjuryIllnessType: '5b1e935ec3d1ba378af8646d',
    froiObjectSubstance: '5b1e935ec3d1ba372af8646d',
    froiWorkerDoing: '5b1e935ec311ba372af8646d',
    autoDriverQuestion: '5b1e935ec3d1ba378af8644d',
    incidentSeverity: '5b1e935ec3d1ba378af86329',
    propEmployeeInvolved: '5b1e9351c3a1aa328af8635e',
    confidentialReport: '655b6a44a990180be27a421f',
    incidentSupervisor: '5b1e935ac3a1aa378af8635e',
    werePoliceCalled: '65c12c76b573bc2fa3393d68',
    wereEmergencyCalled: '65c12c76b573bc2fa3393d6b',
    otherViolence: '65c12c76b573bc2fa3393d5c',
    enviromentImpacted: '65c12c76b573bc2fa3393d72'
  },
  showClaimForCompany: ['5e1c9814c468f600365a6fd3'],
  showExposureForCompanies: ['62c611d4a0f4d30058270bc4'],
  columbusAccountId: '5f5f76be7670dd00462e8d08'
};

const dev = {
  apiUrl: 'http://localhost:3006',
  name: 'development'
};

const stage = {
  s3: {
    BUCKET: 'staging.ireportsourcetest.com'
  },
  apiGateway: {
    REGION: 'YOUR_DEV_API_GATEWAY_REGION',
    URL: 'YOUR_DEV_API_GATEWAY_URL'
  },
  cognito: {
    REGION: 'YOUR_DEV_COGNITO_REGION',
    USER_POOL_ID: 'YOUR_DEV_COGNITO_USER_POOL_ID',
    APP_CLIENT_ID: 'YOUR_DEV_COGNITO_APP_CLIENT_ID',
    IDENTITY_POOL_ID: 'YOUR_DEV_IDENTITY_POOL_ID'
  },
  apiUrl: 'https://ireportstageapi.com',
  //apiUrl: 'https://ireportprodapi.com',
  name: 'stage'
};

const uat = {
  s3: {
    BUCKET: 'uat.ireportsourcetest.com'
  },
  apiGateway: {
    REGION: 'YOUR_DEV_API_GATEWAY_REGION',
    URL: 'YOUR_DEV_API_GATEWAY_URL'
  },
  cognito: {
    REGION: 'YOUR_DEV_COGNITO_REGION',
    USER_POOL_ID: 'YOUR_DEV_COGNITO_USER_POOL_ID',
    APP_CLIENT_ID: 'YOUR_DEV_COGNITO_APP_CLIENT_ID',
    IDENTITY_POOL_ID: 'YOUR_DEV_IDENTITY_POOL_ID'
  },
  apiUrl: 'https:/ireportuatapi.com',
  name: 'uat'
};

const prod = {
  s3: {
    BUCKET: 'production.ireportsource.com'
  },
  apiGateway: {
    REGION: 'YOUR_PROD_API_GATEWAY_REGION',
    URL: 'YOUR_PROD_API_GATEWAY_URL'
  },
  cognito: {
    REGION: 'YOUR_PROD_COGNITO_REGION',
    USER_POOL_ID: 'YOUR_PROD_COGNITO_USER_POOL_ID',
    APP_CLIENT_ID: 'YOUR_PROD_COGNITO_APP_CLIENT_ID',
    IDENTITY_POOL_ID: 'YOUR_PROD_IDENTITY_POOL_ID'
  },
  //apiUrl: 'https://ireportstageapi.com',
  apiUrl: 'https://ireportprodapi.com',
  // maintenanceMessage: {
  //   message:
  //     'iReportSource is down for maintenance.  We expect to have service restored by 3 A.M. Eastern.',
  //   title: 'Maintenance'
  // },
  gaTrackingID: 'UA-124808580-1',
  name: 'production'
};

const envConfig =
  process.env.REACT_APP_STAGE === 'production'
    ? prod
    : process.env.REACT_APP_STAGE === 'stage'
    ? stage
    : process.env.REACT_APP_STAGE === 'uat'
    ? uat
    : dev;

/* TODO: figure out a way to require only if a file exists. */
const localConfig = {};

const config = {
  ...base,
  ...envConfig,
  ...localConfig
};

export default config;
