import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames/bind';
import Input from '../inputs';
import GearMenu from '../GearMenu';
import history from '../../history';
import { useActiveHeirarchy } from '../../utils/useActiveHeirarchy';
import Chevron from '../../svgs/chevron';

import styles from './styles.module.scss';

const bStyles = classnames.bind(styles);

function LeaderboardTable({
  title,
  results,
  tooltip,
  subCategory,
  id,
  cloneLeaderboard,
  deleteLeaderboard,
  downloadLeaderboard,
  groupIds,
  readOnly,
  columnName,
  showPercentage,
  jobTitles = []
}) {
  const { activeCompany } = useActiveHeirarchy();
  const [topTen, setTopTen] = useState([results?.slice(0, 10)]);
  const [order, setOrder] = useState('asc');

  useEffect(() => {
    results.reverse();
    setTopTen(results?.slice(0, 10));
  }, [order, results]);

  const groupNames = activeCompany.groups
    .filter(g => groupIds?.includes(g._id))
    .map(g => g?.name);

  let first4Groups =
    groupNames.length > 4
      ? groupNames.slice(0, 4).concat(`+ ${groupNames.length - 4}`)
      : groupNames;
  let otherNames = groupNames.slice(4);

  let first4JobTitles =
    jobTitles?.length > 4
      ? jobTitles.slice(0, 4).concat(`+ ${jobTitles.length - 4}`)
      : jobTitles;
  let otherJobTitles = jobTitles?.slice(4);

  return (
    <div className={styles.card} data-cy={title.replace('  ', ' ').trim()}>
      <div className={styles.titleRow}>
        <Input
          tooltip={tooltip?.value}
          fieldLabel={title}
          className={styles.title}
          displayTooltip={subCategory === tooltip?.label}
          name={title}
        />
        <GearMenu
          options={[
            {
              value: 'edit',
              visible: !readOnly,
              label: 'Edit Leaderboard',
              onClick: () => history.push(`/app/leaderboards/edit/${id}`)
            },
            {
              value: 'download',
              visible: true,
              label: 'Download Leaderboard',
              onClick: () => downloadLeaderboard()
            },
            {
              value: 'clone',
              visible: !readOnly,
              label: 'Clone Leaderboard',
              onClick: () => cloneLeaderboard(id)
            },
            {
              value: 'delete',
              label: 'Delete Leaderboard',
              color: 'red',
              visible: !readOnly,
              onClick: () => deleteLeaderboard(id)
            }
          ]?.filter(option => option.visible)}
          gear
        />
      </div>
      {groupNames?.length ? (
        <div className={styles.locationRow} data-for="otherGroups">
          <b>Groups:&nbsp;</b>
          {first4Groups.map((g, i) => {
            if (i === 5) {
              return (
                <>
                  <span
                    className={styles.locationName}
                    key={g}
                    data-tip
                    data-for="otherGroups"
                  >
                    {g}
                  </span>
                  <ReactTooltip className={styles.tooltip} id="otherGroups">
                    {otherNames.map(group => (
                      <span className={styles.extraGroupsBubble} key={g}>
                        {group}
                      </span>
                    ))}
                  </ReactTooltip>
                </>
              );
            } else {
              return <div className={styles.locationName}>{g}</div>;
            }
          })}
        </div>
      ) : null}
      {jobTitles?.length ? (
        <div className={styles.locationRow} data-for="otherJobTitles">
          <b>Job Titles: &nbsp;</b>
          {first4JobTitles?.map((t, i) => {
            if (i === 5) {
              return (
                <>
                  <span
                    className={styles.locationName}
                    key={t}
                    data-tip
                    data-for="otherJobTitles"
                  >
                    {t}
                  </span>
                  <ReactTooltip className={styles.tooltip} id="otherJobTitles">
                    {otherJobTitles.map(group => (
                      <span className={styles.extraGroupsBubble} key={t}>
                        {group}
                      </span>
                    ))}
                  </ReactTooltip>
                </>
              );
            } else {
              return <div className={styles.locationName}>{t}</div>;
            }
          })}
        </div>
      ) : null}
      <div className={styles.table}>
        <div className={styles.header}>Rank</div>
        <div className={styles.header}>Name</div>
        <div className={styles.columnType}>
          <div>{columnName}</div>
          <Chevron
            className={styles.buttonImage}
            width="18px"
            height="18px"
            fill="black"
            transform={order === 'desc' ? 'rotate(270)' : 'rotate(90)'}
            onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
          />
        </div>
      </div>
      <div className={styles.divider} />
      {topTen.map((r, i) => (
        <div className={bStyles(styles.table, { even: (i + 1) % 2 === 0 })}>
          <div className={styles.rank}>
            {order === 'asc' ? i + 1 : results.length - i}
          </div>
          <div className={styles.data}>{r.name}</div>
          <div className={styles.score}>
            {showPercentage ? `${r.value}%` : r.value}
          </div>
        </div>
      ))}
      {topTen.length === 0 && (
        <div className={styles.noDataMessage}>No Results</div>
      )}
    </div>
  );
}

export default LeaderboardTable;
