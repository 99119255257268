import {
  ADD_COMPANY_LOCATION_REQUEST,
  ADD_COMPANY_LOCATION_RESPONSE,
  ADD_COMPANY_REQUEST,
  ADD_COMPANY_RESPONSE,
  ADD_PROJECT_REQUEST,
  DELETE_COMPANY_LOCATION_REQUEST,
  DELETE_COMPANY_LOCATION_RESPONSE,
  DELETE_COMPANY_REQUEST,
  DELETE_COMPANY_RESPONSE,
  DELETE_PROJECT_REQUEST,
  FETCH_USER_COMPANIES_RESPONSE,
  SET_ACTIVE_COMPANY,
  SET_ACTIVE_GROUPS,
  SET_ACTIVE_LOCATION,
  SET_ACTIVE_PROJECT,
  SET_ALL_LOCATIONS,
  SET_INCIDENT_OWNER,
  UPDATE_COMPANY_LOCATION_REQUEST,
  UPDATE_COMPANY_LOCATION_RESPONSE,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_RESPONSE,
  UPDATE_PROJECT_REQUEST
} from '../constants/actionTypes';
import config from '../config/config';

export const initialState = {
  list: [],
  loading: false,
  activeCompany: {},
  activeLocationId: 0,
  activeProject: 0,
  allLocations: [],
  activeGroups: []
};

const company = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_COMPANY_LOCATION_REQUEST:
    case ADD_COMPANY_REQUEST:
    case UPDATE_COMPANY_LOCATION_REQUEST:
    case DELETE_COMPANY_LOCATION_REQUEST:
    case UPDATE_COMPANY_REQUEST:
    case DELETE_COMPANY_REQUEST:
    case ADD_PROJECT_REQUEST:
    case UPDATE_PROJECT_REQUEST:
    case DELETE_PROJECT_REQUEST:
    case SET_INCIDENT_OWNER:
      return {
        ...state,
        loading: true
      };

    case FETCH_USER_COMPANIES_RESPONSE:
      return {
        ...state,
        loading: false,
        list: payload
      };

    case SET_ACTIVE_COMPANY: {
      if (payload._id === '5d4d7dc89e07c4004c6b0e12') {
        payload.isDash = true;
        payload.hideAnalytics = false;
      } else if (config.showExposureForCompanies.includes(payload._id)) {
        payload.showExposure = true;
      }
      if (payload.accountId === config.columbusAccountId)
        payload.isColumbus = true;

      return {
        ...state,
        activeCompany: { ...payload, showAircraft: payload.isAircraftCompany }
      };
    }

    case SET_ACTIVE_LOCATION:
      return {
        ...state,
        activeLocationId: payload
      };

    case SET_ALL_LOCATIONS:
      return {
        ...state,
        allLocations: payload
      };

    case SET_ACTIVE_PROJECT:
      return {
        ...state,
        activeProject: payload
      };

    case ADD_COMPANY_RESPONSE:
      return {
        ...state,
        loading: false,
        list: [...state.list, payload]
      };

    case UPDATE_COMPANY_RESPONSE: {
      let companies = state.list;

      const index = companies.findIndex(company => company._id === payload._id);

      companies[index] = payload;

      return {
        ...state,
        loading: false,
        list: companies
      };
    }

    case DELETE_COMPANY_RESPONSE: {
      const companies = state.list;

      const index = companies.findIndex(company => company.id === payload.id);

      return {
        ...state,
        loading: false,
        list: [...companies.slice(0, index), ...companies.slice(index + 1)]
      };
    }

    case SET_ACTIVE_GROUPS:
      return {
        ...state,
        activeGroups: payload
      };

    case ADD_COMPANY_LOCATION_RESPONSE:
      return {
        ...state,
        loading: false,
        activeGroups: payload
      };

    case UPDATE_COMPANY_LOCATION_RESPONSE: {
      let groups = state.activeGroups;

      const index = groups.findIndex(group => group._id === payload._id);

      groups[index] = payload;

      return {
        ...state,
        loading: false,
        activeGroups: groups
      };
    }

    case DELETE_COMPANY_LOCATION_RESPONSE: {
      const groups = state.activeGroups;

      const index = groups.findIndex(group => group._id === payload.id);

      return {
        ...state,
        loading: false,
        activeGroups: [...groups.slice(0, index), ...groups.slice(index + 1)]
      };
    }

    default:
      return { ...state };
  }
};

export default company;
