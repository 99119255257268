import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { deleteAttachment, uploadAttachment } from '../../../api/v4';
import {
  fetchEmployee,
  createClaim,
  updateClaim,
  deleteClaim,
  fetchClaimById,
  printClaim
} from '../../../api/v4';
import config from '../../../config/config';
import history from '../../../history';
import { setActiveIncidentRequest } from '../../../actions/incidents';
import { addMessage } from '../../../actions/messages';
import { getActiveIncidentSelector } from '../../../selectors/incidents';
import { getMyUserId } from '../../../selectors/users';
import { formatFiles } from '../../../utils/attachmentSelector';
import { useActiveHeirarchy } from '../../../utils/useActiveHeirarchy';
import AttachmentUploadCard from '../../../components/AttachmentUploadCard';
import ClaimCard from '../../../components/claims/Cards';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import InstructionCard from '../../../components/claims/InstructionCard';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';
import Modal from '../../../components/Modal';
import LeftNav from '../../../components/LeftNav';
import { SubmitSaveForLaterCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';

import styles from '../claimCreate.module.scss';

export default function AutoClaim() {
  const activeIncident = useSelector(getActiveIncidentSelector);
  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();
  const myUserId = useSelector(getMyUserId);
  const employeeId =
    activeIncident?.answers?.[config.staticIds.autoDriverQuestion];
  let { claimId } = useParams();
  const dispatch = useDispatch();

  const [id, setId] = useState(null);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [overviewInfo, setOverviewInfo] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saveChangesModal, setSaveChangesModal] = useState(false);
  const [sumbitModal, setSubmitModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [incidentId, setIncidentId] = useState();
  const [attachments, setAttachments] = useState([]);
  const [accidentInfo, setAccidentInfo] = useState({});
  const [vehicleInfo, setVehicleInfo] = useState({});
  const [driverInfo, setDriverInfo] = useState({});
  const [policyInfo, setPolicyInfo] = useState({});
  const [thirdPartyVehicles, setThirdPartyVehicles] = useState([]);
  const [thirdPartyPedestrians, setThirdPartyPedestrian] = useState([]);
  const [thirdPartyBuildings, setThirdPartyBuildings] = useState([]);
  const [creating, setCreating] = useState(false);
  const [driverEditing, setDriverEditing] = useState(false);
  const [vehicleEditing, setVehicleEditing] = useState(false);
  const [accidentEditing, setAccidentEditing] = useState(false);
  const [policyEditing, setPolicyEditing] = useState(false);
  const [thirdPartyVehicleEditing, setThirdPartyVehicleEditing] = useState(
    false
  );
  const [
    thirdPartyPedestrianEditing,
    setThirdPartyPedestrianEditing
  ] = useState(false);
  const [thirdPartyBuildingEditing, setThirdPartyBuildingEditing] = useState(
    false
  );
  const [availablePolicies, setAvailablePolicies] = useState();
  useEffect(() => {
    if (!claimId) {
      setCreating(true);
      if (employeeId) {
        fetchEmployee(employeeId[0]?.value ?? employeeId[0]).then(employee => {
          setDriverInfo({
            employeeId: employee._id,
            employeeFirstName: employee?.firstName,
            employeeLastName: employee?.lastName,
            employeeGender: employee?.gender
          });
        });
      }

      if (activeProject) {
        setAccidentInfo({
          projectId: activeProject._id,
          groupId: activeGroup._id,
          claimReportedByTitle: 'EmployeeOrInternalAgent',
          seekingInsuredVehicleCoverageIndicator: 'yes'
        });
        setAvailablePolicies(activeCompany.policies);
        setPermissions(activeCompany.claimPermissions);
      } else if (activeGroup) {
        setAccidentInfo({
          groupId: activeGroup._id,
          claimReportedByTitle: 'EmployeeOrInternalAgent',
          seekingInsuredVehicleCoverageIndicator: 'yes'
        });
        setAvailablePolicies(activeCompany.policies);
        setPermissions(activeCompany.claimPermissions);
      } else if (activeCompany) {
        setAvailablePolicies(activeCompany.policies);
        setPermissions(activeCompany.claimPermissions);
      }

      const answers = activeIncident?.answers;
      if (answers) {
        let incidentLocation =
          answers[config.staticIds.incidentLocation] &&
          !answers?.[config.staticIds.incidentLocation].includes(
            'Not Applicable'
          )
            ? answers?.[config.staticIds.incidentLocation]?.split(', ')
            : '';

        if (!incidentLocation) {
          const location = activeCompany.groups.find(
            g => g._id === activeIncident.groupId
          );
          incidentLocation = [
            location.streetAddress,
            location.city,
            location.state,
            location.zip
          ];
        }

        setAccidentInfo({
          incidentDate: answers[config.staticIds.timeIncidentOccured],
          incidentDescription: answers[config.staticIds.incidentDescription],
          streetAddress: incidentLocation?.[0],
          city: incidentLocation?.[1],
          state: incidentLocation?.[2],
          zip: incidentLocation?.[3] === 'USA' ? null : incidentLocation?.[3],
          groupId: activeIncident.groupId,
          projectId: activeIncident.projectId
        });

        setVehicleInfo({
          estimatedDamageSeverityCode:
            answers[config.staticIds.incidentSeverity]?.label
        });
      }

      if (activeIncident?.incidentActivity?.attachments?.length > 0) {
        const photoEvidence = activeIncident?.incidentActivity?.attachments.filter(
          a => a.isPhotoEvidence && !a.isRemoved && !a.isRemovedByOwner
        );
        setAttachments(photoEvidence);
      }
    } else {
      fetchClaimById(claimId).then(claim => {
        setClaimInfo(claim);
      });
      setAvailablePolicies(activeCompany.policies);
      setPermissions(activeCompany.claimPermissions);
    }
  }, [
    claimId,
    activeIncident,
    activeCompany,
    employeeId,
    activeProject,
    activeGroup
  ]);

  const setClaimInfo = claim => {
    setId(claim._id);
    setIsSubmitted(claim.isSubmitted);
    setIncidentId(claim.incidentId);
    setOverviewInfo({
      claimNumber: claim.claimNumber,
      previousClaims: claim.previousClaims,
      submissionDate: claim.submissionDate,
      insuredReportNumber: claim.insuredReportNumber
    });
    setDriverInfo({
      employeeId: claim.employeeId,
      employeeFirstName: claim.employeeFirstName,
      employeeLastName: claim.employeeLastName,
      employeeGender: claim.employeeGender,
      sufferedBodilyInjuryIndicator: claim.sufferedBodilyInjuryIndicator
        ? 'yes'
        : 'no',
      extentOfInjuryCode: claim.extentOfInjuryCode,
      extentOfInjuryOtherText: claim.extentOfInjuryOtherText,
      personalPropertyDamagedIndicator: claim.personalPropertyDamagedIndicator
        ? 'yes'
        : 'no',
      personalPropertyDamageDescription:
        claim.personalPropertyDamageDescription,
      personalPropertyDescription: claim.personalPropertyDescription,
      wasDriverOrCustodianPresent: claim.wasDriverOrCustodianPresent,
      relationshipToVehicleOwner: claim.relationshipToVehicleOwner
    });
    setAccidentInfo({
      groupId: claim.groupId,
      projectId: claim.projectId,
      incidentDate: claim.incidentDate,
      streetAddress: claim.incidentLocation?.incidentStreetAddress,
      city: claim.incidentLocation?.incidentCity,
      state: claim.incidentLocation?.incidentState,
      incidentDescription: claim.incidentDescription,
      eventDescriptionCode: claim.eventDescriptionCode,
      claimNotificationOther: claim.claimNotificationOther,
      thirdPartyInvolved: claim.thirdPartyInvolved ? 'yes' : 'no',
      typeOfThirdParty: claim.typeOfThirdParty,
      reportingClaimAsRecordOnlyIndicator: claim.reportingClaimAsRecordOnlyIndicator
        ? 'yes'
        : 'no',
      insuredLocationCode: claim.insuredLocationCode,
      claimsOccurrenceAdditionalRemarks: claim.claimsOccurrenceAdditionalRemarks
    });
    setVehicleInfo({
      estimatedDamageSeverityCode: claim.estimatedDamageSeverityCode,
      make: claim.make,
      model: claim.model,
      year: claim.year,
      VIN: claim.VIN,
      vehicleOwnershipTypeCode: claim.vehicleOwnershipTypeCode,
      typeOfVehicle: claim.typeOfVehicle,
      licensePlateNumber: claim.licensePlateNumber,
      wasVehicleStopped: claim.wasVehicleStopped,
      pointOfImpactCode: claim.pointOfImpactCode
    });
    setThirdPartyVehicles({
      numberOfThirdPartyVehiclesInvolved:
        claim.numberOfThirdPartyVehiclesInvolved,
      thirdPartyVehicles: claim.thirdPartyVehicles.map(t => ({
        ...t,
        sufferedBodilyInjuryIndicator: t.sufferedBodilyInjuryIndicator
          ? 'yes'
          : 'no',
        personalPropertyDamagedIndicator: t.personalPropertyDamagedIndicator
          ? 'yes'
          : 'no',
        involvedPartyAdditionalRemarks: t.involvedPartyAdditionalRemarks
      }))
    });
    setThirdPartyPedestrian({
      numberOfThirdPartyPedestriansInvolved:
        claim.numberOfThirdPartyPedestriansInvolved,
      thirdPartyPedestrians: claim.thirdPartyPedestrians.map(t => ({
        ...t,
        sufferedBodilyInjuryIndicator: t.sufferedBodilyInjuryIndicator
          ? 'yes'
          : 'no',
        personalPropertyDamagedIndicator: t.personalPropertyDamagedIndicator
          ? 'yes'
          : 'no',
        involvedPartyAdditionalRemarks: t.involvedPartyAdditionalRemarks
      }))
    });
    setThirdPartyBuildings({
      numberOfThirdPartyBuildingsInvolved:
        claim.numberOfThirdPartyBuildingsInvolved,
      thirdPartyBuildings: claim.thirdPartyBuildings
    });
    setPolicyInfo({
      policyEffectiveDate: claim.policyId?.policyEffectiveDate,
      policyEndDate: claim.policyId?.policyExpirationDate,
      displayPolicyNumber: claim.policyId?.displayPolicyNumber,
      lineOfBusinessCode: claim.policyId?.lineOfBusinessCode
    });
    setPermissions(claim.permissions);
    setAttachments(claim.attachments);
  };

  const handleSubmit = isSubmitted => {
    const object = {
      type: 'au',
      ...driverInfo,
      sufferedBodilyInjuryIndicator:
        driverInfo.sufferedBodilyInjuryIndicator === 'yes',
      personalPropertyDamagedIndicator:
        driverInfo.personalPropertyDamagedIndicator === 'yes',
      ...accidentInfo,
      incidentLocation: {
        incidentStreetAddress: accidentInfo.streetAddress,
        incidentCity: accidentInfo.city,
        incidentState: accidentInfo.state
      },
      reportingClaimAsRecordOnlyIndicator:
        accidentInfo.reportingClaimAsRecordOnlyIndicator === 'yes',
      ...vehicleInfo,
      ...thirdPartyVehicles,
      thirdPartyVehicles: thirdPartyVehicles?.thirdPartyVehicles?.map(t => ({
        ...t,
        sufferedBodilyInjuryIndicator:
          t.sufferedBodilyInjuryIndicator === 'yes',
        personalPropertyDamagedIndicator:
          t.personalPropertyDamagedIndicator === 'yes'
      })),
      ...thirdPartyPedestrians,
      thirdPartyPedestrians: thirdPartyPedestrians?.thirdPartyPedestrians?.map(
        t => ({
          ...t,
          sufferedBodilyInjuryIndicator:
            t.sufferedBodilyInjuryIndicator === 'yes',
          personalPropertyDamagedIndicator:
            t.personalPropertyDamagedIndicator === 'yes'
        })
      ),
      ...thirdPartyBuildings,
      ...policyInfo,
      incidentId: activeIncident._id,
      isSubmitted,
      permissions: activeCompany.claimPermissions,
      attachments
    };
    if (!id) {
      createClaim(object).then(claim => {
        setClaimInfo(claim);
        setId(claim._id);
        setSubmitModal(false);
        setUnsavedChanges(false);
        setCreating(false);
        if (claim.isSubmitted) {
          dispatch(
            addMessage({ error: false, message: 'Submitted Successfully!' })
          );
        } else {
          dispatch(
            addMessage({ error: false, message: 'Saved Successfully!' })
          );
        }
      });
    } else {
      updateClaim({
        _id: id,
        ...object
      }).then(claim => {
        setEditingFalse();
        setClaimInfo(claim);
        setSubmitModal(false);
        if (claim.isSubmitted) {
          dispatch(
            addMessage({ message: 'Submitted Successfully!', error: false })
          );
        } else {
          dispatch(
            addMessage({ message: 'Saved Successfully!', error: false })
          );
        }
      });
    }
  };

  const setEditingFalse = () => {
    setAccidentEditing(false);
    setVehicleEditing(false);
    setDriverEditing(false);
    setPolicyEditing(false);
    setUnsavedChanges(false);
  };

  const handleDelete = () => {
    deleteClaim(id).then(() => history.goBack());
  };

  const handleCancel = goBack => {
    if (goBack) history.goBack();
    if (
      id &&
      (accidentEditing || vehicleEditing || driverEditing || policyEditing)
    ) {
      setEditingFalse();
    } else if (unsavedChanges && !saveChangesModal) {
      setSaveChangesModal(true);
    } else {
      history.goBack();
    }
  };

  const print = () => {
    printClaim(id).then(response => window.open(response, '_blank'));
  };

  const vehicleInfoComplete = !(
    !vehicleInfo.vehicleOwnershipTypeCode ||
    !vehicleInfo.typeOfVehicle ||
    !vehicleInfo.year ||
    !vehicleInfo.make ||
    !vehicleInfo.model ||
    !vehicleInfo.wasVehicleStopped ||
    !vehicleInfo.pointOfImpactCode ||
    !vehicleInfo.estimatedDamageSeverityCode
  );

  const driverInfoComplete = !(
    !driverInfo.employeeId ||
    !driverInfo.employeeGender ||
    !driverInfo.sufferedBodilyInjuryIndicator ||
    !driverInfo.wasDriverOrCustodianPresent ||
    !driverInfo.relationshipToVehicleOwner ||
    !driverInfo.personalPropertyDamagedIndicator
  );

  const accidentInfoComplete = !(
    !accidentInfo.groupId ||
    !accidentInfo.incidentDate ||
    !accidentInfo.incidentDescription ||
    !accidentInfo.streetAddress ||
    !accidentInfo.city ||
    !accidentInfo.state ||
    !accidentInfo.eventDescriptionCode ||
    !accidentInfo.thirdPartyInvolved
  );

  const policyInfoComplete = !(
    !policyInfo.displayPolicyNumber ||
    !policyInfo.policyEffectiveDate ||
    !policyInfo.policyEndDate ||
    !policyInfo.lineOfBusinessCode
  );

  const thirdPartyVehicleComplete = accidentInfo?.typeOfThirdParty?.includes(
    'Vehicle'
  )
    ? !(
        !thirdPartyVehicles.numberOfThirdPartyVehiclesInvolved ||
        !thirdPartyVehicles?.thirdPartyVehicles?.every(
          t =>
            t.otherInsurancePolicyNumber &&
            t.firstName &&
            t.lastName &&
            t.phoneNumber &&
            t.sufferedBodilyInjuryIndicator &&
            t.personalPropertyDamagedIndicator
        )
      )
    : true;

  const thirdPartyPedestrianComplete = accidentInfo?.typeOfThirdParty?.includes(
    'PedestrianOrBicyclist'
  )
    ? !(
        !thirdPartyPedestrians.numberOfThirdPartyPedestriansInvolved ||
        !thirdPartyPedestrians?.thirdPartyPedestrians?.every(
          t =>
            t.bicyclistOrPedestrianRoleCode &&
            t.firstName &&
            t.lastName &&
            t.phoneNumber &&
            t.sufferedBodilyInjuryIndicator &&
            t.personalPropertyDamagedIndicator
        )
      )
    : true;

  const thirdPartyBuildingComplete = accidentInfo?.typeOfThirdParty?.includes(
    'Building'
  )
    ? !(
        !thirdPartyBuildings.numberOfThirdPartyBuildingsInvolved ||
        !thirdPartyBuildings?.thirdPartyBuildings?.every(
          t =>
            t.otherPropertyDescription &&
            t.firstName &&
            t.lastName &&
            t.phoneNumber &&
            t.otherDamagedPropertiesRoleCode
        )
      )
    : true;

  const canSubmit =
    accidentInfoComplete &&
    policyInfoComplete &&
    driverInfoComplete &&
    vehicleInfoComplete &&
    thirdPartyVehicleComplete &&
    thirdPartyPedestrianComplete &&
    thirdPartyBuildingComplete;

  const isReadOnly = permissions?.find(p => p.userId === myUserId)?.readOnly;

  const addAttachments = attachment => {
    if (!attachment.length > 0) return;
    let data = formatFiles(attachment);
    uploadAttachment(data, {
      ownerType: 'claim',
      ownerId: claimId,
      isSignature: false,
      isPhotoEvidence: false
    }).then(response => {
      setAttachments([...attachments, ...response]);
    });
  };

  const deleteAtt = attachment => {
    deleteAttachment(attachment._id).then(() => {
      let a = attachments.filter(a => a !== attachment);
      setAttachments([...a]);
    });
  };

  const header = (
    <Header
      title={activeIncident?.incidentNumber}
      section="Vehicle Claim"
      pageActionOptions={[
        {
          label: 'Permissions',
          visible: !creating && !isReadOnly,
          color: 'blueOutline',
          onClick: () => history.push(`/app/permissions/Claim/${id}`)
        },
        {
          label: 'Return to Incident',
          visible: true,
          color: 'blueOutline',
          onClick: () =>
            dispatch(setActiveIncidentRequest(incidentId ?? activeIncident._id))
        },
        {
          label: 'Download Claim',
          visible: isSubmitted,
          color: 'greenOutline',
          onClick: print
        },
        {
          label: 'Delete Claim',
          visible: !creating && isSubmitted && !isReadOnly,
          color: 'redOutline',
          onClick: handleDelete
        }
      ]}
      clickBack={() =>
        unsavedChanges ? setSaveChangesModal(true) : history.goBack()
      }
      needsSaved={unsavedChanges}
    />
  );

  const footer = (
    <SubmitSaveForLaterCancelFooter
      submitButtonClick={() =>
        canSubmit ? setSubmitModal(true) : setMissingModalOpen(true)
      }
      saveButtonClick={() => handleSubmit(false)}
      cancelButtonClick={handleCancel}
      onMouseEnter={() => setMissingRequired(true)}
    />
  );

  const left = (
    <LeftNav
      items={[
        {
          id: 0,
          label: isSubmitted ? 'Overview' : 'Instructions',
          complete: true
        },
        {
          id: 1,
          label: 'Accident Information',
          complete: accidentInfoComplete
        },
        {
          id: 2,
          label: 'First Party Vehicle Information',
          complete: vehicleInfoComplete
        },
        {
          id: 3,
          label: 'First Party Driver Information',
          complete: driverInfoComplete
        },
        {
          id: 4,
          label: 'Third Party Vehicle Information',
          hide: !accidentInfo?.typeOfThirdParty?.includes('Vehicle'),
          complete: thirdPartyVehicleComplete
        },
        {
          id: 5,
          label: 'Third Party Pedestrian Information',
          hide: !accidentInfo?.typeOfThirdParty?.includes(
            'PedestrianOrBicyclist'
          ),
          complete: thirdPartyPedestrianComplete
        },
        {
          id: 6,
          label: 'Third Party Building Information',
          hide: !accidentInfo?.typeOfThirdParty?.includes('Building'),
          complete: thirdPartyBuildingComplete
        },
        {
          id: 9,
          label: 'Policy Information',
          complete: policyInfoComplete
        },
        { id: 10, label: 'Claim Attachments' }
      ]}
    />
  );

  return (
    <HeaderAndFooter
      Header={header}
      Footer={footer}
      Left={left}
      showFooter={!isSubmitted && !isReadOnly}
    >
      {isSubmitted ? (
        <ClaimCard
          name={0}
          claimSection={overviewInfo}
          title="Overview"
          viewOnly
        />
      ) : (
        <InstructionCard name={0} />
      )}
      <ClaimCard
        name={1}
        editing={creating || accidentEditing}
        viewOnly={isReadOnly}
        claimSection={accidentInfo}
        onChange={values => {
          setAccidentInfo(values);
          setUnsavedChanges(true);
        }}
        title="Accident Information"
        type="auto"
        openEdit={setAccidentEditing}
        isSubmitted={isSubmitted}
        permissions={permissions}
        missingRequired={missingRequired}
      />
      <ClaimCard
        name={2}
        editing={creating || vehicleEditing}
        viewOnly={isReadOnly}
        claimSection={vehicleInfo}
        onChange={values => {
          setVehicleInfo(values);
          setUnsavedChanges(true);
        }}
        title="First Party Vehicle Information"
        openEdit={setVehicleEditing}
        isSubmitted={isSubmitted}
        missingRequired={missingRequired}
      />
      <ClaimCard
        name={3}
        editing={creating || driverEditing}
        viewOnly={isReadOnly}
        claimSection={driverInfo}
        onChange={values => {
          setDriverInfo(values);
          setUnsavedChanges(true);
        }}
        title="First Party Driver Information"
        openEdit={setDriverEditing}
        isSubmitted={isSubmitted}
        missingRequired={missingRequired}
      />
      {accidentInfo?.typeOfThirdParty?.includes('Vehicle') ? (
        <ClaimCard
          name={4}
          editing={creating || thirdPartyVehicleEditing}
          viewOnly={isReadOnly}
          claimSection={thirdPartyVehicles}
          onChange={values => {
            setThirdPartyVehicles(values);
            setUnsavedChanges(true);
          }}
          title="Third Party Vehicle Information"
          openEdit={setThirdPartyVehicleEditing}
          isSubmitted={isSubmitted}
          missingRequired={missingRequired}
        />
      ) : null}
      {accidentInfo?.typeOfThirdParty?.includes('PedestrianOrBicyclist') ? (
        <ClaimCard
          name={5}
          editing={creating || thirdPartyPedestrianEditing}
          viewOnly={isReadOnly}
          claimSection={thirdPartyPedestrians}
          onChange={values => {
            setThirdPartyPedestrian(values);
            setUnsavedChanges(true);
          }}
          title="Third Party Pedestrian Information"
          openEdit={setThirdPartyPedestrianEditing}
          isSubmitted={isSubmitted}
          missingRequired={missingRequired}
        />
      ) : null}
      {accidentInfo?.typeOfThirdParty?.includes('Building') ? (
        <ClaimCard
          name={6}
          editing={creating || thirdPartyBuildingEditing}
          viewOnly={isReadOnly}
          claimSection={thirdPartyBuildings}
          onChange={values => {
            setThirdPartyBuildings(values);
            setUnsavedChanges(true);
          }}
          title="Third Party Building Information"
          openEdit={setThirdPartyBuildingEditing}
          isSubmitted={isSubmitted}
          missingRequired={missingRequired}
        />
      ) : null}
      <ClaimCard
        name={9}
        editing={creating || policyEditing}
        viewOnly={isReadOnly}
        claimSection={policyInfo}
        onChange={values => {
          setPolicyInfo(values);
          setUnsavedChanges(true);
        }}
        title="Policy Information"
        openEdit={setPolicyEditing}
        isSubmitted={isSubmitted}
        availablePolicies={availablePolicies}
        incidentDate={accidentInfo.incidentDate}
        type="AUTOB"
        missingRequired={missingRequired}
      />
      {console.log(thirdPartyVehicles)}
      <AttachmentUploadCard
        name={10}
        addAttachments={addAttachments}
        deleteAttachment={deleteAtt}
        isEditing={!isSubmitted}
        addedAttachments={attachments}
        attachmentsToUpload={[]}
        ownerType="claim"
        createdBy={myUserId}
      />
      <SaveChangesModal
        onRequestClose={() => setSaveChangesModal(false)}
        isOpen={saveChangesModal}
        submitActions={() => handleCancel(true)}
      />
      <DeleteItemModal
        onRequestClose={() => setDeleteModal(false)}
        isOpen={deleteModal}
        submitActions={handleDelete}
        hideCustomTemplate
        what="Claim"
      />
      <Modal
        title="Submit Claim"
        titleClassName="blueHeader"
        isOpen={sumbitModal}
        submitButtonColor="blue"
        submitButtonText="Submit"
        onRequestClose={() => setSubmitModal(false)}
        submitActions={() => handleSubmit(true)}
      >
        <div className={styles.text}>
          Once you submit the claim the data will no longer update within the
          incident or for your insurance. Make sure you have attached all
          supporting documentation before you submit.
        </div>
      </Modal>
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="Auto Claim"
      />
    </HeaderAndFooter>
  );
}
