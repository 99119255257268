import React, { useEffect, useState } from 'react';
import {
  fetchEquipmentTitles,
  fetchEquipmentExceptionReport,
  exportEquipmentExceptionReport
} from '../../api/v4';
import { EXCEPTION_REPORTING_FREQUENCY_OPTIONS } from '../../constants/constants';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';
import { Button, Dropdown, TwoColumn } from '../../components/inputs';
import Card from '../../components/Card';
import { DatePicker } from '../../components/inputs/DateTimePicker';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import HierarchySelector from '../../components/HierarchySelector';
import List from '../../components/List';
import MissingRequiredModal from '../../components/Modal/missingRequiredModal';
import { isInvalidDateRange } from '../../utils/formValidation';
import styles from './styles.module.scss';

export default function EquipmentReport() {
  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();
  const [groupIds, setGroupIds] = useState();
  const [projectIds, setProjectIds] = useState();
  const [equipmentTitles, setEquipmentTitles] = useState([]);
  const [auditTitles, setAuditTitles] = useState([]);
  const [showAllAudits, setShowAllAudits] = useState();
  const [selectedEquipment, setSelectedEquipment] = useState([]);
  const [selectedAudits, setSelectedAudits] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [rangeStartDate, setRangeStartDate] = useState(null);
  const [rangeEndDate, setRangeEndDate] = useState(null);
  const [exceptionData, setExpectionData] = useState([]);
  const [exceptionColumns, setExceptionColumns] = useState();
  const [ranReport, setRanReport] = useState();
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);

  useEffect(() => {
    fetchEquipmentTitles().then(r => {
      setEquipmentTitles(r.map(r => ({ ...r, value: r._id, label: r.title })));
    });
  }, []);

  useEffect(() => {
    let newAuditOptions = [];

    // Gather all required and linked audits
    const allRequired = equipmentTitles.flatMap(
      equipment => equipment.requiredSafetyAuditTemplates
    );
    let audits = equipmentTitles.flatMap(
      equipment => equipment.linkedSafetyAuditTemplates
    );

    // Include LOTOTemplates if the company has Lockout Tagout
    if (activeCompany?.hasLockoutTagout) {
      audits = audits.concat(
        equipmentTitles.flatMap(equipment => equipment.linkedLOTOTemplates)
      );
      allRequired.push(
        ...equipmentTitles.flatMap(equipment => equipment.requiredLOTOTemplates)
      );
    }

    // Function to map audit to desired format
    const mapAudit = audit => ({
      ...audit,
      value: audit._id,
      label: audit.title
    });

    // Filter and map audits based on selected equipment
    if (!selectedEquipment?.length) {
      newAuditOptions = audits
        .filter(
          audit =>
            showAllAudits !== 'required' || allRequired.includes(audit._id)
        )
        .map(mapAudit);
    } else {
      newAuditOptions = selectedEquipment.reduce((acc, equipmentId) => {
        const equipment = equipmentTitles.find(
          equipment => equipment._id === equipmentId
        );
        if (equipment) {
          const uniqueAudits = audits
            .filter(
              audit =>
                !acc.some(existingAudit => existingAudit._id === audit._id) &&
                (equipment.linkedLOTOTemplates.some(
                  template => template._id === audit._id
                ) ||
                  equipment.linkedSafetyAuditTemplates.some(
                    template => template._id === audit._id
                  ))
            )
            .filter(
              audit =>
                showAllAudits !== 'required' || allRequired.includes(audit._id)
            )
            .map(mapAudit);
          return acc.concat(uniqueAudits);
        }
        return acc;
      }, []);
    }

    // Remove duplicate audits
    const uniqueAuditOptions = Array.from(
      new Set(newAuditOptions.map(audit => audit._id))
    ).map(id => newAuditOptions.find(audit => audit._id === id));

    // Update state
    setSelectedAudits(selectedAudits =>
      selectedAudits?.filter(selectedAudit =>
        uniqueAuditOptions.some(audit => audit._id === selectedAudit)
      )
    );
    setAuditTitles(uniqueAuditOptions);
  }, [selectedEquipment, equipmentTitles, showAllAudits, activeCompany]);

  useEffect(() => {
    let columns = [
      {
        key: 'title',
        label: 'Equipment Name'
      }
    ];
    let allAuditNames = [];
    exceptionData.forEach(e =>
      allAuditNames.push(...e.auditData.map(a => a.title))
    );
    allAuditNames = [...new Set(allAuditNames)].map(name => ({
      key: name.toLowerCase().replace(' ', ''),
      label: name,
      datatype: 'date',
      accessor: r => r.auditData.find(r => r.title === name)?.date
    }));

    setExceptionColumns(columns.concat(allAuditNames));
  }, [exceptionData]);

  const runReport = () => {
    fetchEquipmentExceptionReport({
      groupIds,
      projectIds,
      selectedEquipment,
      selectedAudits,
      showAllAudits,
      dateRange,
      rangeStartDate,
      rangeEndDate
    }).then(setExpectionData);
    setRanReport(true);
  };

  const handleGroupChange = values => {
    setGroupIds(values);

    if (projectIds?.length) {
      const validProjectIds = activeCompany.groups
        .filter(g => values.includes(g._id))
        .map(g => g.projects.map(p => p._id))
        .reduce((acc, x) => acc.concat(x), []);

      const newProjectIds = projectIds.filter(p => validProjectIds.includes(p));
      setProjectIds(newProjectIds);
    }
  };

  const handleExportTable = async () => {
    const src = await exportEquipmentExceptionReport({
      exceptionData,
      showAllAudits
    });
    window.location = src;
  };
  const header = (
    <Header
      title="Equipment Report"
      rightButtons={{
        text: 'Export Table',
        visible: ranReport,
        color: 'blue',
        onClick: () => handleExportTable()
      }}
    />
  );

  const auditText = activeCompany.hasLockoutTagout ? 'Audit/LOTO' : 'Audit';

  return (
    <HeaderAndFooter Header={header}>
      <Card>
        <TwoColumn>
          <Dropdown
            options={[
              { value: 'all', label: `Show all ${auditText}s` },
              { value: 'required', label: `Show required ${auditText}s` }
            ]}
            fieldLabel={`Types of ${auditText}`}
            currentValue={showAllAudits}
            onChange={setShowAllAudits}
            isRequired
            touched={missingRequired && !showAllAudits}
          />
          {dateRange === 'customRange' ? (
            <TwoColumn>
              <Dropdown
                options={[
                  { value: null, label: '' },
                  ...EXCEPTION_REPORTING_FREQUENCY_OPTIONS
                ]}
                fieldLabel="Date"
                currentValue={dateRange}
                onChange={setDateRange}
                touched={
                  missingRequired &&
                  dateRange === 'customRange' &&
                  isInvalidDateRange(rangeStartDate, rangeEndDate)
                }
                tooltip="This will look for audits with a due date in the selected range"
                displayTooltip
              />
              <div
                className={{
                  display: 'flex',
                  flexDirection: 'column-reverse'
                }}
              >
                <div className={styles.datePicker}>
                  <DatePicker
                    currentValue={rangeStartDate}
                    onChange={setRangeStartDate}
                    name="startDate"
                  />
                  <DatePicker
                    currentValue={rangeEndDate}
                    onChange={setRangeEndDate}
                    name="endDate"
                  />
                </div>
                <span className={styles.dateWarning}>
                  {isInvalidDateRange(rangeStartDate, rangeEndDate)}
                </span>
              </div>
            </TwoColumn>
          ) : (
            <Dropdown
              options={[
                { value: null, label: '' },
                ...EXCEPTION_REPORTING_FREQUENCY_OPTIONS
              ]}
              fieldLabel="Date"
              currentValue={dateRange}
              onChange={setDateRange}
              tooltip={`This will look for ${auditText}(s) with a due date in the selected range`}
              displayTooltip
            />
          )}
        </TwoColumn>
        <HierarchySelector
          onGroupChange={handleGroupChange}
          groupId={activeGroup ? [activeGroup?._id] : groupIds}
          groupRequired={false}
          onProjectChange={setProjectIds}
          projectId={activeProject ? [activeProject?._id] : projectIds}
          multi
          alwaysShowGroupDropdown
          alwaysShowProjectDropdown
        />
        <TwoColumn>
          <Dropdown
            fieldLabel="Equipment Title(s)"
            options={equipmentTitles}
            currentValue={selectedEquipment}
            onChange={setSelectedEquipment}
            multi
            alphabetize
            searchable
            selectButtons
          />
          <Dropdown
            fieldLabel={`${auditText} Title(s)`}
            options={auditTitles}
            currentValue={selectedAudits}
            onChange={setSelectedAudits}
            multi
            alphabetize
            searchable
            selectButtons
          />
        </TwoColumn>
        <Button
          color="blue"
          text={ranReport ? 'Update Report' : 'Run Report'}
          onClick={() =>
            showAllAudits ? runReport() : setMissingModalOpen(true)
          }
          onMouseEnter={() => setMissingRequired(!showAllAudits)}
          className={styles.button}
        />
      </Card>
      <Card>
        {ranReport ? (
          <List data={exceptionData} dataIsHash settings={exceptionColumns} />
        ) : showAllAudits && !auditTitles?.length ? (
          <div className={styles.messageText}>
            The equipment selected does not have any associated Audits or
            Lockout/Tagout Procedures. This report will not be helpful until
            some are assigned to the equipment
          </div>
        ) : (
          <div className={styles.messageText}>
            Fill fields above to generate report
          </div>
        )}
      </Card>
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="Equipment Report"
        isRunning
      />
    </HeaderAndFooter>
  );
}
