const yearToDateDropdown = (earliestYear = 2010) => {
  const year = new Date().getFullYear();
  let dropdown = [];

  for (let i = year; i >= earliestYear; i--) {
    dropdown.push({ value: i, label: i });
  }

  return dropdown;
};

export default yearToDateDropdown;
