import React, { useEffect, useState } from 'react';
import { getHoursWorked } from '../../api/v4';
import {
  OSHA_PERRP_DROPDOWN,
  PERRP_ENTITY_CODES
} from '../../constants/constants';
import { states } from '../../constants/states';
import {
  normalizePhone,
  validZip,
  normalizeWebsite,
  normalizeBwcPolicy,
  validNAICS,
  validSIC
} from '../../utils/formValidation';
import {
  InputRow,
  Textbox,
  Dropdown,
  EmployeeDropdown,
  Checkbox
} from '../../components/inputs';
import { Button } from '../../components/inputs';
import FileCropper from '../../components/FileCropper';
import HoursTable from '../../components/HoursTable/HoursTable';

import '../form.css';

const thisYear = new Date().getFullYear();

export default function CompanyForm(props) {
  const {
    company,
    setCompany,
    isUpdate,
    addAttachment,
    addedAttachments,
    removeAttachment,
    areaGroupsDontMatch,
    setAreaGroupsDontMatch
  } = props;

  const [year, setYear] = useState(thisYear);
  const [areaHours, setAreaHours] = useState();
  const [groupHours, setGroupHours] = useState(null);

  useEffect(() => {
    if (isUpdate) {
      getHoursWorked(year).then(data => {
        setGroupHours(data.groups);
        setAreaHours(data.areas);
      });
    }
  }, [year, isUpdate]);

  const checkHours = (groupParam, areaParam) => {
    setAreaGroupsDontMatch(
      groupParam?.filter(group => {
        const allAreas = areaParam?.filter(a => a.groupId === group._id);
        if (!allAreas?.length) return false;
        const areas = allAreas.reduce(
          (a, area) => a + area?.hoursWorked.reduce((c, d) => c + (d ?? 0), 0),
          0
        );
        const groups = group?.hoursWorked?.reduce((a, b) => a + (b ?? 0), 0);
        return groups - areas < 0;
      })
    );
  };

  const onChange = value => {
    setCompany({ ...company, ...value });
  };

  return (
    <div className="companyForm">
      {isUpdate ? (
        <div className="form-fields--logo">
          <img
            src={
              company?.logoUrl ||
              require('../../assets/images/no-image-placeholder.jpg')
            }
            alt={`${company.name}'s Logo`}
          />
        </div>
      ) : null}
      <div className="form-fields--split">
        <div className="form-fields--attachmentsLeftSide">
          {addedAttachments?.length ? (
            <div className="form-fields--uploaded">
              <img
                src={addedAttachments[0].source_url}
                alt="Uploaded Logo"
                className="form-fields--attachmentsLeftSideImage"
              />
              <Button
                text="Remove Logo"
                color="red"
                onClick={removeAttachment}
                className="form-fields--hoverButton"
              />
            </div>
          ) : (
            <FileCropper
              addAttachment={addAttachment}
              activeCompany={company}
            />
          )}
        </div>
        <div className="form-fields--attachmentsRightSide">
          <Textbox
            currentValue={company?.name}
            fieldLabel="Division Name"
            onChange={v => onChange({ name: v })}
            placeholder="e.g. Aperture Science Human Resources"
            isRequired
          />
          <Textbox
            currentValue={company?.phoneNumber?.fullPhoneNumber}
            fieldLabel="Phone"
            onChange={v => {
              const phone = normalizePhone(v);
              onChange({
                phoneNumber: { ...company.phoneNumber, fullPhoneNumber: phone }
              });
            }}
            isRequired
          />
        </div>
      </div>
      <Textbox
        currentValue={company?.mainAddress?.streetAddress}
        fieldLabel="Division Address"
        onChange={v =>
          onChange({
            mainAddress: { ...company.mainAddress, streetAddress: v }
          })
        }
        isRequired
      />
      <InputRow>
        <Textbox
          currentValue={company?.mainAddress?.city}
          fieldLabel="City"
          onChange={v =>
            onChange({ mainAddress: { ...company.mainAddress, city: v } })
          }
          isRequired
        />
        <Dropdown
          currentValue={company?.mainAddress?.stateName}
          options={states}
          fieldLabel="State"
          onChange={v =>
            onChange({
              mainAddress: { ...company.mainAddress, stateName: v },
              reportingType: 1
            })
          }
          isRequired
          searchable
        />
        <Textbox
          currentValue={company?.mainAddress?.zip}
          fieldLabel="ZIP Code"
          onChange={v =>
            onChange({ mainAddress: { ...company.mainAddress, zip: v } })
          }
          isRequired
          errorMessage={validZip(company?.mainAddress?.zip)}
          showWarning={validZip(company?.mainAddress?.zip)}
        />
      </InputRow>
      <Textbox
        currentValue={company?.website}
        fieldLabel="Website"
        onChange={v => {
          const url = normalizeWebsite(v);
          onChange({ website: url });
        }}
      />
      {company?.mainAddress?.stateName === 'OH' ? (
        <Dropdown
          currentValue={company?.reportingType}
          fieldLabel="OSHA or PERRP"
          options={OSHA_PERRP_DROPDOWN}
          onChange={v =>
            onChange({
              reportingType: v,
              entityCode: v === 1 ? '' : company?.entityCode ?? '',
              bwcNumber: v === 1 ? '' : company?.bwcNumber ?? ''
            })
          }
          isRequired
        />
      ) : null}
      {company?.reportingType === 2 ? (
        <>
          <Dropdown
            currentValue={company?.entityCode}
            options={PERRP_ENTITY_CODES}
            fieldLabel="Entity Code"
            onChange={v => onChange({ entityCode: v })}
          />
          <Textbox
            currentValue={company?.bwcNumber}
            fieldLabel="BWC Policy Number"
            placeholder="e.g. 12345678-000"
            onChange={v => {
              const bwc = normalizeBwcPolicy(v);
              onChange({ bwcNumber: bwc });
            }}
            isRequired
          />
        </>
      ) : null}
      <InputRow>
        <Textbox
          currentValue={company?.NAICS}
          fieldLabel="NAICS"
          placeholder="000000"
          type="number"
          onChange={v => onChange({ NAICS: v })}
          isRequired
          errorMessage={validNAICS(company?.NAICS)}
          showWarning={validNAICS(company?.NAICS)}
          tooltip="North American Industrial Classification"
          displayTooltip
        />
        <Textbox
          currentValue={company?.SIC}
          fieldLabel="SIC"
          placeholder="0000"
          type="number"
          onChange={v => onChange({ SIC: v })}
          errorMessage={validSIC(company?.SIC)}
          showWarning={validSIC(company?.SIC)}
          tooltip="Standard Industrial Classification"
          displayTooltip
        />
      </InputRow>
      <InputRow>
        <Textbox
          currentValue={company?.yearCompanyWasFounded}
          fieldLabel="What year was the company founded?"
          placeholder="yyyy"
          onChange={v => onChange({ yearCompanyWasFounded: v })}
        />
      </InputRow>
      <Checkbox
        currentValue={company?.hasLockoutTagout}
        fieldLabel="Enable Lockout/Tagout Procedures"
        onChange={v => onChange({ hasLockoutTagout: v })}
      />
      {isUpdate ? (
        <>
          <EmployeeDropdown
            currentValue={company?.supervisorsWithAllIncidentAccess}
            fieldLabel="Supervisors with access to all Incidents in their Group/Establishment"
            tooltip="Supervisors selected will get access/notifications for all incidents in the group/establishments they have access to. In order to restrict supervisors again, remove them from this list."
            displayTooltip
            permissions={[400]}
            onChange={v => onChange({ supervisorsWithAllIncidentAccess: v })}
            multi
            searchable
            selectButtons
          />
          <EmployeeDropdown
            currentValue={company?.adminsWithConfidentialAccess}
            fieldLabel="Can see confidential reports"
            tooltip="Admins selected will get access/notifications for all confidential incidents."
            displayTooltip
            permissions={[900]}
            onChange={v => onChange({ adminsWithConfidentialAccess: v })}
            multi
            searchable
            selectButtons
          />
          <EmployeeDropdown
            currentValue={company?.usersWithCustomDocConfidentialAccess}
            fieldLabel="Can see confidential Custom Documents"
            tooltip="Users selected will get access/notifications for all confidential Custom Documents."
            displayTooltip
            permissions={[500, 900]}
            onChange={v =>
              onChange({ usersWithCustomDocConfidentialAccess: v })
            }
            multi
            searchable
            selectButtons
          />
          <EmployeeDropdown
            currentValue={company?.usersWithBoConfidentialAccess}
            fieldLabel="Can see confidential Behavior Observations"
            tooltip="Users selected will get access/notifications for all confidential Behavior Observations"
            displayTooltip
            permissions={[500, 900]}
            onChange={v => onChange({ usersWithBoConfidentialAccess: v })}
            multi
            searchable
            selectButtons
          />
          <EmployeeDropdown
            currentValue={company?.additionalIncidentSignatures}
            fieldLabel="Additional signatures needed for Employee Injury Report"
            permissions={[400, 500, 900]}
            onChange={v =>
              onChange({
                additionalIncidentSignatures: v
              })
            }
            multi
            searchable
            selectButtons
          />
          <Checkbox
            currentValue={company?.allAdditionalSignaturesMustSign}
            fieldLabel="Each user selected for additional signatures must sign report"
            tooltip="Select this if all the users need to sign. If only one signature is required, leave unchecked."
            displayTooltip
            onChange={v =>
              onChange({
                allAdditionalSignaturesMustSign: v
              })
            }
            disabled={!company?.additionalIncidentSignatures?.length}
          />
          {groupHours && areaHours ? (
            <HoursTable
              companyHours={groupHours}
              year={year}
              setYear={setYear}
              change={(fieldName, value) => {
                onChange({ groupHours: value });
                checkHours(value.hours, areaHours);
              }}
              founded={company?.yearCompanyWasFounded}
              groupOrArea="Group"
            />
          ) : null}
          {company?.groups?.some(g =>
            g.projects.some(a => a.isSeparateEstablishment)
          ) && areaHours ? (
            <>
              {areaGroupsDontMatch?.length ? (
                <span className="form-error">
                  The Areas in the following Groups add to be greater than the
                  hours set for the Group:{' '}
                  {areaGroupsDontMatch?.map(g => g.name).join(', ')}
                </span>
              ) : null}
              <HoursTable
                companyHours={areaHours}
                year={year}
                setYear={setYear}
                change={(fieldName, value) => {
                  onChange({ areaHours: value });
                  checkHours(groupHours, value.hours);
                }}
                founded={company?.yearCompanyWasFounded}
                groupOrArea="Area"
              />
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
}
