import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
  useTable,
  usePagination,
  useFilters,
  useSortBy,
  useRowSelect
} from 'react-table';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames/bind';

import { loadAllCompanyUsersRequest } from '../../actions/user';
import { usePrevious } from '../../utils/hooks';
import {
  DAILY_FREQUENCY_TEXT,
  MONTHLY_FREQUENCY_OPTIONS,
  QUARTERLY_FREQUENCY_TEXT,
  YEARLY_FREQUENCY_OPTIONS
} from '../../constants/constants';
import SvgWithTooltip from '../SvgWithTooltip';
import { getActiveCompany, getActiveLocationId } from '../../selectors/company';
import { getAllCompanyUsers } from '../../selectors/users';
import Loading from '../Loading';
import Card from '../Card';
import { GearMenu } from '../GearMenu';
import Pagination from '../Pagination';
import { InputRow, Dropdown, Textbox, Checkbox, Button } from '../inputs';
import DayOfWeekButtons from '../inputs/DayOfWeekButtons';
import { noBubble } from '../../utils/events';
import FileDownloader from '../FileDownloader';
import SortArrows from '../../svgs/sortArrows';
import {
  DateCell,
  DateFilter,
  FutureDateFilter,
  filterDate,
  DateCSVCell,
  DateTimeCSVCell
} from './dates';
import { UserCell, sortUsers, filterUsers } from './users';

import styles from './listStyles.module.scss';
const bStyles = classnames.bind(styles);

/* Lifted straight from react-table */
function toString(a) {
  if (typeof a === 'number') {
    if (isNaN(a) || a === Infinity || a === -Infinity) {
      return '';
    }
    return String(a);
  }
  if (typeof a === 'string') {
    return a;
  }
  return '';
}

const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => (
  <Textbox
    currentValue={filterValue || ''}
    onChange={e => setFilter(e || undefined)}
    placeholder="Search"
  />
);

const PageSize = ({ set, current, dataSize }) => {
  const pageSizeOptions = { [dataSize]: `All Rows` };
  [5, 10, 20, 30, 50]
    .filter(o => o < dataSize)
    .map(o => (pageSizeOptions[o] = `${o} Rows`));
  return (
    <InputRow>
      <Dropdown
        className={styles.noMargin}
        options={pageSizeOptions}
        currentValue={
          current > dataSize ? dataSize.toString() : current.toString()
        }
        onChange={v => set(parseInt(v, 10))}
        testID="Rows Dropdown"
      />
    </InputRow>
  );
};

const makeEnumFilter = options => ({ column: { filterValue, setFilter } }) => (
  <Dropdown
    multi
    options={options}
    currentValue={filterValue ?? []}
    onChange={setFilter}
    placeholder="Show All"
  />
);

const BoolFilter = ({ column: { filterValue, setFilter } }) => (
  <Dropdown
    options={{
      all: 'Show All',
      yes: 'Yes',
      no: 'No'
    }}
    currentValue={filterValue || 'all'}
    onChange={v => setFilter(v === 'all' ? undefined : v)}
  />
);

const FreqFilter = makeEnumFilter({
  weekly: 'Repeating Weekly',
  monthly: 'Repeating Monthly',
  quarterly: 'Repating Quarterly',
  yearly: 'Repeating Yearly',
  paused: 'Paused'
});

const AttachmentFilter = ({ column: { filterValue, setFilter } }) => (
  <Dropdown
    options={{
      all: 'Show All',
      yes: 'Has Attachments',
      no: 'No Attachments'
    }}
    currentValue={filterValue || 'all'}
    onChange={v => setFilter(v === 'all' ? undefined : v)}
  />
);

const UrlCell = ({ value, column }) => (
  <Button
    onClick={() => window.open(`https://${value}`, '_blank')}
    text={column.text}
    color="green"
    className={styles.noPadding}
  />
);

const GroupsFilter = ({ column: { filterValue, setFilter } }) => {
  const company = useSelector(getActiveCompany, shallowEqual);
  const groupDropdown = company?.locations
    ?.filter(l => !l.isHidden)
    ?.map(group => {
      return {
        value: group._id,
        label: group.name
      };
    });
  return (
    <Dropdown
      multi
      options={groupDropdown}
      currentValue={filterValue ?? []}
      onChange={setFilter}
      placeholder="Show All"
      searchable
    />
  );
};

function filterGroups(rows, columnIds, filter) {
  if (filter.length > 0) {
    return rows.filter(r =>
      filter.some(f => r.original[columnIds[0]]?.includes(f))
    );
  }
  return rows;
}

const ProjectFilter = ({ column: { filterValue, setFilter } }) => {
  const company = useSelector(getActiveCompany, shallowEqual);
  const group = useSelector(getActiveLocationId, shallowEqual);

  let projectDropdown = [];

  if (group) {
    projectDropdown = group.projects
      ?.filter(p => !p.isHidden)
      ?.map(p => ({
        value: p._id,
        label: p.name
      }));
  } else {
    projectDropdown = company?.locations?.map(group => {
      return group.projects
        ?.filter(p => !p.isHidden)
        ?.map(p => {
          return {
            value: p._id,
            label: p.name
          };
        });
    });
  }

  projectDropdown = projectDropdown?.reduce((acc, x) => acc.concat(x), []);

  return (
    <Dropdown
      multi
      options={projectDropdown}
      currentValue={filterValue ?? []}
      onChange={setFilter}
      placeholder="Show All"
      searchable
    />
  );
};

function filterProjects(rows, columnIds, filter) {
  if (filter.length > 0) {
    return rows.filter(r =>
      filter.some(f => r.original[columnIds[0]]?.includes(f))
    );
  }
  return rows;
}

function filterBool(rows, columnIds, filter) {
  switch (filter) {
    case 'yes':
      return rows.filter(r => r.values[columnIds[0]]);
    case 'no':
      return rows.filter(r => !r.values[columnIds[0]]);
    /* eslint-disable-next-line no-fallthrough */
    default:
    case 'all':
      return rows;
  }
}

function filterAttachments(rows, columnIds, filter) {
  switch (filter) {
    case 'yes':
      return rows.filter(r => r.values[columnIds[0]]?.length);
    case 'no':
      return rows.filter(r => !r.values[columnIds[0]]?.length);
    /* eslint-disable-next-line no-fallthrough */
    default:
    case 'all':
      return rows;
  }
}

function filterFreq(rows, columnIds, filter) {
  if (!filter?.length) return rows;
  return rows.filter(r => {
    const freq = r.values[columnIds[0]][0];
    return filter.includes(freq);
  });
}

const sortHeader = label => ({ column }) => (
  <div
    className={bStyles({
      asc: column.isSortedDesc === false,
      desc: column.isSortedDesc === true
    })}
  >
    {label}
    {column.isSorted && <SortArrows />}
  </div>
);

export const List = ({
  settings,
  data,
  className,
  actions,
  dataIsHash,
  rowClick,
  tabs,
  getRowId,
  title,
  name,
  saveKey,
  hideCheckboxes,
  hideCheckboxFor,
  wide = true,
  showErrorFor,
  errorMessage,
  loading
}) => {
  const [loaded, setLoaded] = useState(false);
  const companyUsers = useSelector(getAllCompanyUsers, shallowEqual);
  const company = useSelector(getActiveCompany, shallowEqual);
  const dispatch = useDispatch();
  const loadAllCompanyUsers = useCallback(() => {
    dispatch(loadAllCompanyUsersRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // this is to stop it from calling this over and over
    loadAllCompanyUsers();
  }, [loadAllCompanyUsers]);

  const getGroupName = useCallback(
    (accessorFunc, row) => {
      const groupId =
        typeof accessorFunc === 'function' ? accessorFunc(row) : accessorFunc;

      return company?.groups
        ?.filter(g => !g.isHidden)
        ?.find(g => g._id === groupId)?.name;
    },
    [company]
  );

  const getProjectName = useCallback(
    (accessorFunc, row) => {
      const projectId =
        typeof accessorFunc === 'function' ? accessorFunc(row) : accessorFunc;

      const projectList = company?.groups
        ?.filter(g => !g.isHidden)
        .map(g => {
          return g.projects?.map(p => p);
        })
        .reduce((acc, x) => acc.concat(x), []);

      return projectList
        ?.filter(p => !p.isHidden)
        .find(g => g._id === projectId)?.name;
    },
    [company]
  );

  const columns = useMemo(() => {
    let columns = (settings ?? []).map((t, i) => {
      let label = t.label;
      let columnKey = dataIsHash ? t.key : i;
      if (!label) {
        const spaced = t.key.replace(/([A-Z])/g, ' $1');
        label = spaced.charAt(0).toUpperCase() + spaced.slice(1);
      }
      let accessor = t.accessor ?? (r => r[columnKey]);
      let type = {
        Header: sortHeader(label),
        accessor,
        id: t.key,
        disableFilters: t.disableFilterBy,
        Filter: DefaultColumnFilter,
        disableSortBy: t.disableSortBy,
        onclick: t.onclick,
        sortType: (rowA, rowB, columnId) => {
          const a = toString(rowA.values[columnId]);
          const b = toString(rowB.values[columnId]);

          return a.localeCompare(b, 'en', {
            sensitivity: 'base',
            numeric: true
          });
        }
      };

      if (t.datatype === 'date') {
        type.Cell = DateCell;
        type.filter = filterDate;
        type.Filter = DateFilter;
      } else if (t.datatype === 'datecsv') {
        type.Cell = DateCSVCell;
        type.filter = filterDate;
        type.Filter = DateFilter;
      } else if (t.datatype === 'datetimecsv') {
        type.Cell = DateTimeCSVCell;
        type.filter = filterDate;
        type.Filter = DateFilter;
      } else if (t.datatype === 'futureDate') {
        type.Cell = DateCell;
        type.filter = filterDate;
        type.Filter = FutureDateFilter;
      } else if (t.datatype === 'users' || t.datatype === 'user') {
        type.Cell = UserCell;
        type.showUserNames = true;
        type.filter = (...args) => filterUsers(companyUsers, ...args);
        type.sortType = (...args) => sortUsers(companyUsers, ...args);
      } else if (t.datatype === 'list') {
        type.accessor = r => accessor(r)?.join(', ');
      } else if (t.datatype === 'dow') {
        type.accessor = r => [
          r.isPaused ? 'paused' : r[t.frequency],
          r[columnKey]
        ];
        type.Filter = FreqFilter;
        type.filter = filterFreq;
        type.Cell = ({ value: [frequency, data] }) => {
          if (frequency === 'monthly') {
            return (
              MONTHLY_FREQUENCY_OPTIONS.find(m => m.value === data[0])?.label ??
              '--'
            );
          } else if (frequency === 'yearly') {
            return (
              YEARLY_FREQUENCY_OPTIONS.find(y => y.value === data[0])?.label ??
              '--'
            );
          } else if (frequency === 'quarterly') {
            return QUARTERLY_FREQUENCY_TEXT;
          } else if (frequency === 'daily') {
            return DAILY_FREQUENCY_TEXT;
          } else if (frequency === 'paused') {
            return 'Paused';
          } else {
            return <DayOfWeekButtons currentValue={data} disabled fromList />;
          }
        };
      } else if (t.datatype === 'trainingDow') {
        type.accessor = r => [r[t.frequency], r[columnKey]];
        type.Filter = FreqFilter;
        type.filter = filterFreq;
        type.Cell = ({ value: [frequency, data] }) => {
          if (frequency === 'monthly') {
            return (
              MONTHLY_FREQUENCY_OPTIONS.find(m => m.value === data[0])?.label ??
              '--'
            );
          } else if (frequency === 'yearly') {
            return (
              YEARLY_FREQUENCY_OPTIONS.find(y => y.value === data[0])?.label ??
              '--'
            );
          } else if (frequency === 'weekly') {
            return <DayOfWeekButtons currentValue={data} disabled fromList />;
          } else if (frequency === 'quarterly') {
            return QUARTERLY_FREQUENCY_TEXT;
          } else {
            return frequency;
          }
        };
      } else if (t.datatype === 'boolean') {
        type.Cell = ({ value }) => (value ? 'Yes' : 'No');
        type.filter = filterBool;
        type.Filter = BoolFilter;
      } else if (t.datatype === 'groups') {
        type.filter = filterGroups;
        type.accessor = r =>
          accessor(r)
            ?.map(getGroupName)
            ?.filter(g => g)
            ?.join(', ');
        type.Filter = GroupsFilter;
      } else if (t.datatype === 'group') {
        type.filter = filterGroups;
        type.accessor = r => getGroupName(accessor(r));
        type.Filter = GroupsFilter;
      } else if (t.datatype === 'project') {
        type.filter = filterProjects;
        type.accessor = r => getProjectName(accessor(r));
        type.Filter = ProjectFilter;
      } else if (t.datatype === 'projects') {
        type.filter = filterProjects;
        type.accessor = r =>
          accessor(r)
            ?.map(getProjectName)
            ?.filter(g => g)
            ?.join(', ');
        type.Filter = ProjectFilter;
      } else if (t.datatype === 'url') {
        type.Cell = UrlCell;
        type.cellProps = () => ({ onClick: noBubble });
        type.text = t.text || 'Download';
        type.Filter = <></>;
        type.disableSortBy = true;
      } else if (t.datatype === 'attachments') {
        type.Cell = ({ value }) => (
          <div>
            {value?.map((attachment, attachmentIndex) => (
              <FileDownloader
                attachment={attachment}
                key={attachmentIndex}
                attachmentIndex={attachmentIndex}
                disabled
                isInList
              />
            ))}
          </div>
        );
        type.cellProps = () => ({ onClick: noBubble });
        type.Filter = AttachmentFilter;
        type.filter = filterAttachments;
      } else if (t.datatype === 'covidAttachments') {
        type.Cell = ({ value }) => {
          if (value[0] === null) {
            return 'See Covid Exposure Page';
          }
          return (
            <div>
              {value?.map((attachment, attachmentIndex) => (
                <FileDownloader
                  attachment={attachment}
                  key={attachmentIndex}
                  attachmentIndex={attachmentIndex}
                  disabled
                  isInList
                />
              ))}
            </div>
          );
        };
        type.cellProps = () => ({ onClick: noBubble });
        type.Filter = AttachmentFilter;
        type.filter = filterAttachments;
      } else if (t.datatype === 'button') {
        type.Cell = ({ value }) => (
          <Button
            text="Resend"
            color="blue"
            onClick={() => type.onclick(value)}
          />
        );

        type.disableSortBy = true;
        type.Filter = <></>;
        type.cellProps = () => ({ onClick: noBubble });
      } else if (t.enum) {
        let e = t.enum;
        if (typeof e[0] === 'string') {
          e = e.map(o => ({ label: o, value: o }));
        }

        if (t.datatype === 'svgs') {
          type.cellProps = () => ({ title: null });
          type.Cell = ({ value }) => (
            <div style={{ display: 'flex' }}>
              {value?.map(v => (
                <SvgWithTooltip type={v} key={v} />
              ))}
            </div>
          );
        } else {
          type.Cell = ({ value }) =>
            e.find(o => o.value === value)?.label ?? '--';
        }
        type.Filter = makeEnumFilter(e);
        type.filter = (rows, columnIds, filter) => {
          if (!filter?.length) return rows;

          return rows.filter(r => {
            const v = r.values[columnIds[0]];

            if (Array.isArray(v)) {
              return v.some(o => filter.includes(o));
            } else {
              return filter.includes(v);
            }
          });
        };
        type.sortType = (rowA, rowB, columnId) => {
          const a = rowA.values[columnId];
          const b = rowB.values[columnId];

          const aIdx = e.findIndex(o => o.value === a);
          const bIdx = e.findIndex(o => o.value === b);

          return aIdx - bIdx;
        };
      } else if (t.Cell) {
        type.Cell = t.Cell;
      }
      if (t.noClick) {
        type.cellProps = () => ({ onClick: noBubble });
      }
      return type;
    });
    return columns;
  }, [settings, dataIsHash, companyUsers, getGroupName, getProjectName]);

  const initialState = { pageSize: 10 };

  const useTableArgs = [
    {
      columns,
      data: data ?? [],
      initialState,
      autoResetSortBy: false,
      autoResetPage: false,
      autoResetFilters: false,
      autoResetSelectedRows: false,
      getRowId
    },
    useFilters,
    useSortBy,
    usePagination,
    hooks => {
      hooks.getCellProps.push((props, { cell }) => ({
        ...props,
        ...cell.column.cellProps?.()
      }));
      hooks.getHeaderProps.push((props, { column }) => ({
        ...props,
        ...column.headerProps?.()
      }));
    }
  ];

  if (actions?.length && !hideCheckboxes) {
    useTableArgs.push(useRowSelect);
    useTableArgs.push(hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          Header: ({ isAllPageRowsSelected, toggleRowSelected, page }) => {
            const modifiedOnChange = event => {
              page.forEach(row => {
                !(Array.isArray(showErrorFor)
                  ? showErrorFor.some(key => row.original[key])
                  : Array.isArray(hideCheckboxFor)
                  ? hideCheckboxFor.some(key => row.original[key])
                  : row.original[hideCheckboxFor]) &&
                  toggleRowSelected(row.id, event);
              });
            };

            let selectableRowsOnCurrentPage = 0;
            let selectedRowsOnCurrentPage = 0;
            page.forEach(row => {
              row.isSelected && selectedRowsOnCurrentPage++;
              !(Array.isArray(hideCheckboxFor)
                ? hideCheckboxFor.some(key => row.original[key])
                : Array.isArray(showErrorFor)
                ? showErrorFor.some(key => row.original[key])
                : row.original[hideCheckboxFor]) &&
                selectableRowsOnCurrentPage++;
            });

            const disabled = selectableRowsOnCurrentPage === 0;
            const checked =
              (isAllPageRowsSelected ||
                selectableRowsOnCurrentPage === selectedRowsOnCurrentPage) &&
              !disabled;

            return (
              <Checkbox
                className={styles.checkbox}
                currentValue={checked}
                disabled={disabled}
                onChange={modifiedOnChange}
                testID="selectAllCheckbox"
              />
            );
          },

          Cell: ({ row }) =>
            showErrorFor &&
            (Array.isArray(showErrorFor)
              ? showErrorFor.some(key => row.original[key])
              : row.original[showErrorFor]) ? (
              <>
                <span data-tip data-for="error">
                  <img
                    src={require('../../assets/images/caution.png')}
                    alt="warning"
                    className={styles.error}
                  />
                </span>
                {errorMessage ? (
                  <ReactTooltip id="error" key="error">
                    {errorMessage}
                  </ReactTooltip>
                ) : null}
              </>
            ) : !(Array.isArray(hideCheckboxFor)
                ? hideCheckboxFor.some(key => row.original[key])
                : row.original[hideCheckboxFor]) ? (
              <Checkbox
                className={styles.checkbox}
                currentValue={row.isSelected}
                onChange={row.toggleRowSelected}
                testID="checkbox"
              />
            ) : (
              <></>
            ),
          cellProps: () => ({ className: styles.selector, onClick: noBubble }),
          headerProps: () => ({ className: styles.selector, rowSpan: 2 }),
          renderFilter: false
        },
        ...columns
      ]);
    });
  }

  const {
    allColumns,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageCount,
    state: { pageIndex, pageSize, selectedRowIds, filters },
    gotoPage,
    setPageSize,
    setAllFilters
  } = useTable(...useTableArgs);

  const prevFilters = usePrevious(filters);
  const group = useSelector(getActiveLocationId, shallowEqual);

  useEffect(() => {
    if (!saveKey) return;
    const saved = sessionStorage.getItem(`filters.${saveKey}`);
    setLoaded(true);
    if (!saved) {
      setAllFilters([]);
      return;
    }
    let json = JSON.parse(saved);
    const prevCompany = sessionStorage.getItem('activeCompany');
    if ((prevCompany !== null && prevCompany !== company._id) || group) {
      json = json.filter(
        f => f.id !== 'groupId' && f.id !== 'locationId' && f.id !== 'groupIds'
      );
    }

    setAllFilters(json);
  }, [saveKey, company._id, group, setAllFilters]);

  useEffect(() => {
    if (!loaded) return;
    if (saveKey && filters !== prevFilters) {
      sessionStorage.setItem(`filters.${saveKey}`, JSON.stringify(filters));
      sessionStorage.setItem('activeCompany', company._id);
    }
  }, [filters, prevFilters, saveKey, company, loaded]);

  const tableStyles = bStyles({
    table: true,
    hoverRow: rowClick
  });

  const updatedActions = useMemo(
    () =>
      (actions ?? [])
        .filter(l => !l.hasOwnProperty('visible') || l.visible)
        .map(a => ({
          ...a,
          onClick: a.onClick
            ? hideCheckboxes
              ? () => a.onClick()
              : () => a.onClick(Object.keys(selectedRowIds))
            : undefined
        })),
    [actions, selectedRowIds, hideCheckboxes]
  );

  const rowData = pageSize === data?.length ? rows : page;
  if (pageIndex > 1 && pageIndex > pageCount - 1)
    gotoPage(pageCount > 0 ? pageCount - 1 : 0);
  return (
    <Card
      className={className}
      tabs={tabs}
      wide={wide}
      title={title}
      showHeader={title}
      name={name}
    >
      <div className={styles.top}>
        <PageSize
          className={styles.pageSize}
          current={pageSize}
          set={setPageSize}
          dataSize={data?.length}
        />
        {pageSize && data?.length ? (
          <Pagination
            page={pageIndex}
            count={rows.length}
            perPage={pageSize}
            setPage={gotoPage}
          />
        ) : (
          <div />
        )}
        {updatedActions?.length ? (
          <GearMenu
            options={updatedActions}
            className={styles.menu}
            text="List Options"
          />
        ) : null}
      </div>
      <div className={tableStyles}>
        {!settings || !data || loading ? (
          <Loading />
        ) : (
          <table {...getTableProps()}>
            <thead className={styles.fullHeader}>
              {headerGroups.map((headerGroup, i) => (
                <React.Fragment key={i}>
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps({
                            className: styles.header
                          })
                        )}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                  <tr
                    {...headerGroup.getHeaderGroupProps({ key: 'sort-header' })}
                  >
                    {headerGroup.headers.map((column, j) =>
                      column?.renderFilter ?? true ? (
                        <th
                          className={classnames([
                            styles.filter,
                            !hideCheckboxes && !showErrorFor && actions?.length
                              ? styles.filterColumnFirst
                              : ''
                          ])}
                          key={j}
                          data-cy={`columnFilter${j}`}
                        >
                          {column.canFilter ? column.render('Filter') : ''}
                        </th>
                      ) : (
                        <React.Fragment key={j} />
                      )
                    )}
                  </tr>
                </React.Fragment>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rowData.map(row => {
                prepareRow(row);
                return (
                  <tr
                    className={
                      (Array.isArray(showErrorFor)
                      ? showErrorFor.some(key => row.original[key])
                      : row.original[showErrorFor])
                        ? styles.rowError
                        : ''
                    }
                    {...row.getRowProps({
                      onClick: rowClick
                        ? () => rowClick(row.original)
                        : undefined
                    })}
                  >
                    {row.cells.map(cell => {
                      let userNames;
                      if (cell.column?.showUserNames) {
                        let ids = Array.isArray(cell.value)
                          ? cell.value
                          : [cell.value];
                        userNames = ids?.map(id => {
                          let emp = companyUsers?.find(
                            u => u._id === id?.toString()
                          );
                          return emp?.loading
                            ? 'Loading User'
                            : emp
                            ? `${emp?.firstName} ${emp?.lastName}`
                            : 'Unknown User';
                        });
                      }
                      return (
                        <td
                          {...cell.getCellProps((props, { cell }) => ({
                            title: userNames ?? cell.value + '',
                            ...props
                          }))}
                          data-cy={cell.value}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              {!rowData.length && (
                <tr className={styles.noData}>
                  <td colSpan={allColumns.length}>
                    {filters.length ? 'No Matches' : 'No Data'}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
      <div className={styles.top}>
        <PageSize
          current={pageSize}
          set={setPageSize}
          dataSize={data?.length}
        />
        {pageSize && data?.length ? (
          <Pagination
            page={pageIndex}
            count={rows.length}
            perPage={pageSize}
            setPage={gotoPage}
          />
        ) : (
          <div />
        )}
        <Button
          text="Back to Top"
          color="blueOutline"
          className={styles.noMargin}
          onClick={() => {
            const element = document.getElementById('mainPage');
            if (element) {
              element.scrollTop = 0;
            }
          }}
        />
      </div>
    </Card>
  );
};

export default List;
