import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import history from '../../history';
import { useSocket } from '../../utils/withSocket';
import {
  fetchBehaviorObservations,
  fetchHasLegacyObservations,
  sendObservationReminder,
  archiveMany,
  fetchUserListFilter,
  updateUserListFilter
} from '../../api/v4';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';
import { addMessage } from '../../actions/messages';
import { getLoggedInUser } from '../../selectors/users';
import { usePrevious } from '../../utils/hooks';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import List from '../../components/List';
import TypeSelection from '../../components/TypeSelection';
import AnalyticsPeriodPicker from '../../components/AnalyticsPeriodPicker';

export default function BehaviorObservations() {
  const socket = useSocket();
  const dispatch = useDispatch();
  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();
  const user = useSelector(getLoggedInUser);
  const [list, setList] = useState([]);
  const [generated, setGenerated] = useState();
  const [loading, setLoading] = useState(false);
  const [hasLegacy, setHasLegacy] = useState(false);
  const [listType, setListType] = useState('Active');
  const [empPeriod, setEmpPeriod] = useState();
  const [currentPeriod, setCurrentPeriod] = useState();
  const [listLoading, setListLoading] = useState(false);
  const prevPeriod = usePrevious(currentPeriod);
  const prevType = usePrevious(listType);

  useEffect(() => {
    fetchUserListFilter(user._id, 'behaviorObservation').then(r => {
      setCurrentPeriod(r);
      setEmpPeriod(r);
    });
  }, [user._id]);

  useEffect(() => {
    if (
      currentPeriod &&
      (!isEqual(prevPeriod, currentPeriod) || !isEqual(prevType, listType))
    ) {
      fetchBehaviorObservations({
        ...currentPeriod,
        selectedType: listType
      }).then(res => {
        setList(res);
        setListLoading(false);
      });
      fetchHasLegacyObservations().then(response => setHasLegacy(response));
    }
  }, [currentPeriod, prevPeriod, listType, prevType]);

  useEffect(() => {
    if (!socket) return;
    socket.emit('subscribeLegacyObservations', {
      company: activeCompany?._id,
      group: activeGroup?._id,
      project: activeProject?._id
    });
    socket.on('observationCSV', value => {
      setGenerated(value);
      setLoading(false);
    });
    socket.on('printFail', error => {
      setGenerated(null);
      dispatch(
        addMessage({
          error: true,
          message: `Could not generate Legacy Observation CSV: ${error}`
        })
      );
    });
  }, [activeCompany, activeGroup, activeProject, dispatch, socket]);

  const handleArchive = ids => {
    if (!ids.length) return;
    archiveMany({
      type: 'behaviorObservation',
      ids: ids,
      isArchived: listType === 'Active'
    }).then(() =>
      fetchBehaviorObservations({
        ...currentPeriod,
        selectedType: listType
      }).then(setList)
    );
  };

  const name =
    generated === null
      ? 'Generate Legacy Observations CSV'
      : generated?.url
      ? 'Regenerate CSV'
      : 'Generating CSV';

  const header = (
    <Header
      title="Behavior Observations"
      pageActionOptions={[
        {
          label: 'Add Behavior Observation',
          color: 'blueOutline',
          onClick: () => history.push(`/app/behaviorObservation/container`),
          visible: !activeGroup?.isHidden && !activeProject?.isHidden
        },
        {
          label: name,
          visible: user?.accessLevel === 900 && hasLegacy,
          color: 'blueOutline',
          onClick: () => {
            socket.emit('generateObservationCSV', {
              company: activeCompany?._id,
              group: activeGroup?._id,
              project: activeProject?._id
            });
            setGenerated(false);
            setLoading(true);
          },
          disabled: loading || name === 'Generating PDF'
        },
        {
          label: 'Download CSV',
          onClick: () => window.open(generated.url, '_blank'),
          color: 'greenOutline',
          visible: generated?.url && user?.accessLevel === 900 && hasLegacy
        }
      ]?.filter(o => o.visible)}
    />
  );
  const columns = [
    {
      label: 'Behavior Observations',
      key: 'title'
    },
    {
      label: 'Employee Observed',
      key: 'employeeObserved',
      datatype: 'user'
    },
    {
      label: 'Employee Observing',
      key: 'supervisorObserving',
      datatype: 'user'
    },
    { label: 'Last Updated', key: 'updatedAt', datatype: 'date' },
    {
      label: 'Completion Date',
      key: 'completionDate',
      datatype: 'date'
    },
    {
      key: 'isConfidential',
      accessor: r => (r?.isConfidential ? 'Yes' : 'No'),
      enum: ['Yes', 'No']
    }
  ];

  if (!activeGroup) {
    columns.unshift({
      key: 'projectId',
      label: 'Area',
      datatype: 'project'
    });
  }

  if (!activeGroup) {
    columns.unshift({
      key: 'groupId',
      label: 'Group/Est.',
      datatype: 'group'
    });
  }

  return (
    <HeaderAndFooter Header={header}>
      {user.accessLevel > 400 ? (
        <TypeSelection
          selectedArray={['Active', 'Archived']}
          onClick={v => {
            setList([]);
            setListType(v);
          }}
          selected={listType}
        />
      ) : null}
      <AnalyticsPeriodPicker
        period={currentPeriod}
        onChange={setCurrentPeriod}
        onSave={v =>
          updateUserListFilter({
            empId: user._id,
            type: 'behaviorObservation',
            period: v
          }).then(() => setEmpPeriod(v))
        }
        allowAll
        savedPeriod={empPeriod}
        listPicker
      />

      <List
        settings={columns}
        data={list}
        dataIsHash
        saveKey={`${listType}behaviorObservations`}
        rowClick={row =>
          history.push(`/app/behaviorObservation/container/${row._id}`)
        }
        getRowId={row => row._id}
        actions={
          user.accessLevel >= 500
            ? [
                ...(listType === 'Active'
                  ? [
                      {
                        label: 'Send Reminder',
                        color: 'blue',
                        onClick: sendObservationReminder
                      }
                    ]
                  : []),
                {
                  label: `${
                    listType === 'Archived' ? 'Un-' : ''
                  }Archive Observations`,
                  color: 'blue',
                  onClick: handleArchive
                }
              ]
            : null
        }
        loading={listLoading}
      />
    </HeaderAndFooter>
  );
}
