import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../history';
import {
  createExpectations,
  fetchCustomTrainingTemplates,
  getCompanyNotifications,
  getCompanyExpectations,
  getJobList,
  updateCompanyNotifications,
  updateExpectations
} from '../../api/v4';
import {
  BEHAVIOR_OBSERVATION_NOTIFICATION_OPTIONS,
  CLAIMS_NOTIFICATIONS,
  DOCUMENT_NOTIFICATION_OPTIONS,
  FROI_NOTIFICATION_OPTIONS,
  QUALITY_AUDIT_NOTIFICATION_OPTIONS,
  REPORT_NOTIFICATION_OPTIONS,
  REPORT_NOTIFICATION_TYPES,
  RISK_ASSESSMENT_NOTIFICATION_OPTIONS,
  SAFETY_SUGGESTION_NOTIFICATION_OPTIONS,
  SAFETY_WALK_NOTIFICATION_OPTIONS,
  TASK_NOTIFICATION_OPTIONS,
  TRAINING_NOTIFICATION_OPTIONS,
  USER_ROLES,
  LOCKOUT_TAGOUT_NOTIFICATION_OPTIONS
} from '../../constants/constants';
import { updateCompanyRequest } from '../../actions/company';
import {
  addUploadedAttachmentsRequest,
  clearUploadedAttachments
} from '../../actions/attachments';
import { getActiveCompany, getIsPerrp } from '../../selectors/company';
import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import { getAccessLevel } from '../../selectors/users';
import CompanyForm from '../../forms/NewCompanyForm';
import { validZip, validNAICS, validSIC } from '../../utils/formValidation';
import { Button, Dropdown, InputRow, TwoColumn } from '../inputs';
import Card from '../Card';
import BlockText from '../inputs/BlockText';
import { InlineTabs } from '../InlineTabs';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import MissingRequiredModal from '../Modal/missingRequiredModal';
import { addMessage } from '../../actions/messages';
import Header from '../Header';
import HeaderAndFooter from '../HeaderAndFooter';
import LeftNav from '../LeftNav';

import styles from './company.module.scss';

export default function CompanyDetails() {
  const dispatch = useDispatch();
  const activeCompany = useSelector(getActiveCompany);
  const isPerrp = useSelector(getIsPerrp);
  const accessLevel = useSelector(getAccessLevel);
  const addedAttachments = useSelector(getAddedAttachmentsSelector);

  const [editType, setEditType] = useState();
  const [editNotifs, setEditNotifs] = useState(false);
  const [selectedTab, setTab] = useState(900);
  const [notifications, setNotifications] = useState({
    emailNotificationsActive: false
  });
  const [allNotifications, setAllNotifications] = useState({});
  const [company, setCompany] = useState();
  const [areaGroupsDontMatch, setAreaGroupsDontMatch] = useState([]);
  const [missingModalOpen, setMissingModalOpen] = useState(false);
  const [expectations, setExpectations] = useState({});
  const [trainingExps, setTrainingExp] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [missingRequiredExpectations, setMissing] = useState(false);

  useEffect(() => {
    getCompanyExpectations().then(r => {
      setTrainingExp(r?.trainings ?? []);
      setExpectations(r);
    });
    getJobList().then(r => {
      setJobTitles(r?.map(job => ({ value: job, label: job })));
    });
    fetchCustomTrainingTemplates().then(setTemplates);
  }, []);

  useEffect(() => {
    setCompany(activeCompany);
    getCompanyNotifications(activeCompany._id, true).then(setAllNotifications);
  }, [activeCompany, editNotifs]);

  useEffect(() => {
    if (allNotifications && selectedTab)
      setNotifications(allNotifications[selectedTab]);
  }, [selectedTab, allNotifications]);

  const handleSaveNotifs = () => {
    updateCompanyNotifications({
      ...allNotifications,
      companyId: activeCompany._id
    }).then(r => {
      setEditNotifs(false);
    });
  };

  const getAnswers = (options, label) => {
    let answers = options?.filter(o => notifications?.[label]?.[o.value]);
    return answers;
  };

  const handleNotificationChange = (values, options, label) => {
    let userNotifications = { ...notifications };
    if (values === 'clearAll') {
      let newAnswer = userNotifications[label]?.filter(
        v => !options.includes(v)
      );
      userNotifications[label] = newAnswer;
    } else if (values === 'selectAll') {
      let answer = [];
      options.forEach(option => {
        answer.push(option.value);
      });
      userNotifications[label] = answer;
    } else if (label === 'groups' || label === 'projects') {
      let answer = [];
      options.forEach(option => {
        if (values?.includes(option.value)) {
          answer.push(option.value);
        }
      });
      userNotifications[label] = answer;
    } else {
      for (let x = 0; x < options.length; x++) {
        const notification = options[x];
        userNotifications[label][notification.value] =
          values.findIndex(value => value === notification.value) > -1;
      }
    }
    setNotifications(userNotifications);
  };

  const handleResetExpectations = newExpectations => {
    setExpectations(newExpectations);
    setTrainingExp(newExpectations?.trainings ?? []);
    setEditType();
  };

  const handleExpectationSubmit = () => {
    let obj = { ...expectations, trainings: trainingExps, type: 'company' };
    if (obj?._id) {
      updateExpectations(obj).then(r => {
        dispatch(
          addMessage({
            error: false,
            message: 'Updated Successfully'
          })
        );
        handleResetExpectations(r);
      });
    } else {
      createExpectations(obj).then(r => {
        dispatch(
          addMessage({
            error: false,
            message: 'Updated Successfully'
          })
        );
        handleResetExpectations(r);
      });
    }
  };

  const tabs = {
    selected: selectedTab,
    onClick: setTab,
    list: USER_ROLES.filter(r => r.value >= 400)
  };

  let reportTypes = REPORT_NOTIFICATION_TYPES;
  if (!activeCompany.showAircraft) {
    reportTypes = REPORT_NOTIFICATION_TYPES.filter(r => !r.showAircraft);
  }
  reportTypes = reportTypes.map(t => ({
    label: t.label,
    fieldValue: t.value,
    options: REPORT_NOTIFICATION_OPTIONS
  }));

  const notificationDropdowns = [
    {
      fieldValue: 'froi',
      label: 'Employee Injury',
      options: FROI_NOTIFICATION_OPTIONS.filter(
        option => option.minAccessLevel <= accessLevel
      ).map(option => {
        if (option.label === 'Marked OSHA Recordable' && isPerrp) {
          return {
            label: 'Marked PERRP Recordable',
            value: option.value
          };
        }

        return option;
      })
    },
    ...reportTypes,
    {
      fieldValue: 'behaviorObservation',
      label: 'Behavior Observations',
      options: BEHAVIOR_OBSERVATION_NOTIFICATION_OPTIONS
    },
    {
      fieldValue: 'safetyAudit',
      label: 'Safety Audits',
      options: RISK_ASSESSMENT_NOTIFICATION_OPTIONS
    },
    {
      fieldValue: 'qualityAudit',
      label: 'Quality Audits',
      options: QUALITY_AUDIT_NOTIFICATION_OPTIONS
    },
    {
      fieldValue: 'lotoAudit',
      label: 'Lockout/Tagout Procedures',
      options: LOCKOUT_TAGOUT_NOTIFICATION_OPTIONS,
      hide: !activeCompany.hasLockoutTagout
    },
    {
      fieldValue: 'safetyWalk',
      label: activeCompany.isDash ? 'DASH Report' : 'Safety Walks',
      options: SAFETY_WALK_NOTIFICATION_OPTIONS
    },
    {
      fieldValue: 'training',
      label: 'Training',
      options: TRAINING_NOTIFICATION_OPTIONS
    },
    {
      fieldValue: 'document',
      label: 'Documents',
      options: DOCUMENT_NOTIFICATION_OPTIONS
    },
    {
      fieldValue: 'task',
      label: 'Tasks',
      options: TASK_NOTIFICATION_OPTIONS
    },
    {
      fieldValue: 'safetySuggestions',
      label: 'Safety Suggestions',
      options: SAFETY_SUGGESTION_NOTIFICATION_OPTIONS,
      hide: selectedTab !== 900
    },
    {
      fieldValue: 'claims',
      label: 'Claims',
      options: CLAIMS_NOTIFICATIONS,
      hide: !activeCompany.showClaims
    }
  ];

  const canSubmit =
    company?.name &&
    company?.phoneNumber?.fullPhoneNumber &&
    company?.mainAddress?.streetAddress &&
    company?.mainAddress?.city &&
    company?.mainAddress?.stateName &&
    company?.mainAddress?.zip &&
    !validZip(company?.mainAddress?.zip) &&
    company?.reportingType &&
    (company?.reportingType === 2 ? company?.bwcNumber : true) &&
    !validNAICS(company?.NAICS) &&
    (company?.SIC ? !validSIC(company?.SIC) : true) &&
    !areaGroupsDontMatch?.length;
  const left = (
    <LeftNav
      items={[
        { id: 'details', label: 'Company Details' },
        { id: 'notifications', label: 'Company Notifications' },
        { id: 'trainingExpectations', label: 'Training Expectations' }
      ]}
    />
  );

  const header = (
    <Header
      title="Company Information"
      clickBack={() => history.push('/app/dashboard')}
    />
  );

  const jobTitleDrodown = jobTitles?.map(t => ({
    ...t,
    isVisible: !trainingExps?.some(te => t.label === te.jobTitle)
  }));

  const validTrainingExpectations = trainingExps?.every(
    e => e.jobTitle && e?.templates?.length
  );

  return (
    <HeaderAndFooter Left={left} Header={header}>
      <Card
        title={editType === 'company' ? 'Edit Company' : 'Company Details'}
        showHeader
        rightButton={
          editType === 'company' ? (
            <InputRow right>
              <Button
                color="blue"
                text="Update"
                onClick={() => {
                  if (canSubmit) {
                    dispatch(updateCompanyRequest(company));
                    setEditType();
                  } else setMissingModalOpen(true);
                }}
              />
              <Button
                color="white"
                text="Cancel"
                onClick={() => {
                  setCompany(activeCompany);
                  setEditType();
                }}
              />
            </InputRow>
          ) : (
            <Button
              color="blue"
              text="Edit"
              onClick={() => setEditType('company')}
              testID="Edit Details"
            />
          )
        }
        name="details"
      >
        <ScrollToTopOnMount />
        {editType === 'company' ? (
          <CompanyForm
            company={company}
            setCompany={setCompany}
            isUpdate
            addedAttachments={addedAttachments}
            addAttachment={values =>
              dispatch(addUploadedAttachmentsRequest(values))
            }
            removeAttachment={() => dispatch(clearUploadedAttachments())}
            areaGroupsDontMatch={areaGroupsDontMatch}
            setAreaGroupsDontMatch={setAreaGroupsDontMatch}
          />
        ) : (
          <div className={styles.accountSettings}>
            <div className={styles.leftColumn}>
              <img
                src={
                  activeCompany.logoUrl ||
                  require('../../assets/images/no-image-placeholder.jpg')
                }
                alt={`${activeCompany.name}'s Logo`}
              />
            </div>

            <div className={styles.rightColumnCompany}>
              <div className={styles.smallHeader}>COMPANY</div>
              <h1 className={styles.companyName}>{activeCompany.name}</h1>

              <h4>
                {activeCompany.mainAddress
                  ? `${activeCompany.mainAddress.streetAddress} ${activeCompany.mainAddress.city}, ${activeCompany.mainAddress.stateName} ${activeCompany.mainAddress.zip}`
                  : 'Address not available'}
              </h4>

              <div className={styles.smallHeaderStats}>PHONE</div>
              <h5 className={styles.companyName}>
                {activeCompany.phoneNumber
                  ? activeCompany.phoneNumber.fullPhoneNumber
                  : 'Not available'}
              </h5>

              <div className={styles.smallHeaderStats}>WEBSITE</div>
              {activeCompany.website ? (
                <a
                  href={activeCompany.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {activeCompany.website}
                </a>
              ) : (
                <h5 className={styles.companyName}>Not available</h5>
              )}
            </div>
          </div>
        )}
      </Card>
      <Card
        title="Company Notification Settings"
        showHeader
        rightButton={
          editNotifs ? (
            <InputRow right>
              <Button color="blue" text="Update" onClick={handleSaveNotifs} />
              <Button
                color="white"
                text="Cancel"
                onClick={() => setEditNotifs(false)}
              />
            </InputRow>
          ) : (
            <Button
              color="blue"
              text="Edit"
              onClick={() => {
                setEditNotifs(true);
              }}
            />
          )
        }
        name="notifications"
      >
        <BlockText blockOfText="Users will always receive notifications for Tasks, Safety/Quality Audits, and Trainings assigned to them." />
        <InlineTabs tabs={tabs} />
        <TwoColumn>
          {notificationDropdowns.map(dropdown => (
            <>
              {dropdown.hide ? (
                <></>
              ) : (
                <Dropdown
                  multi
                  fieldLabel={dropdown.label}
                  placeholder="Notify users when..."
                  options={dropdown.options}
                  disabled={!editNotifs}
                  currentValue={getAnswers(
                    dropdown.options,
                    dropdown.fieldValue
                  )}
                  onChange={values =>
                    handleNotificationChange(
                      values,
                      dropdown.options,
                      dropdown.fieldValue
                    )
                  }
                  searchable
                  selectButtons
                />
              )}
            </>
          ))}
        </TwoColumn>
      </Card>
      <Card
        title="Training Expectations"
        showHeader
        name="trainingExpectations"
        rightButton={
          editType === 'expectations' ? (
            <InputRow right>
              <Button
                color="blue"
                text="Update"
                onClick={() =>
                  missingRequiredExpectations ? null : handleExpectationSubmit()
                }
                onMouseEnter={() => setMissing(!validTrainingExpectations)}
              />
              <Button
                color="white"
                text="Cancel"
                onClick={() => handleResetExpectations(expectations)}
              />
            </InputRow>
          ) : (
            <Button
              color="blue"
              text="Edit"
              onClick={() => setEditType('expectations')}
              testID="Edit Details"
            />
          )
        }
      >
        {trainingExps?.map((e, i) => (
          <>
            <TwoColumn className={styles.specialGrid}>
              <TwoColumn>
                <Dropdown
                  fieldLabel="Job Title"
                  options={jobTitleDrodown}
                  disabled={editType !== 'expectations'}
                  currentValue={e?.jobTitle}
                  onChange={v => {
                    let updated = [...trainingExps];
                    updated[i] = { ...e, jobTitle: v };
                    setTrainingExp(updated);
                  }}
                  searchable
                  selectButtons
                  isRequired
                  touched={missingRequiredExpectations && !e?.jobTitle}
                />
                <Dropdown
                  multi
                  fieldLabel="Trainings"
                  options={templates
                    ?.filter(t => !t.isArchived)
                    ?.map(t => ({
                      value: t._id,
                      label: t.title
                    }))}
                  disabled={editType !== 'expectations'}
                  currentValue={e?.templates}
                  onChange={v => {
                    let updated = [...trainingExps];
                    updated[i] = { ...e, templates: v };
                    setTrainingExp(updated);
                  }}
                  searchable
                  selectButtons
                  isRequired
                  touched={missingRequiredExpectations && !e?.templates?.length}
                />
              </TwoColumn>
              {editType === 'expectations' ? (
                <img
                  type="button"
                  src={require('../../assets/images/remove.png')}
                  alt="delete admin"
                  onClick={() => setTrainingExp(trainingExps.toSpliced(i, 1))}
                  className={styles.removeImage}
                  data-cy="removeButton"
                />
              ) : null}
            </TwoColumn>
          </>
        ))}
        {editType === 'expectations' ? (
          <Button
            text="Add Expectation"
            color="blue"
            onClick={() => {
              setEditType('expectations');
              setTrainingExp(trainingExps.concat([{}]));
            }}
            inputClassName={styles.addButton}
            disabled={!jobTitleDrodown?.length}
          />
        ) : null}
      </Card>
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="Company"
        isEditing
      />
    </HeaderAndFooter>
  );
}
