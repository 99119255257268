import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import history from '../../history';

import { formatFiles } from '../../utils/attachmentSelector';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';
import { addComment } from '../../api/v4';
import {
  FREQUENCY_OPTIONS,
  MONTHLY_FREQUENCY_OPTIONS,
  TASK_CATEGORY_DROPDOWN,
  YEARLY_FREQUENCY_OPTIONS
} from '../../constants/constants';
import {
  addUserTaskRequest,
  deleteUserTaskRequest,
  updateUserTaskRequst
} from '../../actions/tasks';
import { addMessage } from '../../actions/messages';
import {
  deleteAttachmentRequest,
  clearUploadedAttachments
} from '../../actions/attachments';
import { getActiveTaskSelector } from '../../selectors/tasks';
import { getActiveIncidentSelector } from '../../selectors/incidents';
import { getLoggedInUser } from '../../selectors/users';
import { getAddedAttachmentsSelector } from '../../selectors/attachments';

import AttachmentUploadCard from '../../components/AttachmentUploadCard';
import Card from '../../components/Card';
import CommentCard from './comments';
import DayOfWeekButtons from '../../components/inputs/DayOfWeekButtons';
import { DatePicker } from '../../components/inputs/DateTimePicker';
import DeleteItemModal from '../../components/Modal/deleteItemModal';
import {
  Checkbox,
  Dropdown,
  EmployeeDropdown,
  Textbox
} from '../../components/inputs';
import DueBy from '../../components/inputs/DueBy';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import HierarchySelector from '../../components/HierarchySelector';
import LeftNav from '../../components/LeftNav';
import MarkItemAsCompleteModal from '../../components/Modal/markItemAsCompleteModal';
import Modal from '../../components/Modal';
import RadioButtons from '../../components/inputs/RadioButtons';
import ReassignUserModal from '../../components/Modal/reassignUserModal';
import {
  SaveCancelFooter,
  SubmitSaveForLaterCancelFooter
} from '../../components/Footer';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import MissingRequiredModal from '../../components/Modal/missingRequiredModal';

export default function TaskContainer(props) {
  const dispatch = useDispatch();
  const clearAttachments = () => dispatch(clearUploadedAttachments());
  const createTask = task => dispatch(addUserTaskRequest(task));
  const updateTask = task => dispatch(updateUserTaskRequst(task));

  const activeTask = useSelector(getActiveTaskSelector);
  const activeIncident = useSelector(getActiveIncidentSelector);
  const loggedInUser = useSelector(getLoggedInUser);
  const addedAttachments = useSelector(getAddedAttachmentsSelector);
  const locked = props.locked;
  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();

  const [openModalType, setOpenModalType] = useState(null);
  const [creating, setCreating] = useState(false);
  const [editing, setEditing] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [task, setTask] = useState({});
  const [comments, setComments] = useState([]);
  const [attachmentsToUpload, setAttachmentsToUpload] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [attachmentsAdded, setAttachmentsAdded] = useState([]);
  const [newOwnerId, setNewOwnerId] = useState();
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);

  const {
    _id,
    assignedTo,
    createdBy,
    currentCategory,
    description,
    dueBy,
    dueByTime,
    dueDate,
    frequency,
    groupId,
    isCompleted,
    isIndividual,
    isPaused,
    label,
    ownerType,
    projectId,
    repeatingOn,
    schedule,
    taskOwner,
    isArchived
  } = task;

  const group = activeCompany.groups?.find(g => g._id === groupId);
  const area = group?.projects?.find(p => p._id === projectId);

  const hiddenOrArchived = isArchived || group?.isHidden || area?.isHidden;

  const disabled = (!editing && !creating) || locked || hiddenOrArchived;

  const isCreator = !createdBy || createdBy === loggedInUser._id;
  const adminOrCreator =
    isCreator ||
    [500, 900].indexOf(loggedInUser.accessLevel) > -1 ||
    loggedInUser._id === taskOwner;

  const isRepeating = schedule === 'repeating';

  const validDueDate =
    dueDate && creating ? moment(dueDate).isAfter(moment()) : true;

  const isFromEquipment = ownerType === 'equipment';
  const isForIncident = ownerType === 'incident';
  const isReportComponentTask =
    ownerType === 'incident' && !!activeTask?.reportComponentName;

  const limit =
    isForIncident && activeTask.stage
      ? activeIncident.permissions
          .filter(p => p[activeTask.stage] === 'Edit')
          .map(p => p.userId)
      : undefined;

  const equipmentProjectDisabled =
    isFromEquipment &&
    activeTask?.equipmentProjectDropdown?.length < 2 &&
    activeTask?.equipmentGroupDropdown?.length < 2;

  useEffect(() => {
    setTask({ ...activeTask });
    if (!activeTask?._id) {
      setCreating(true);
      setTask({
        groupId: activeGroup?._id,
        projectId: activeProject?._id,
        ...activeTask
      });
    }

    if (
      !activeTask.isCompleted &&
      activeTask.assignedTo?.some(assignee => assignee === loggedInUser._id) &&
      activeTask?.schedule !== 'repeating'
    )
      setEditing(true);
    setAttachmentsAdded(addedAttachments);
    setComments(activeTask?.comments ?? []);
  }, [activeTask, activeGroup, activeProject, addedAttachments, loggedInUser]);

  const handleAnswer = values => {
    setHasUnsavedChanges(true);
    setTask({ ...task, ...values });
  };

  const canSubmit =
    (groupId || activeGroup._id) &&
    label &&
    description &&
    assignedTo?.length > 0 &&
    !assignedTo.every(a => a === null) &&
    currentCategory?.length &&
    (((_id || schedule === 'immediate') && dueDate && validDueDate) ||
      (isRepeating &&
        frequency &&
        dueBy &&
        dueByTime &&
        (repeatingOn?.length || frequency === 'quarterly')));

  const handleTaskSubmit = async (isCompleted = false) => {
    if (creating) {
      if (filesToUpload?.length) {
        let data = formatFiles(filesToUpload);
        await createTask({
          ...task,
          attachmentData: {
            data: data,
            ownerType: 'task',
            photoType: 'defective'
          }
        });
      } else {
        await createTask({ ...task, isCompleted });
      }
      setCreating(false);
      setHasUnsavedChanges(false);
      clearAttachments();
      if (isRepeating) setOpenModalType('createRepeating');
    } else {
      await updateTask({ ...task, isCompleted });
      clearAttachments();
      history.goBack();
    }
  };

  const handleDeleteTask = () => {
    const allAttachments = [
      ...(addedAttachments || []),
      ...(activeTask.photosToUpload || [])
    ];
    dispatch(deleteUserTaskRequest({ ...task, attachments: allAttachments }));
  };

  const addNewComment = async (comment, parentId) => {
    let fullComment = {
      value: comment,
      ownerId: task._id,
      ownerType: 'task',
      parentId
    };
    addComment(fullComment).then(newComment => {
      setComments([...comments, newComment]);
    });
  };

  const handleDiscardTask = () => {
    clearAttachments();
    activeTask.returnPath
      ? history.push(activeTask.returnPath)
      : history.goBack();
  };

  const deleteAttachment = attachment => {
    dispatch(deleteAttachmentRequest(attachment));
    dispatch(
      addMessage({
        error: false,
        message: 'Deleted successfully!'
      })
    );
    let updatedAttachments = activeTask?.attachments?.filter(
      a => a !== attachment
    );
    setHasUnsavedChanges(creating || editing);
    if (!creating && !editing)
      updateTask({
        ...this.state,
        attachments: updatedAttachments
      });
  };

  const addAttachments = async (attachments, photoType = '') => {
    let data = formatFiles(attachments);
    let attachmentData = {
      data,
      ownerId: activeTask._id ?? 'pending',
      ownerType: 'task',
      photoType: photoType
    };
    // need to update the task so the attachment array has the new photo ids
    await updateTask({
      ...task,
      _id: activeTask._id,
      attachmentData
    });
  };

  const header = (
    <Header
      title="Tasks"
      section={`${creating ? 'Create' : editing ? 'Edit' : 'View'} Task`}
      needsSaved={hasUnsavedChanges}
      clickBack={() =>
        hasUnsavedChanges
          ? setOpenModalType('unsavedChanges')
          : handleDiscardTask()
      }
      pageActionOptions={
        adminOrCreator && !creating
          ? [
              {
                label: 'Edit Task',
                visible: !editing && !hiddenOrArchived,
                color: 'blueOutline',
                onClick: () => setEditing(true)
              },
              {
                label: 'Delete Task',
                visible: editing || activeTask.isArchived,
                color: 'redOutline',
                onClick: () =>
                  setOpenModalType(isRepeating ? 'endRepeating' : 'deleteCa')
              },
              {
                label: 'Reopen Task',
                visible: !hiddenOrArchived && isCompleted && editing,
                color: 'greenOutline',
                onClick: () => {
                  updateTask({
                    ...task,
                    isCompleted: !isCompleted
                  });
                }
              },
              {
                label: 'Transfer Ownership',
                visible:
                  !hiddenOrArchived &&
                  !isCompleted &&
                  activeTask.schedule !== 'repeating' &&
                  adminOrCreator &&
                  editing,
                color: 'blueOutline',
                onClick: () => setOpenModalType('transferOwner')
              },
              {
                label: `${activeTask?.isArchived ? 'Un-' : ''}Archive Task`,
                color: 'blueOutline',
                onClick: () => {
                  updateTask({ ...task, isArchived: !task.isArchived });
                  if (!task.isArchived) history.push('/app/tasks/task');
                },
                visible:
                  loggedInUser.accessLevel > 400 &&
                  !activeTask?.fromRepeating &&
                  activeTask.canEdit
              }
            ]
          : null
      }
    />
  );

  const footer =
    creating || (activeTask?.fromRepeating && editing) ? (
      <SaveCancelFooter
        saveButtonClick={() =>
          canSubmit ? handleTaskSubmit() : setMissingModalOpen(true)
        }
        saveButtonText={editing ? 'Update' : 'Create'}
        cancelButtonClick={() =>
          hasUnsavedChanges
            ? setOpenModalType('unsavedChanges')
            : handleDiscardTask()
        }
        editing={editing}
        onMouseEnter={() => setMissingRequired(!canSubmit)}
      />
    ) : editing ? (
      <SubmitSaveForLaterCancelFooter
        saveButtonClick={() => handleTaskSubmit()}
        saveButtonDisabled={!hasUnsavedChanges || !canSubmit}
        saveButtonText="Save"
        submitButtonText="Complete"
        cancelButtonClick={() =>
          hasUnsavedChanges
            ? setOpenModalType('unsavedChanges')
            : handleDiscardTask()
        }
        deleteButtonClick={() => setOpenModalType('delete')}
        submitButtonClick={() =>
          canSubmit ? setOpenModalType('complete') : setMissingModalOpen(true)
        }
        onMouseEnter={() => setMissingRequired(!canSubmit)}
        submitButtonDisabled={hiddenOrArchived}
      />
    ) : null;

  let leftNav = [{ label: 'Task Details', id: '0' }];
  if (ownerType !== 'incident') leftNav.push({ label: 'Attachments', id: '1' });
  if (!creating) leftNav.push({ label: 'Comments', id: '2' });

  return (
    <>
      <HeaderAndFooter
        Header={header}
        Footer={footer}
        showFooter
        lockInfo={props.lockInfo}
        Left={<LeftNav items={leftNav} />}
      >
        <Card showHeader title="Task Details" name={0}>
          <HierarchySelector
            onGroupChange={v => {
              let newProjectId = v === groupId ? projectId : '';
              handleAnswer({ groupId: v, projectId: newProjectId });
            }}
            groupId={groupId}
            groupDisabled={
              disabled ||
              isForIncident ||
              (isFromEquipment &&
                activeTask?.equipmentGroupDropdown?.length < 2)
            }
            onProjectChange={v => handleAnswer({ projectId: v })}
            projectId={projectId}
            projectDisabled={
              disabled || equipmentProjectDisabled || !adminOrCreator
            }
            filteredGroupDropdown={activeTask?.equipmentGroupDropdown}
            filteredProjectDropdown={activeTask?.equipmentProjectDropdown?.filter(
              p => p.groupId === groupId
            )}
            showFilteredMessage={isFromEquipment}
            touched={missingRequired && !groupId}
          />
          <Textbox
            currentValue={label}
            fieldLabel="Title"
            onChange={label => handleAnswer({ label })}
            placeholder="Type a clear and concise title."
            isRequired
            disabled={disabled || !adminOrCreator}
            touched={missingRequired && !label}
          />
          <Textbox
            currentValue={description}
            fieldLabel="Description"
            onChange={description => handleAnswer({ description })}
            type="textarea"
            placeholder="Describe what needs to be done to complete this Task. Be sure to include any important details."
            isRequired
            disabled={disabled || !adminOrCreator}
            touched={missingRequired && !description}
          />
          <Dropdown
            options={TASK_CATEGORY_DROPDOWN}
            fieldLabel="Category/Reason"
            currentValue={currentCategory}
            onChange={v => handleAnswer({ currentCategory: v })}
            disabled={disabled || isReportComponentTask || !adminOrCreator}
            isRequired
            multi
            placeholder="Choose one or more options"
            touched={missingRequired && !currentCategory}
          />
          {isForIncident || !creating ? null : (
            <RadioButtons
              fieldLabel="Schedule"
              options={[
                { value: 'immediate', label: 'Immediately' },
                { value: 'repeating', label: 'Repeating' }
              ]}
              isRequired
              onChange={v => handleAnswer({ schedule: v })}
              currentValue={schedule}
              disabled={disabled || editing || isFromEquipment}
              touched={missingRequired && !schedule}
            />
          )}
          {isRepeating ? (
            <>
              <Dropdown
                options={FREQUENCY_OPTIONS}
                fieldLabel="Frequency"
                currentValue={frequency}
                onChange={v =>
                  handleAnswer({ frequency: v, repeatingOn: undefined })
                }
                isRequired={true}
                disabled={disabled || !adminOrCreator}
                touched={missingRequired && !frequency}
              />
              {frequency === 'weekly' ? (
                <DayOfWeekButtons
                  fieldLabel="Assign Every:"
                  inputClassName="dayOfWeekInput"
                  disabled={disabled || !adminOrCreator}
                  onChange={v => handleAnswer({ repeatingOn: v })}
                  currentValue={repeatingOn}
                  isRequired
                  touched={missingRequired && !repeatingOn?.length}
                />
              ) : frequency !== 'quarterly' ? (
                <Dropdown
                  options={
                    frequency === 'monthly'
                      ? MONTHLY_FREQUENCY_OPTIONS
                      : YEARLY_FREQUENCY_OPTIONS
                  }
                  fieldLabel="Assign Every:"
                  currentValue={repeatingOn}
                  onChange={v => handleAnswer({ repeatingOn: [v] })}
                  isRequired
                  disabled={disabled || !adminOrCreator}
                  touched={missingRequired && !repeatingOn?.length}
                />
              ) : (
                <></>
              )}
              <DueBy
                fieldLabel="Due By"
                isRequired
                disabled={disabled || !adminOrCreator}
                name="dueBy"
                dueBy={dueBy}
                dueTime={dueByTime}
                onDueByChange={v => handleAnswer({ dueBy: v })}
                onDueTimeChange={v => handleAnswer({ dueByTime: v })}
                touched={missingRequired}
              />
            </>
          ) : (
            <DatePicker
              fieldLabel="Due By"
              isRequired
              onChange={value => handleAnswer({ dueDate: value })}
              pickTime
              currentValue={dueDate}
              disabled={disabled || !adminOrCreator}
              name="taskDueBy"
              touched={(missingRequired && !dueDate) || !validDueDate}
              minDate={moment()}
              errorMessage={
                !validDueDate ? 'Must be a future date and time' : null
              }
            />
          )}
          <EmployeeDropdown
            limit={limit}
            fieldLabel="Assignee(s)"
            currentValue={assignedTo}
            onChange={v => handleAnswer({ assignedTo: v })}
            isRequired
            disabled={disabled || !adminOrCreator}
            searchable
            name="employees"
            multi
            permissions={[100, 400, 500, 900]}
            group={groupId}
            touched={missingRequired && !assignedTo}
          />
          {isReportComponentTask ? (
            <></>
          ) : (
            <Checkbox
              fieldLabel="Assign Individually"
              name="isIndividual"
              onChange={value => handleAnswer({ isIndividual: value })}
              currentValue={isIndividual}
              disabled={
                disabled || (isRepeating ? !creating && !editing : !creating)
              }
            />
          )}
          {isRepeating && !creating ? (
            <Checkbox
              fieldLabel="Pause Task"
              name="pauseTask"
              onChange={value => handleAnswer({ isPaused: value })}
              currentValue={isPaused}
              disabled={disabled || !adminOrCreator}
            />
          ) : (
            <></>
          )}
        </Card>
        {isReportComponentTask || isRepeating ? null : (
          <AttachmentUploadCard
            addAttachments={addAttachments}
            deleteAttachment={deleteAttachment}
            isCreating={creating}
            isEditing={
              adminOrCreator
                ? editing
                : task?.assignedTo?.includes(loggedInUser._id)
            }
            addedAttachments={attachmentsAdded}
            attachmentsToUpload={attachmentsToUpload}
            name={1}
            handleAnswer={(currentAttachments, currentFiles) => {
              setAttachmentsToUpload(currentAttachments);
              setFilesToUpload(currentFiles);
              setHasUnsavedChanges(true);
            }}
            taskOwnedBy={ownerType}
            ownerType="task"
            formattedAttachmentsToUpload={filesToUpload}
            keepAttachmentsAnonymous={ownerType === 'safetySuggestion'}
            disabled={hiddenOrArchived}
          />
        )}
        {creating || isRepeating ? null : (
          <CommentCard
            name={2}
            addComment={addNewComment}
            comments={comments}
            disabled={hiddenOrArchived}
          />
        )}
      </HeaderAndFooter>
      <SaveChangesModal
        isOpen={openModalType === 'unsavedChanges'}
        onRequestClose={() => setOpenModalType()}
        submitActions={handleDiscardTask}
        savingWhat="Task"
      />
      <DeleteItemModal
        isOpen={openModalType === 'deleteCa'}
        onRequestClose={() => setOpenModalType()}
        deletingWhat="Task"
        hideCustomTemplate
        submitActions={handleDeleteTask}
      />
      <MarkItemAsCompleteModal
        isOpen={openModalType === 'complete'}
        completingWhat="Task"
        onRequestClose={() => setOpenModalType()}
        submitActions={() => handleTaskSubmit(true)}
        cancelActions={() => setOpenModalType()}
        message={
          <div>
            Are you sure you would like to mark this task complete? This will
            remove the task from the assignee(s) to-do list.
          </div>
        }
        submitButtonColor="blue"
        submitButtonText="Complete Task"
        cancelButtonText="Cancel"
        cancelButtonColor="white"
        headerColor="blueHeader"
      />
      <ReassignUserModal
        title="Transfer Ownership"
        previousUser={taskOwner}
        reassignUser={newOwnerId}
        disableSubmit={!newOwnerId}
        message={
          <div>
            From here, you can assign a different user to be the owner for this
            task. Task Ownership can only be reassigned to an Admin or
            Collaborator User with access to the Group/Establishment this task
            is in.
          </div>
        }
        onRequestClose={() => {
          setOpenModalType();
          setNewOwnerId();
        }}
        isOpen={openModalType === 'transferOwner'}
        employeeFieldLabel="New Task Owner"
        onChange={setNewOwnerId}
        submitActions={() => {
          setOpenModalType();
          setNewOwnerId();
          updateTask({
            ...task,
            taskOwner: newOwnerId
          });
        }}
        permissions={[500, 900]}
        groups={groupId}
        submitButtonText="Reassign"
      />
      <Modal
        title="Repeating Task"
        titleClassName="blueHeader"
        isOpen={openModalType === 'createRepeating'}
        onRequestClose={() => history.goBack()}
        hideButtons={true}
        testID="repeatingTask"
      >
        <div className="reportSectionContainer-emailAssigneeText">
          A Repeating Task has been created.{' '}
          <span
            style={{
              fontWeight: 'bold',
              fontStyle: 'italic'
            }}
          >
            It can be found in the Repeating section of the Tasks page. Editing
            or ending the a Repeating Task will only affect future Tasks, and
            will not alter any Tasks that are already Active
          </span>
        </div>
      </Modal>
      <Modal
        title="End Repeating Task"
        titleClassName="redHeader"
        isOpen={openModalType === 'endRepeating'}
        submitButtonColor="red"
        submitButtonText="Delete Task"
        onRequestClose={() => setOpenModalType()}
        submitActions={handleDeleteTask}
      >
        <div className="reportSectionContainer-emailAssigneeText">
          <span style={{ color: '#c74846', fontWeight: 'bold' }}>
            Ending a Repeating Task will remove it from the Repeating section of
            the Task page.{' '}
          </span>
          Tasks that are already Active will not be affected, but no more will
          be created by this Repeating Task.
        </div>
      </Modal>
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="Task"
        isEditing={editing}
        isCompleting
      />
    </>
  );
}
