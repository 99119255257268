import React, { useState } from 'react';
import { Input, nameInput } from '../';
import classnames from 'classnames/bind';
import '../input.css';
import styles from './index.module.scss';

const bStyles = classnames.bind(styles);

const Textbox = ({
  name,
  placeholder,
  onChange,
  onBlur,
  currentValue = '',
  disabled,
  type,
  inputClassName,
  rows,
  onMouseDown,
  autoFocus,
  inputRef,
  testID,
  enter,
  onKeyDown,
  warning,
  touched,
  error,
  errorMessage,
  showWarning,
  ...props
}) => {
  const [invalidField, setInvalidField] = useState(false);

  const invalid = touched || (invalidField && !currentValue);

  const keyDown = e => {
    if (e.keyCode === 27 && escape) {
      escape();
    } else if ((e.keyCode === 13 || e.keyCode === 10) && enter) {
      enter(currentValue);
    } else if (onKeyDown) {
      return onKeyDown(e);
    }
  };
  const change = e => {
    setInvalidField(props.isRequired);
    return onChange?.(e.target.value, e);
  };

  const handleOnBlur = e => {
    setInvalidField(props.isRequired && !currentValue);
    if (onBlur) onBlur();
  };

  return (
    <Input
      {...props}
      name={name}
      disabled={disabled}
      warning={warning}
      touched={touched}
      error={error}
      errorMessage={errorMessage}
      showWarning={showWarning}
    >
      {type === 'textarea' ? (
        <textarea
          data-cy={testID ?? props.fieldLabel}
          value={currentValue}
          rows={rows ?? 5}
          placeholder={placeholder}
          className={bStyles([styles.textbox, inputClassName], {
            invalid: invalid
          })}
          onChange={change}
          onKeyDown={keyDown}
          onBlur={handleOnBlur}
          disabled={disabled}
          min="0"
          id={name}
          onDragStart={e => e.preventDefault()}
          onDrop={e => e.preventDefault()}
          autoFocus={autoFocus}
          ref={inputRef}
        />
      ) : (
        <input
          value={currentValue}
          type={type}
          placeholder={placeholder}
          className={bStyles([styles.textbox, inputClassName], {
            invalid: invalid
          })}
          onChange={change}
          onKeyDown={keyDown}
          onBlur={handleOnBlur}
          onMouseDown={onMouseDown}
          disabled={disabled}
          min="0"
          step={props.step ?? '1'}
          id={name}
          autoFocus={autoFocus}
          ref={inputRef}
          data-cy={testID ?? props.fieldLabel}
        />
      )}
    </Input>
  );
};

let t = nameInput(Textbox);
export { t as Textbox };
export default t;
