import React from 'react';
import moment from 'moment';
import classnames from 'classnames/bind';
import Card from '../../Card';
import { Checkbox } from '../index';
import UserBadge from '../../UserBadge';
import styles from './styles.module.scss';
const bStyles = classnames.bind(styles);
const getContinuousCareHeaders = cc => {
  const isPastDue = moment().isAfter(moment(cc.dueBy));
  if (isPastDue) {
    return {
      top: 'Past Due',
      style: 'redOutline'
    };
  } else {
    return {
      top: 'Assigned',
      style: 'blueOutline'
    };
  }
};
export default function ContinuousCareCard({
  document,
  index,
  assignee,
  handleNa,
  onClick,
  disabled
}) {
  const header = getContinuousCareHeaders(document);
  const classes = bStyles({
    infoDiv: true,
    disabled: disabled || document.isNa,
    [header.style]: true
  });
  return (
    <Card
      name={`ccCard${index}`}
      title={`${assignee?.firstName} ${assignee?.lastName}'s ${document.label}`}
      wide
      showHeader
    >
      <Checkbox
        fieldLabel="Mark as Not Applicable"
        currentValue={document.isNa}
        onChange={handleNa}
        disabled={disabled}
      />
      <div className={classes} onClick={() => (disabled ? null : onClick())}>
        {document.isNa ? (
          <div className={styles.text}>Not Applicable</div>
        ) : (
          <>
            <UserBadge userId={assignee?._id} showImage />
            <div>
              <div className={styles.text}>{header.top}</div>
              <div className={`${styles.text} ${styles.notBold}`}>
                Due Date: {moment(document.dueBy).format('MM/DD/YYYY hh:mm a')}
              </div>
            </div>
          </>
        )}
      </div>
    </Card>
  );
}
