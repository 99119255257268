import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import {
  fetchWitnessStatementList,
  sendWitnessStatementReminder,
  fetchUserListFilter,
  updateUserListFilter
} from '../../api/v4';
import { setActiveIncidentRequest } from '../../actions/incidents';
import { REACTIVE_REPORT_TYPES } from '../../constants/constants';
import { useActiveHeirarchy } from '../../utils/useActiveHeirarchy';
import { usePrevious } from '../../utils/hooks';
import { getLoggedInUser } from '../../selectors/users';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import List from '../../components/List';
import TypeSelection from '../../components/TypeSelection';
import AnalyticsPeriodPicker from '../../components/AnalyticsPeriodPicker';

function WitnessStatementList() {
  const [statementList, setStatementList] = useState([]);
  const [listType, setListType] = useState('Active');
  const [empPeriod, setEmpPeriod] = useState();
  const [currentPeriod, setCurrentPeriod] = useState();
  const [loading, setLoading] = useState(false);
  const { activeCompany } = useActiveHeirarchy();
  const user = useSelector(getLoggedInUser);
  const dispatch = useDispatch();
  const prevPeriod = usePrevious(currentPeriod);

  useEffect(() => {
    fetchUserListFilter(user._id, 'witnessStatement').then(r => {
      setCurrentPeriod(r);
      setEmpPeriod(r);
    });
  }, [user._id]);

  useEffect(() => {
    if (!isEqual(currentPeriod, prevPeriod))
      fetchWitnessStatementList({
        ...currentPeriod,
        selectedType: listType
      }).then(setStatementList);
  }, [currentPeriod, prevPeriod, listType]);

  const handleTypeChange = value => {
    setListType(value);
    setStatementList([]);
    setLoading(true);
    fetchWitnessStatementList({ ...currentPeriod, selectedType: value }).then(
      res => {
        setStatementList(res);
        setLoading(false);
      }
    );
  };

  const header = <Header section="Witness Statements" />;

  const columns = [
    { key: 'incidentNumber', label: 'Incident Number' },
    { key: 'witnessName', label: 'Witness Name' },
    {
      key: 'reportType',
      label: 'Incident Type',
      datatype: 'svgs',
      disableSortBy: true,
      accessor: ws => ws.reportType?.map(v => parseInt(v, 10)),
      enum: activeCompany?.showAircraft
        ? REACTIVE_REPORT_TYPES.filter(r => !r.label !== 'Spill')
        : REACTIVE_REPORT_TYPES.filter(r => !r.isAircraft)
    },
    {
      key: 'isCompleted',
      label: 'Status',
      enum: ['Open', 'In Progress', 'Completed', 'Not Applicable'],
      accessor: ws =>
        ws.isNa
          ? 'Not Applicable'
          : !ws.hasStarted && !ws.isCompleted
          ? 'Open'
          : ws.isCompleted === false
          ? 'In Progress'
          : 'Completed'
    },
    {
      key: 'externalToken',
      label: 'Internal or External?',
      enum: ['Internal', 'External'],
      accessor: ws => (ws.externalToken ? 'External' : 'Internal')
    }
  ];

  return (
    <HeaderAndFooter Header={header}>
      {user.accessLevel > 400 ? (
        <TypeSelection
          selectedArray={['Active', 'Archived']}
          onClick={handleTypeChange}
          selected={listType}
        />
      ) : null}
      <AnalyticsPeriodPicker
        period={currentPeriod}
        onChange={setCurrentPeriod}
        onSave={v =>
          updateUserListFilter({
            empId: user._id,
            type: 'witnessStatement',
            period: v
          }).then(() => setEmpPeriod(v))
        }
        allowAll
        savedPeriod={empPeriod}
        listPicker
      />
      <List
        data={statementList}
        settings={columns}
        dataIsHash
        saveKey="witnessStatementList"
        name="WitnessStatementList"
        getRowId={row => row._id}
        actions={[
          {
            label: 'Send Reminder',
            color: 'blue',
            onClick: ids => {
              sendWitnessStatementReminder(ids);
            },
            visible: listType === 'Active'
          }
        ]}
        hideCheckboxFor={['isCompleted', 'externalToken', 'isArchived']}
        rowClick={row => {
          dispatch(
            setActiveIncidentRequest({
              _id: row.incidentId,
              returnPath: '/app/witnessStatementList',
              leavePage: false,
              selectedSection: 'Witness Statements'
            })
          );
        }}
        loading={loading}
      />
    </HeaderAndFooter>
  );
}

export default WitnessStatementList;
