import { useSelector } from 'react-redux';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../selectors/company';

export function useActiveHeirarchy() {
  const activeCompany = useSelector(state => getActiveCompany(state));
  const activeGroup = useSelector(state => getActiveLocationId(state));
  const activeProject = useSelector(state => getActiveProject(state));

  return { activeCompany, activeGroup, activeProject };
}

export default useActiveHeirarchy;
