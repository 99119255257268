import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { emailAssignees } from '../../api/v4';
import history from '../../history';
import {
  fetchIncidentByIdRequest,
  updateReportComponentRequest
} from '../../actions/incidents';
import {
  createOshaFormRequest,
  updateOshaFormRequest
} from '../../actions/oshaLogs';
import { setActiveTaskRequest } from '../../actions/tasks';
import { getAttachmentLoadingSelector } from '../../selectors/attachments';
import {
  getActiveIncidentSelector,
  getIncidentsLoadingSelector
} from '../../selectors/incidents';
import { getActiveOshaLogSelector } from '../../selectors/oshaLogs';
import { getActiveReportsSelector } from '../../selectors/reports';
import { getLoggedInUser } from '../../selectors/users';
import {
  canMarkAsComplete,
  hasAccess,
  isSectionComplete
} from '../../utils/incidentHelper';
import { isComplete } from '../../utils/reportHelper';
import {
  canSubmit,
  oshaDbMapper,
  numberOfDays
} from '../../utils/oshaDbMapper';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';
import { useSocket } from '../../utils/withSocket';
import Assignee from '../../components/Assignee';
import AssignCorrectiveActionButton from '../../components/inputs/AssignCorrectiveActionButton';
import BlockText from '../../components/inputs/BlockText';
import Card from '../../components/Card';
import { Checkbox, Textbox } from '../../components/inputs';
import Header from '../../components/Header';
import { HeaderAndFooter } from '../../components/HeaderAndFooter';
import LeftNav from '../../components/LeftNav';
import MarkAsCompleteModal from '../../components/Modal/markAsCompleteModal';
import Modal from '../../components/Modal';
import OshaComplianceCard from '../../components/reportCards/OshaComplianceCard';
import { SaveCancelFooter } from '../../components/Footer';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import ReportSection from '../../components/reportCards/ReportSection';
import ViewOnly from '../../components/ViewOnly';
import MissingRequiredModal from '../../components/Modal/missingRequiredModal';

import './index.css';

export default function ReportSectionContainer(props) {
  const dispatch = useDispatch();

  const { stage } = useParams();
  const { activeCompany } = useActiveHeirarchy();
  const updateReportComponent = payload =>
    dispatch(updateReportComponentRequest(payload));
  const setTask = payload => dispatch(setActiveTaskRequest(payload));

  const activeIncident = useSelector(getActiveIncidentSelector);
  const activeReport = useSelector(getActiveReportsSelector);
  const attachmentLoading = useSelector(getAttachmentLoadingSelector);
  const incidentLoading = useSelector(getIncidentsLoadingSelector);
  const loggedInUser = useSelector(getLoggedInUser);
  const oshaForm = useSelector(getActiveOshaLogSelector);

  const [openModalType, setOpenModalType] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [report, setReport] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [oshaQuestionnaire, setOshaQuestionnaire] = useState([]);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);

  const { socketDirty, socketLock, lockInfo, locked } = useSocket(
    activeIncident._id,
    activeReport?.report?.[0]?.[0]
  );

  useEffect(() => {
    let reportName;
    let minSections;
    if (activeReport?.report) {
      setReport([...activeReport.report]);

      reportName = activeReport.report?.[0]?.[0];
      minSections = activeReport.report?.[0]?.[6].minSections;
    } else {
      setReport([...activeReport]);
      reportName = activeReport?.[0]?.[0];
      minSections = activeReport?.[0]?.[6].minSections;
    }

    if (oshaForm.length > 0) {
      const oshaQuestions = [];

      oshaForm.forEach(form =>
        oshaQuestions.push({
          ...form.formAnswers,
          daysJobRestriction:
            form.formAnswers.daysJobRestriction ??
            numberOfDays(
              form.formAnswers.daysJobRestrictionArray,
              form.formAnswers.daysJobRestrictionCheckbox
            ),
          daysAwayFromWork:
            form.formAnswers.daysAwayFromWork ??
            numberOfDays(
              form.formAnswers.daysAwayFromWorkArray,
              form.formAnswers.daysAwayFromWorkCheckbox
            ),
          incidentId: form._id,
          _id: form._id,
          isLegacy: form.isLegacy
        })
      );

      setOshaQuestionnaire(oshaQuestions);
    } else if (reportName === 'OSHA Compliance') {
      setOshaQuestionnaire(Array(minSections).fill({}));
    }
  }, [activeReport, oshaForm, activeIncident]);

  const rc = report?.[0];
  const rcName = rc?.[0];
  const rcComplete = isComplete(report);
  const reportTask = activeIncident?.incidentActivity?.openTasks?.filter(
    task => report.length > 0 && task.reportComponentName === rcName
  );
  const disabled = locked || activeReport?.disabled;

  const sendEmail = () => {
    const reportTask = activeIncident?.incidentActivity?.openTasks?.filter(
      task => report.length > 0 && task.reportComponentName === rcName
    );
    emailAssignees({
      messageContent: emailMessage,
      incidentId: activeIncident?._id,
      sentMessageToIds: reportTask?.[0]?.assignedTo
    }).then(() => {
      setOpenModalType(null);
      setEmailMessage('');
    });
  };

  const answerQuestion = async (fields, subSectionIndex) => {
    socketLock();
    report[0][1].subSections[subSectionIndex][1] = fields;
    report[0][5].isStarted = true;
    setReport({ ...report });
    setUnsavedChanges(true);
  };

  const reopenReportSection = async () => {
    report[0][4].isComplete = false;
    await updateReportComponent({
      stage
    });
  };

  const answerOsha = (values, index) => {
    const oshaForm = { ...oshaQuestionnaire[index], ...values };
    let osha = [...oshaQuestionnaire];
    osha[index] = oshaForm;
    setOshaQuestionnaire(osha);
    setUnsavedChanges(true);

    socketLock();
  };

  const handleImmediatelyFixed = (values, index) => {
    const correctiveActions = [...report[0][1]?.correctiveActions];
    correctiveActions[index].immediatelyFixed = values;
    report[0][1].correctiveActions = [...correctiveActions];
    setReport({ ...report });
    setUnsavedChanges(true);
  };

  const handleSectionSubmit = async (markAsComplete, dontLeave = false) => {
    setUnsavedChanges(true);

    const canSubmitOshaLog = oshaQuestionnaire.map(form => canSubmit(form));

    const showComplete =
      rcName !== 'OSHA Compliance' && rcName !== 'Corrective Actions'
        ? canMarkAsComplete(report[0][1].subSections)
        : rcName === 'OSHA Compliance'
        ? canSubmitOshaLog.indexOf(false) === -1
        : false;
    if (showComplete && markAsComplete === undefined) {
      setOpenModalType('markComplete');
      return;
    }

    setOpenModalType(null);

    if (markAsComplete) {
      report[0][4].isComplete = true;
    }

    if (report[0][0] === 'OSHA Compliance') {
      oshaQuestionnaire.map(async (form, index) => {
        const oshaAnswers = oshaDbMapper(form);
        const payload = {
          incidentId: activeIncident._id,
          companyId: activeIncident.companyId,
          locationId: activeIncident.locationId,
          projectId: activeIncident?.projectId,
          questions: oshaAnswers
        };
        if (form._id) {
          await dispatch(
            updateOshaFormRequest({
              ...oshaQuestionnaire[index],
              ...payload
            })
          );
        } else {
          await dispatch(createOshaFormRequest(payload));
        }
      });
      report[0][5].isStarted = true;
    }
    await dispatch(
      updateReportComponent({
        stage,
        dontLeavePage: dontLeave
      })
    );

    socketDirty();
  };

  const handleNaSection = async (value, subSectionIndex) => {
    socketLock();

    if (rcName === 'OSHA Compliance') {
      report[0][6].subSections[subSectionIndex][3] = {
        notApplicable: value
      };
      setReport({ ...report });
    } else {
      report[0][1].subSections[subSectionIndex][3] = {
        notApplicable: value
      };
      setReport({ ...report });
    }

    setUnsavedChanges(true);
  };

  const handleDiscard = async () => {
    if (report.returnPath) {
      history.push(report.returnPath);
    } else {
      dispatch(fetchIncidentByIdRequest(activeIncident));
      history.push(`/app/incidentContainer/${props.match.params.stage}`);
    }
  };

  const getLeftNavLabel = () => {
    return rcName !== 'OSHA Compliance' && rcName !== 'Corrective Actions'
      ? getFirstSubsectionOf()
      : isOshaType()
      ? [{ label: 'OSHA Compliance' }]
      : isPERRPType()
      ? [{ label: 'PERRP' }]
      : rcName === 'Corrective Actions'
      ? getCAs()
      : [];
  };

  const isPERRPType = () =>
    rcName === 'OSHA Compliance' && activeCompany.reportingType === 2;

  const isOshaType = () =>
    rcName === 'OSHA Compliance' && activeCompany.reportingType === 1;

  const getFirstSubsectionOf = () =>
    rc?.[1]?.subSections?.map(subsection => ({
      label: subsection[0],
      complete: isSectionComplete(subsection)
    }));

  const getCAs = () =>
    rc?.[1]?.correctiveActions?.map(ca => ({
      label: ca.label,
      complete: ca?.task?.isComplete
    }));

  const showMarkAsComplete = !rcComplete && rcName === 'Corrective Actions';

  const pageActions = disabled
    ? []
    : [
        {
          visible:
            activeIncident.incidentOwner === loggedInUser._id &&
            rc?.[2]?.assignedTo === 'unassigned' &&
            !rcComplete &&
            rcName !== 'Corrective Actions',
          label: 'Assign Report as Task',
          color: 'blueOutline',
          onClick: () =>
            setTask({
              ownerId: activeIncident._id,
              ownerType: 'incident',
              reportComponentName: rcName,
              label: `${activeIncident.incidentNumber}, ${rcName}`,
              groupId: activeIncident.groupId,
              stage
            })
        },
        {
          visible: showMarkAsComplete,
          label: 'Mark Report as Complete',
          color: 'blueOutline',
          onClick: () => setOpenModalType('markComplete')
        }
      ];

  const headerCenter = (
    <ViewOnly
      canUnlock={
        !disabled &&
        rcComplete &&
        (loggedInUser?.accessLevel === 900 ||
          activeIncident.incidentOwner === loggedInUser?._id)
      }
      incident={activeIncident}
      loggedInUser={loggedInUser}
      onClick={() => setOpenModalType('unlockReport')}
      activeReport={activeReport}
    />
  );
  const right =
    rc?.[2]?.assignedTo !== 'unassigned' ? (
      <Assignee
        user={rc?.[2]?.assignedTo}
        options={[
          {
            label: 'Email Assignee',
            onClick: () => setOpenModalType('emailAssignee'),
            visible: true
          },
          {
            label: 'Edit Task',
            visible: activeIncident?.incidentOwner === loggedInUser._id,
            onClick: () =>
              setTask({
                ...reportTask[0],
                stage
              })
          }
        ]}
      />
    ) : null;

  const header = (
    <Header
      title={activeIncident && activeIncident.incidentNumber}
      section={
        rcName === 'OSHA Compliance' && activeCompany.reportingType === 2
          ? 'PERRP'
          : rcName
      }
      needsSaved={unsavedChanges}
      clickBack={() =>
        unsavedChanges ? setOpenModalType('unsavedChanges') : handleDiscard()
      }
      center={headerCenter}
      right={right}
      pageActionOptions={pageActions?.filter(pg => pg.visible)}
    />
  );

  const cannotSubmit =
    (canMarkAsComplete(rc?.[1]?.subSections) || rcName === 'Corrective Actions'
      ? false
      : !unsavedChanges && rcName !== 'OSHA Compliance') || attachmentLoading;

  const footer = (
    <SaveCancelFooter
      saveButtonClick={() =>
        cannotSubmit
          ? setMissingModalOpen(true)
          : handleSectionSubmit(undefined)
      }
      saveButtonDisabled={rcName === 'Corrective Actions' && !unsavedChanges}
      cancelButtonClick={() =>
        unsavedChanges ? setOpenModalType('unsavedChanges') : handleDiscard()
      }
      editing={true}
      onMouseEnter={() => setMissingRequired(true)}
    />
  );

  return (
    <>
      {incidentLoading && (
        <ReactLoading
          type="spinningBubbles"
          color="#2f8738"
          className="loading"
        />
      )}
      <HeaderAndFooter
        Header={header}
        Footer={disabled ? null : footer}
        Left={<LeftNav items={getLeftNavLabel()} />}
        lockInfo={lockInfo}
        showFooter={!rcComplete}
      >
        {rcName !== 'OSHA Compliance' &&
        (rcName !== 'Corrective Actions' || activeIncident.legacy) ? (
          rc?.[1]?.subSections?.map((subsection, index) => (
            <ReportSection
              name={index}
              reportSectionHeader={subsection[0]}
              fields={subsection[1]}
              key={index}
              answerQuestion={fields => answerQuestion(fields, index)}
              disabled={
                rcComplete ||
                !hasAccess(loggedInUser, activeReport.stage, activeIncident) ||
                disabled
              }
              canSectionNotApplicable={
                subsection[2] && subsection[2].showNotApplicable
              }
              sectionNotApplicable={
                subsection[3] && subsection[3].notApplicable
              }
              handleNaSection={value => handleNaSection(value, index)}
              incidentId={activeIncident._id}
              groupId={activeIncident.groupId}
              missingRequired={missingRequired}
            />
          ))
        ) : rcName === 'Corrective Actions' &&
          rc?.[1]?.correctiveActions.length > 0 ? (
          rc?.[1]?.correctiveActions.map((ca, index) => (
            <Card
              name={index}
              title={ca.label}
              wide
              showHeader
              key={index}
              testID={`caCard${index}`}
            >
              {activeIncident?.isRootCauseLegacy ? null : (
                <BlockText blockOfText={ca?.description} />
              )}
              <Checkbox
                fieldLabel="Immediately Corrected"
                currentValue={ca.immediatelyFixed}
                disabled={
                  rcComplete ||
                  !hasAccess(
                    loggedInUser,
                    activeReport.stage,
                    activeIncident
                  ) ||
                  disabled
                }
                onChange={values => handleImmediatelyFixed(values, index)}
              />
              <AssignCorrectiveActionButton
                groupId={activeIncident.groupId}
                task={ca?.task}
                ownerType="incident"
                label={ca.label}
                schedule="immediate"
                projectId={activeIncident.projectId}
                immediatelyFixed={ca?.immediatelyFixed}
                ownerId={activeIncident._id}
                testID={`caButton${index}`}
                disabled={
                  (rcComplete && !ca?.task) ||
                  (ca.task?.isArchived && loggedInUser.accessLevel < 500)
                }
                onClick={() => {
                  if (unsavedChanges) handleSectionSubmit(false, true);
                }}
                type="correctiveAction"
                description={ca?.description}
              />
            </Card>
          ))
        ) : rcName === 'Corrective Actions' &&
          rc?.[1]?.correctiveActions?.length === 0 ? (
          <div className="reportSectionContainer-correctiveActionText">
            Complete the Root Cause to populate Corrective Actions
          </div>
        ) : (
          <div>
            {report?.length &&
              oshaQuestionnaire?.map((x, i) => (
                <OshaComplianceCard
                  answerOsha={values => answerOsha(values, i)}
                  oshaForm={x}
                  key={i}
                  name={i}
                  disabled={
                    disabled ||
                    rcComplete ||
                    !hasAccess(loggedInUser, activeReport.stage, activeIncident)
                  }
                  missingRequired={missingRequired}
                />
              ))}
          </div>
        )}
      </HeaderAndFooter>
      <Modal
        title="Email Assignee"
        titleClassName="blueHeader"
        isOpen={openModalType === 'emailAssignee'}
        submitButtonColor="blue"
        submitButtonText="Send Email"
        onRequestClose={() => {
          setOpenModalType(null);
          setEmailMessage('');
        }}
        submitActions={sendEmail}
      >
        <div className="reportSectionContainer-emailAssigneeText">
          This email will go through the iReportSource system for record keeping
          purposes.
        </div>
        <Textbox
          fieldLabel="Email Content"
          type="textarea"
          placeholder="Type email content here"
          currentValue={emailMessage}
          onChange={setEmailMessage}
        />
      </Modal>
      <SaveChangesModal
        isOpen={openModalType === 'unsavedChanges'}
        onRequestClose={() => setOpenModalType(null)}
        submitActions={handleDiscard}
        savingWhat="a report"
      />
      <MarkAsCompleteModal
        isOpen={openModalType === 'markComplete'}
        onRequestClose={() => setOpenModalType(null)}
        submitActions={() => handleSectionSubmit(true)}
        cancelActions={() => handleSectionSubmit(false)}
      />
      <Modal
        title="Unlock and Re-Open Report"
        titleClassName="blueHeader"
        isOpen={openModalType === 'unlockReport'}
        submitButtonColor="blue"
        submitButtonText="Re-open Report"
        onRequestClose={() => setOpenModalType(null)}
        submitActions={reopenReportSection}
      >
        <div className="reportSectionContainer-emailAssigneeText">
          Unlocking this report will allow you –and users with edit permission–
          to make changes and additions to the report.{' '}
          <span style={{ color: '#c74846', fontWeight: 'bold' }}>
            This also means that the report will no longer be considered
            completed until it is filled out, saved, and marked as complete once
            more. Re-Opening this report will also remove the currently assigned
            user.
          </span>{' '}
          You can reassign this report to the same or a different user as a new
          task.
        </div>
      </Modal>
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject={rcName}
      />
    </>
  );
}
