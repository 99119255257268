import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useParams, useLocation } from 'react-router';
import history from '../../../history';
import { addMessage } from '../../../actions/messages';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import {
  createTrainingCourse,
  deleteTrainingCourse,
  fetchCompanyUsers,
  fetchDocumentFoldersByType,
  getCourseTemplates,
  getJobList,
  getTrainingCourseById,
  updateTrainingCourse
} from '../../../api/v4';
import Card from '../../../components/Card';
import {
  Button,
  Checkbox,
  Dropdown,
  EmployeeDropdown,
  InputRow,
  Textbox
} from '../../../components/inputs';
import HierarchySelector from '../../../components/HierarchySelector';
import { DatePicker } from '../../../components/inputs/DateTimePicker';
import RadioButtons from '../../../components/inputs/RadioButtons';
import { getLoggedInUser } from '../../../selectors/users';
import { Rearranger } from '../../../components/Rearranger';
import { TRAINING_TYPES } from '../../../constants/constants';
import List from '../../../components/List';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../../selectors/company';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import LeftNav from '../../../components/LeftNav';
import ReassignUserModal from '../../../components/Modal/reassignUserModal';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';
import Modal from '../../../components/Modal';

import styles from './styles.module.scss';

export default function TrainingCourseCreate() {
  const { id, duplicate } = useParams();
  const company = useSelector(getActiveCompany);
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(getLoggedInUser);
  const activeLocation = useSelector(getActiveLocationId);
  const activeProject = useSelector(getActiveProject);
  const fromScreen = location.state?.fromScreen;

  const emptyCourse = {
    folderId: null,
    groupIds: [],
    projectIds: [],
    title: '',
    instructions: '',
    sequential: false,
    assignOn: null,
    dueDate: null,
    trainings: [],
    assignedTo: []
  };

  const [course, setCourse] = useState(emptyCourse);
  const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
  const [originalAssignedTo, setOriginalAssignedTo] = useState([]);
  const [jobList, setJobList] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [editing, setEditing] = useState(false);
  const [creating, setCreating] = useState(false);
  const [openUnsavedModal, setOpenUnsavedModal] = useState(false);
  const [folders, setFolders] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [approverModal, setApproverModal] = useState(false);
  const [selectedTraining, setSelectedTraining] = useState(null);
  const [selectedApprover, setSelectedApprover] = useState(null);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);
  const [removeAssigneeModal, setRemoveAssigneeModal] = useState(false);
  const [removedAssignees, setRemoved] = useState([]);

  const handleAnswer = answer => {
    setUnsavedChanges(true);
    setCourse({ ...course, ...answer });
  };

  const trainingBox = training => {
    const needsApprover = training.needsApproval && !training.approver;
    return (
      <div
        className={styles.trainingText}
        onClick={() => {
          if (training.needsApproval) {
            setApproverModal(true);
            setSelectedApprover(training.approver);
            setSelectedTraining(training);
          }
        }}
      >
        <p style={{ fontWeight: 'bold' }}>{training.title}</p>
        <p>
          {TRAINING_TYPES.find(t => t.value === training.type)?.label ??
            'Digital Training'}
        </p>
        {training.needsApproval ? (
          needsApprover ? (
            <p className={styles.errorText}>Additional Information Required</p>
          ) : (
            <p className={styles.linkText}>Change Approver</p>
          )
        ) : null}
      </div>
    );
  };

  useEffect(() => {
    if (course.assignBy === 1) {
      getJobList().then(r => {
        setJobList(r?.map(job => ({ value: job, label: job })));
      });
    }
  }, [course.assignBy]);
  useEffect(() => {
    fetchCompanyUsers().then(setEmployees);
  }, []);

  useEffect(() => {
    getCourseTemplates().then(r =>
      setTemplates(
        [{ label: '', value: null }].concat(
          r.map(temp => ({ ...temp, value: temp._id, label: temp.title }))
        )
      )
    );
    const local = JSON.parse(localStorage.getItem('trainingCourse'));
    if (local) {
      setUnsavedChanges(location.state?.newTraining?.title);
      if (id) setEditing(true);
      else setCreating(true);
      setUnsavedChanges(true);
      const trainingIndex = local.trainings?.findIndex(
        t =>
          (t._id ?? t.trainingTemplateId) ===
          (location.state?.oldTemplate ?? location.state?.newTraining?._id)
      );
      let newTrainings =
        local.trainings?.filter(
          t =>
            (t._id ?? t.trainingTemplateId) !==
            (location.state?.oldTemplate ?? location.state?.newTraining?._id)
        ) ?? [];

      if (trainingIndex > -1)
        newTrainings = [
          ...newTrainings.slice(0, trainingIndex),
          location.state?.newTraining,
          ...newTrainings.slice(trainingIndex)
        ];
      else
        newTrainings = newTrainings.concat(location.state?.newTraining ?? []);
      setCourse({
        ...local,
        trainings: newTrainings
      });
      delete location.state?.newTraining;
      localStorage.removeItem('trainingCourse');
    } else if (id) {
      getTrainingCourseById(id).then(r => {
        setOriginalAssignedTo(r.assignedTo);
        if (duplicate) {
          delete r._id;
          delete r.auditLog;
          setCourse({
            ...r,
            title: `${r.title} - Copy`
          });

          setCreating(true);
        } else {
          setCourse(r);
        }
      });
    } else {
      setCreating(true);
    }
    fetchDocumentFoldersByType('TrainingCourses').then(r =>
      setFolders(
        [{ value: null, label: ' ' }].concat(
          r.map(f => {
            return { value: f._id, label: f.name };
          })
        )
      )
    );
  }, [id, duplicate, location.state, activeLocation, user]);

  const handleBack = () => {
    history.push(fromScreen ?? '/app/trainingCourses');
  };

  const handleSubmit = () => {
    if (creating)
      createTrainingCourse({
        ...course,
        groupIds: activeLocation ? [activeLocation] : course.groupIds,
        projectIds: activeProject ? [activeProject] : course.projectIds
      }).then(handleBack);
    else updateTrainingCourse(course).then(handleBack);
  };

  const handleGroupChange = values => {
    const validProjectIds = company.groups
      .map(g => {
        if (values.includes(g._id)) {
          return g.projects.map(p => p._id);
        } else {
          return [];
        }
      })
      .reduce((acc, x) => acc.concat(x), []);

    const newProjectIds = course.projectIds?.filter(p =>
      validProjectIds.includes(p)
    );
    const validUsers = course.assignedTo?.filter(user =>
      employees.find(
        u =>
          u._id.toString() === user && u.groupIds.some(g => values.includes(g))
      )
    );
    handleAnswer({
      groupIds: values,
      projectIds: newProjectIds,
      assignedTo: values?.length ? validUsers : course.assignedTo
    });
  };

  const handleArchive = () => {
    updateTrainingCourse({
      ...course,
      isArchived: !course.isArchived
    }).then(response => {
      if (response.isArchived) history.push('/app/trainingCourses');
      else {
        getTrainingCourseById(response._id).then(setCourse);
      }
    });
  };

  const handleRemoveAssignees = () => {
    updateTrainingCourse({ ...course, removedAssignees }).then(() => {
      dispatch(addMessage({ message: 'Successfully Removed Assignee(s)' }));
      history.goBack();
    });
  };

  const canSubmit =
    course.title?.trim() &&
    (user.accessLevel > 500 || course.groupIds?.length || activeLocation) &&
    course.assignOn &&
    course.dueDate &&
    (course.assignedTo?.length || course.assignByJobTitles?.length) &&
    ((course.trainings?.length &&
      course.trainings.every(t => !t.needsApproval || t.approver)) ||
      !creating);

  const removeableAssignees = creating
    ? []
    : course?.assignedTo
        ?.map(a => {
          let allTrainingsCompleted = course.trainings
            ?.filter(t => t.assignedTo[0] === a)
            ?.every(t => t.isCompleted || t.completionDate);
          return allTrainingsCompleted ? null : a;
        })
        ?.filter(a => a);

  const header = (
    <Header
      title={`${creating ? 'Create ' : editing ? 'Edit ' : ''}Training Course`}
      needsSaved={hasUnsavedChanges}
      section="Training Course"
      clickBack={() =>
        hasUnsavedChanges ? setOpenUnsavedModal(true) : handleBack()
      }
      pageActionOptions={
        !creating || course.completionDate
          ? [
              {
                label:
                  editing || course?.isArchived
                    ? 'Delete Course'
                    : 'Edit Course',
                onClick: () =>
                  editing || course?.isArchived
                    ? setDeleteModal(true)
                    : setEditing(true),
                color:
                  editing || course?.isArchived ? 'redOutline' : 'blueOutline',
                visible: true
              },
              {
                label: `Remove Assignees`,
                onClick: () => setRemoveAssigneeModal(true),
                color: 'blueOutline',
                visible:
                  removeableAssignees?.length &&
                  course?.assignedTo?.length > 1 &&
                  !course.isCompleted &&
                  (user.accessLevel === 900 ||
                    course.createdByUserId === user._id)
              },
              {
                label: `${course?.isArchived ? 'Un-' : ''}Archive Course`,
                onClick: () => handleArchive(),
                color: 'blueOutline',
                visible: user.accessLevel > 400
              }
            ]
          : null
      }
    />
  );
  const getQuickNav = () => {
    let leftNav = [{ label: course.title, id: 'title' }].concat(
      course.trainingTemplates?.length
        ? course?.trainingTemplates?.map(template => ({
            label: template.title,
            id: template._id.toString()
          }))
        : []
    );

    return leftNav;
  };

  const trainingListColumns = [
    {
      key: 'assignedTo',
      label: 'Employees',
      datatype: 'users'
    },
    {
      key: 'completionStatus',
      enum: [
        'Assigned',
        'Completed',
        'In-Progress',
        'Future Assignment Scheduled',
        'Awaiting Approval'
      ]
    },
    { key: 'completionDate', datatype: 'date' }
  ];

  const notAssignedYet =
    !creating &&
    moment(course.assignOn)
      .startOf('day')
      .isAfter(moment());

  const footer = (
    <SaveCancelFooter
      saveButtonClick={() =>
        !canSubmit ? setMissingModalOpen(true) : handleSubmit()
      }
      cancelButtonClick={() =>
        hasUnsavedChanges
          ? setOpenUnsavedModal(true)
          : creating
          ? handleBack()
          : setEditing(false)
      }
      editing={editing}
      onMouseEnter={() => setMissingRequired(!canSubmit)}
    />
  );

  const canAddTrainings =
    course.title?.trim() &&
    course.assignOn &&
    course.dueDate &&
    (course.assignedTo?.length || course.assignByJobTitles?.length);

  return (
    <>
      <HeaderAndFooter
        Header={header}
        Footer={footer}
        Left={!editing && !creating ? <LeftNav items={getQuickNav()} /> : null}
        showFooter={editing || creating}
      >
        <Card showHeader={!creating} title={course?.title} name="title">
          <Dropdown
            options={templates?.map(t => ({
              ...t,
              isVisible: !t.isArchived
            }))}
            fieldLabel="Course Template"
            onChange={v => {
              const foundTemplate = templates.find(t => t._id === v);
              handleAnswer({
                ...emptyCourse,
                ...(foundTemplate ?? {}),
                disabledTrainings: foundTemplate?.trainings
                  ?.filter(t => !t.nscCompanyIds?.length)
                  ?.map(t => t._id ?? t),
                nonDeletable: foundTemplate?.trainings?.map(t => t._id ?? t),
                masterTemplate: v,
                _id: null
              });
            }}
            currentValue={course.masterTemplate}
            disabled={!creating}
            searchable
          />
          <Dropdown
            options={folders}
            fieldLabel="Folder"
            onChange={v => handleAnswer({ folderId: v })}
            currentValue={course.folderId}
            disabled={!editing && !creating}
            searchable
          />
          <HierarchySelector
            multi
            onGroupChange={handleGroupChange}
            onProjectChange={values => handleAnswer({ projectIds: values })}
            groupDisabled={!creating}
            projectDisabled={!creating}
            groupId={course?.groupIds}
            projectId={course?.projectIds}
            groupRequired={user.accessLevel < 900}
          />
          <Textbox
            isRequired
            fieldLabel="Training Course Title"
            currentValue={course.title}
            disabled={!creating || course.masterTemplate}
            onChange={v => handleAnswer({ title: v })}
            touched={missingRequired && !course.title}
          />
          <Textbox
            fieldLabel="Instructions"
            type="textarea"
            onChange={v => handleAnswer({ instructions: v })}
            disabled={!creating}
            currentValue={course.instructions}
          />
          <Checkbox
            fieldLabel="Release Sequentially"
            currentValue={course.sequential}
            onChange={v => handleAnswer({ sequential: v })}
            disabled={!creating || course.masterTemplate}
            tooltip="If selected, the trainings will release one after the other. Otherwise trainings will release all at once."
            displayTooltip
          />
          {/* hide until course creating all the trainings is addressed (https://gitlab.com/ireportsource/ireportsource-react-app/-/issues/1162#note_1859805001)*/}
          {/* <Checkbox
            fieldLabel="Immediately Reassign if Assignee(s) fail?"
            currentValue={course.immediatelyReassign}
            displayTooltip
            disabled={!creating || course.masterTemplate}
            tooltip={
              <div>
                <p>
                  When this is selected, any assignee that fails this course
                  will be automatically reassigned this course.
                </p>
                <p>
                  This course will be reassigned after everyone has completed
                  the course.
                </p>
              </div>
            }
            onChange={value =>
              handleAnswer({
                immediatelyReassign: value
              })
            }
          /> */}
          <DatePicker
            fieldLabel="Assign On"
            isRequired
            onChange={v =>
              handleAnswer({
                assignOn: v,
                dueDate: moment(v).isAfter(course.dueDate)
                  ? null
                  : course.dueDate
              })
            }
            currentValue={course.assignOn}
            disabled={!creating && (!editing || course.hasBeenAssigned)}
            name="assignOn"
            minDate={moment().add(id ? 1 : 0, 'days')}
            touched={missingRequired && !course.assignOn}
          />
          <DatePicker
            fieldLabel="Due By"
            isRequired
            onChange={v => handleAnswer({ dueDate: v })}
            currentValue={course.dueDate}
            disabled={(!editing && !creating) || !course.assignOn}
            name="dueDate"
            minDate={moment(course.assignOn)?.add(1, 'days')}
            touched={missingRequired && !course.dueDate && course.assignOn}
          />
          <RadioButtons
            options={[
              { value: 1, label: 'Job Title' },
              { value: 2, label: 'Employee' }
            ]}
            currentValue={course.assignBy}
            onChange={v => {
              handleAnswer({
                assignBy: v,
                assignedTo: [],
                assignByJobTitles: []
              });
            }}
            fieldLabel="Assign By"
            needSeparateLines
            disabled={!creating || (id && !notAssignedYet)}
            isRequired
            touched={missingRequired && !course.assignBy}
          />
          {jobList && course.assignBy === 1 ? (
            <Dropdown
              fieldLabel={
                notAssignedYet || creating ? 'Assignees' : 'Job Titles'
              }
              options={jobList}
              selectButtons
              searchable
              onChange={v => {
                if (v.length || creating)
                  handleAnswer({
                    assignByJobTitles: v
                  });
              }}
              disabled={
                (!creating || !course.assignBy) && (!editing || !notAssignedYet)
              }
              multi
              isRequired
              currentValue={course.assignByJobTitles}
              touched={missingRequired && !course.assignByJobTitles?.length}
            />
          ) : null}
          {course.assignBy === 2 || course.assignedTo?.length ? (
            <EmployeeDropdown
              fieldLabel="Assignees"
              permissions={[100, 400, 500, 900]}
              selectButtons
              onChange={v => {
                if (v.length || creating)
                  handleAnswer({
                    assignedTo: v
                  });
              }}
              limit={originalAssignedTo.length ? originalAssignedTo : null}
              searchable
              multi
              employees={employees.filter(
                e =>
                  !course.groupIds?.length ||
                  course.groupIds.filter(g => e.groupIds.includes(g)).length
              )}
              disabled={
                (!editing && !creating) ||
                (!id && !course.assignBy && !notAssignedYet) ||
                (id && (!notAssignedYet || originalAssignedTo.length <= 1))
              }
              filterChoices={[
                {
                  label: 'Inactive Employees',
                  value: 'inactive'
                }
              ]}
              currentValue={course.assignedTo}
              isRequired
              touched={missingRequired && !course.assignedTo?.length}
            />
          ) : null}
        </Card>
        {id ? (
          <InputRow className={styles.overallFooter}>
            {course.trainingTemplates?.map(template => {
              // nsc approval aren't on the template - we need to look at the training
              let nscTraining =
                template?.nscCompanyIds?.length &&
                course.trainings?.find(
                  t => t.trainingTemplateId === template._id
                );
              const nscHasApprover = nscTraining?.needsApproval;
              return (
                <Card showHeader title={template.title} name={template._id}>
                  <List
                    saveKey={`trainingCourseList-${template.title}`}
                    getRowId={r => r._id}
                    dataIsHash
                    data={course.assignedTo.map(assignee => {
                      const foundMaster = course.trainings?.find(
                        t => t.trainingTemplateId === template._id
                      );
                      const found = foundMaster?.children?.find(t =>
                        t.assignedTo.includes(assignee)
                      );
                      return {
                        _id:
                          found?._id ??
                          `${assignee} - ${template.trainingTemplateId}`,
                        completionDate: found?.completionDate,
                        assignedTo: [assignee],
                        approver: [found?.approver ?? template?.approver],
                        approveReject: found?.approveReject,
                        completionStatus:
                          found?.completionStatus ??
                          'Future Assignment Scheduled'
                      };
                    })}
                    rowClick={v =>
                      history.push({
                        pathname: `/app/training/approve/${v._id}`,
                        state: {
                          fromScreen: `/app/trainingCourses/create/${id ?? ''}`
                        }
                      })
                    }
                    name={template.title}
                    settings={trainingListColumns.concat(
                      template?.needsApproval || nscHasApprover
                        ? [
                            {
                              key: 'approver',
                              datatype: 'users'
                            },
                            {
                              key: 'approveReject',
                              accessor: v =>
                                v.approveReject
                                  ? v?.approveReject === 'approve'
                                    ? 'Approved'
                                    : 'Rejected'
                                  : ''
                            }
                          ]
                        : []
                    )}
                  />
                </Card>
              );
            })}
          </InputRow>
        ) : (
          <InputRow className={styles.overallFooter}>
            {course.trainingTemplates?.length ?? course.trainings?.length ? (
              <Rearranger
                className={styles.trainingCards}
                itemStyles={styles.trainingCard}
                items={course.trainingTemplates ?? course?.trainings ?? []}
                onChange={newTrainings => {
                  setUnsavedChanges(true);
                  handleAnswer(
                    course.trainingTemplates
                      ? { trainingTemplates: [...newTrainings] }
                      : { trainings: [...newTrainings] }
                  );
                }}
                renderItem={trainingBox}
                disabled={!creating}
                disabledItems={course.disabledTrainings}
                nonDeletableItems={course.nonDeletable}
                errorItems={course.trainings
                  .filter(t => t.needsApproval && !t.approver)
                  .map(t => t._id)}
                onDuplicate={row => {
                  const clone = cloneDeep(row);
                  delete clone._id;
                  return clone;
                }}
                inline
                hideDuplicate
                allowEdit
                handleEditRow={template => {
                  localStorage.setItem(
                    'trainingCourse',
                    JSON.stringify(course)
                  );
                  localStorage.setItem(
                    'trainingTemplate',
                    JSON.stringify({
                      ...template,
                      stage: template.nscCompanyIds?.length ? 1 : 0
                    })
                  );
                  history.push({
                    pathname: `/app/trainingCourses/addTraining`,
                    state: {
                      fromScreen: `/app/trainingCourses/create/${id ?? ''}`,
                      creating: creating,
                      excludeTemplates: course.trainings
                        .map(t => t._id ?? t.trainingTemplateId ?? t)
                        .filter(
                          t =>
                            t !==
                            (
                              template._id ??
                              template.trainingTemplateId ??
                              template
                            ).toString()
                        ),
                      invalidNames: course.trainings
                        .filter(t => t.title !== template.title)
                        .map(t => t.title.toLowerCase().trim())
                    }
                  });
                }}
              />
            ) : null}

            {creating ? (
              <Button
                color="blue"
                text="Add Training"
                onClick={() => {
                  localStorage.setItem(
                    'trainingCourse',
                    JSON.stringify(course)
                  );
                  history.push({
                    pathname: `/app/trainingCourses/addTraining`,
                    state: {
                      fromScreen: `/app/trainingCourses/create/${id ?? ''}`,
                      excludeTemplates: course.trainings.map(
                        t => t._id ?? t.trainingTemplateId ?? t
                      ),
                      invalidNames: course.trainings.map(t =>
                        t.title.toLowerCase().trim()
                      ),
                      creating: creating
                    }
                  });
                }}
                disabled={!canAddTrainings}
                inputClassName={
                  missingRequired && canAddTrainings
                    ? styles.trainingRequiredButton
                    : ''
                }
              />
            ) : null}
          </InputRow>
        )}
      </HeaderAndFooter>
      <SaveChangesModal
        isOpen={openUnsavedModal}
        savingWhat="Training Course"
        onRequestClose={() => setOpenUnsavedModal(false)}
        submitActions={handleBack}
      />
      <DeleteItemModal
        deletingWhat="Course"
        isOpen={deleteModal}
        onRequestClose={() => setDeleteModal(false)}
        submitActions={() => deleteTrainingCourse(id).then(handleBack)}
      />
      <ReassignUserModal
        title="Change Approver"
        reassignUser={selectedApprover}
        disableSubmit={!selectedApprover}
        titleClassName="redHeader"
        previousUser={selectedTraining?.approver}
        message="This course training requires approval, and needs an approver to be
            selected."
        onRequestClose={() => {
          setApproverModal(false);
          setSelectedApprover(null);
        }}
        isOpen={approverModal}
        onChange={setSelectedApprover}
        submitActions={() => {
          const found = course.trainings.findIndex(
            t => t._id === selectedTraining?._id
          );
          course.trainings[found].approver = selectedApprover;
          setApproverModal(false);
          setSelectedApprover(null);
        }}
        permissions={[500, 900]}
        placeholder="Select one.."
      />
      <MissingRequiredModal
        isOpen={missingModalOpen}
        subject="Training Course"
        onRequestClose={() => setMissingModalOpen(false)}
        isEditing={editing}
      />
      <Modal
        title="Remove Assignee(s)"
        titleClassName="redHeader"
        isOpen={removeAssigneeModal}
        submitButtonColor="red"
        submitButtonText="Save Changes"
        onRequestClose={() => {
          setRemoved([]);
          setRemoveAssigneeModal(false);
        }}
        submitActions={handleRemoveAssignees}
        disableSubmit={!removedAssignees?.length}
      >
        <span style={{ color: '#c74846', fontWeight: 'bold' }}>
          Removing an assignee is permanent. The assignee will not be able to
          complete this Course. A new Course that is similar or identical can
          still be assigned.
        </span>

        <EmployeeDropdown
          limit={removeableAssignees}
          currentValue={removedAssignees}
          onChange={values => {
            if (values.length < removeableAssignees.length) setRemoved(values);
          }}
          multi
          searchable
        />
      </Modal>
    </>
  );
}
