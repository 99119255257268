import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import history from '../../history';
import {
  getUserApprovedTrainings,
  fetchContinuousCareByEmployeeId,
  fetchEmployeeDocuments,
  fetchFireExposures,
  getUserCompletedCourses,
  fetchUserListFilter,
  updateUserListFilter
} from '../../api/v4';
import { getLoggedInUser } from '../../selectors/users';
import { PRE_MADE_DOCUMENTS, DOCUMENT_TYPES } from '../../constants/constants';
import { usePrevious } from '../../utils/hooks';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';

import AnalyticsPeriodPicker from '../../components/AnalyticsPeriodPicker';
import DashboardButtons from '../../components/Modal/dashboardButtons';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import List from '../../components/List';

import './index.css';

export default function MyDocumentList() {
  const [documents, setDocuments] = useState([]);
  const user = useSelector(getLoggedInUser);
  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();
  const [modalOpen, setModal] = useState(null);
  const [modalSelection, setModalSelection] = useState(null);
  const [empPeriod, setEmpPeriod] = useState();
  const [currentPeriod, setCurrentPeriod] = useState();
  const [loading, setLoading] = useState(false);
  const prevPeriod = usePrevious(currentPeriod);

  const handleOpenModal = modalId => {
    setModal(modalId);
  };
  const handleCloseModal = () => {
    setModalSelection(null);
    setModal(null);
  };
  const modalProps = {
    document: {
      title: 'Create New Document',
      buttons: DOCUMENT_TYPES,
      modalSubmit: () => {
        modalSelection === 'upload'
          ? history.push('/app/myDocumentContainer')
          : setModalSelection(null, handleOpenModal('premadeDoc'));
      }
    },
    premadeDoc: {
      title: 'Create New Premade Document',
      buttons: PRE_MADE_DOCUMENTS,
      modalSubmit: () => {
        modalSelection === 'rec'
          ? history.push('/app/recurrenceContainer')
          : modalSelection === 'trans'
          ? history.push('/app/transitionContainer')
          : history.push('/app/fireexposure');
      }
    }
  };

  useEffect(() => {
    fetchUserListFilter(user._id, 'myDocument').then(r => {
      setCurrentPeriod(r);
      setEmpPeriod(r);
    });
  }, [user._id]);

  useEffect(() => {
    setLoading(true);
    async function fetchDocs() {
      const docs = await fetchEmployeeDocuments(user._id, { ...currentPeriod });
      const ccDocs = await fetchContinuousCareByEmployeeId(user._id, {
        ...currentPeriod
      });
      const fireExposures = await fetchFireExposures(user._id, {
        ...currentPeriod
      });

      const { digital, inPerson } = await getUserApprovedTrainings(user._id, {
        ...currentPeriod
      });
      const courses = await getUserCompletedCourses(user._id, {
        ...currentPeriod
      });
      let trainings = [...digital, ...inPerson];
      const completedAndApprovedTraining = trainings.map(training => {
        return {
          ...training,
          label: training.title,
          formData: {
            employeeName: `${user.firstName} ${user.lastName}`
          }
        };
      });
      setDocuments([
        ...docs,
        ...ccDocs,
        ...fireExposures,
        ...completedAndApprovedTraining,
        ...courses
      ]);
      setLoading(false);
    }
    if (currentPeriod && !isEqual(prevPeriod, currentPeriod)) fetchDocs();
  }, [user, prevPeriod, currentPeriod]);

  const handleView = original => {
    if (original.title) {
      if (original.type === 'course')
        history.push({
          pathname: `/app/trainingCourses/view/${original._id}`,
          state: { fromScreen: '/app/myDocumentsStages' }
        });
      else history.push(`/app/training/view/${original._id}`);
    } else {
      if (
        original.label ===
        'Recurrence, Relapse During Recovery & Continuing Treatment'
      ) {
        history.push(`/app/recurrenceContainer/${original._id}`);
      } else if (original.label === 'Transitional Return to Work Program') {
        history.push(`/app/transitionContainer/${original._id}`);
      } else if (original.label === 'Personal Exposure Form') {
        history.push(`/app/fireexposure/${original._id}`);
      } else {
        history.push(`/app/myDocumentContainer/${original._id}`);
      }
    }
  };

  const header = (
    <Header
      title="My Documents"
      rightButtons={{
        text: 'Create Document',
        color: 'blue',
        onClick: () => handleOpenModal('document'),
        visible: !activeGroup?.isHidden && !activeProject?.isHidden
      }}
    />
  );

  const columns = [
    { key: 'label', label: 'Title' },
    { key: 'createdAt', label: 'Created Date', datatype: 'date' },
    {
      key: 'expirationDate',
      datatype: 'futureDate',
      accessor: r => r.expirationDate ?? 'Does Not Expire'
    },
    {
      key: 'status',
      accessor: r => {
        if (
          r.label ===
          'Recurrence, Relapse During Recovery & Continuing Treatment'
        ) {
          if (r.isApprovedBySupervisor) {
            return 'Approved';
          } else {
            return 'Supervisor Review';
          }
        } else if (r.label === 'Transitional Return to Work Program') {
          if (!r.isApprovedBySupervisor) {
            return 'Supervisor Review';
          } else if (r.isApprovedByHr) {
            return 'Approved';
          } else {
            return 'HR Review';
          }
        } else if (r.title) {
          if (r.type === 'course') return 'Completed';
          return 'Approved';
        } else {
          return '--';
        }
      },
      enum: ['Approved', 'Supervisor Review', 'HR Review', 'Completed']
    },
    {
      key: 'docType',
      label: 'Document Type',
      accessor: r => {
        if (r.title) {
          if (r.type === 'course') return 'Course';
          return 'Training';
        } else if (
          r.label === 'Transitional Return to Work Program' ||
          r.label ===
            'Recurrence, Relapse During Recovery & Continuing Treatment' ||
          r.label === 'Personal Fire Exposure Form'
        ) {
          return 'Premade Documents';
        } else {
          return 'Uploaded Documents';
        }
      },
      enum: ['Training', 'Course', 'Premade Documents', 'Uploaded Documents']
    }
  ];

  return (
    <HeaderAndFooter Header={header}>
      <AnalyticsPeriodPicker
        period={currentPeriod}
        onChange={v => {
          setCurrentPeriod(v);
          setDocuments([]);
        }}
        onSave={v =>
          updateUserListFilter({
            empId: user._id,
            type: 'myDocument',
            period: v
          }).then(() => setEmpPeriod(v))
        }
        allowAll
        savedPeriod={empPeriod}
        listPicker
      />
      <List
        saveKey="docStages"
        data={documents}
        dataIsHash
        settings={columns}
        rowClick={handleView}
        loading={loading}
      />

      <DashboardButtons
        modalOpen={modalOpen}
        modalProps={modalProps}
        handleCloseModal={handleCloseModal}
        modalSelection={modalSelection}
        setModalSelection={setModalSelection}
        company={activeCompany}
      />
    </HeaderAndFooter>
  );
}
