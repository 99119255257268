import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import Modal from '.';
import classnames from 'classnames/bind';
import { Button } from '../inputs';
import svgMapper from '../../utils/svgMapper';
import RadioButtons from '../inputs/RadioButtons';
import { EmployeeDropdown } from '../inputs';
import { DatePicker } from '../inputs/DateTimePicker';

import styles from './styles.module.scss';

export default function DashboardButtons(props) {
  const {
    handleCloseModal,
    modalProps,
    modalOpen,
    modalSelection,
    setModalSelection,
    company,
    alreadySelected,
    submitButtonText,
    deactivatedUsers,
    group
  } = props;

  const [missingRequired, setMissingRequired] = useState(false);
  const [empDropdown] = useState(
    modalProps?.formOptions?.extraFormOptions?.usersToComplete ?? []
  );

  const renderExtraOptions = modalProps?.formOptions?.renderExtraOptions;
  const extraFormOptions = modalProps?.formOptions?.extraFormOptions;

  const missingRequiredFormOptions =
    renderExtraOptions &&
    (!extraFormOptions?.usersToComplete?.length ||
      (extraFormOptions?.method === 2 ? !extraFormOptions?.formsDueBy : false));

  const allUsersDeactivated =
    extraFormOptions?.usersToComplete?.length === deactivatedUsers?.length &&
    extraFormOptions?.method === 2;

  const submitTooltipMessage = allUsersDeactivated
    ? 'All selected employees are deactivated and therefore cannot be given assignments.'
    : missingRequiredFormOptions
    ? !extraFormOptions?.usersToComplete?.length
      ? 'At least one employee must be selected'
      : extraFormOptions?.method === 2 && !extraFormOptions.formsDueBy
      ? 'Due date required'
      : null
    : null;

  const renderColumbusRecurrenceOptions = () => {
    const setExtraFormOptions = modalProps?.formOptions?.setExtraFormOptions;
    return (
      <div>
        <RadioButtons
          fieldLabel={`Who will fill out ${
            renderExtraOptions === 'rec' ? 'Recurrence' : 'Transitional'
          } forms?`}
          options={[
            { label: 'Supervisor (or above)', value: 1 },
            { label: 'Injured Employee(s)', value: 2 }
          ]}
          currentValue={extraFormOptions?.method}
          tooltip="For an employee to fill out this form, they must be an active user."
          displayTooltip
          onChange={v =>
            setExtraFormOptions({
              method: v,
              formsDueBy: v !== 2 ? null : extraFormOptions?.formsDueBy
            })
          }
        />
        <EmployeeDropdown
          fieldLabel="Employee(s) who need form?"
          currentValue={extraFormOptions?.usersToComplete}
          onChange={v => setExtraFormOptions({ usersToComplete: v })}
          isRequired
          multi
          searchable
          group={group}
          limit={empDropdown}
        />
        {extraFormOptions?.method === 2 && !allUsersDeactivated ? (
          <DatePicker
            pickTime
            fieldLabel="Form(s) Due By"
            isRequired
            currentValue={extraFormOptions?.formsDueBy}
            onChange={v => setExtraFormOptions({ formsDueBy: v })}
            minDate={moment()}
            touched={missingRequired && !extraFormOptions?.formsDueBy}
            errorMessage="Must be a future date/time"
            showWarning={moment().isAfter(extraFormOptions?.formsDueBy)}
            name="formsDueBy"
          />
        ) : null}
        {deactivatedUsers?.length && extraFormOptions?.method === 2 ? (
          <>
            <div className={styles.usersDeactivatedWarning}>
              The following users are not active and therefore cannot receive
              assignments:
            </div>
            <div className={styles.usersDeactivatedWarning}>
              {deactivatedUsers
                ?.map(user => {
                  return `${user.firstName} ${user.lastName}`;
                })
                .join(', ')}
            </div>
          </>
        ) : null}
      </div>
    );
  };

  return (
    <Modal
      medium
      title={modalOpen ? modalProps?.[modalOpen].title : ''}
      titleClassName="blueHeader"
      isOpen={modalOpen}
      onRequestClose={handleCloseModal}
      submitActions={modalProps?.[modalOpen]?.modalSubmit}
      submitButtonText={submitButtonText ?? 'Create'}
      submitButtonColor="blue"
      disableSubmit={
        !modalSelection?.length ||
        missingRequiredFormOptions ||
        allUsersDeactivated
      }
      submitTooltip={submitTooltipMessage}
      onMouseEnter={() =>
        setMissingRequired(renderExtraOptions && missingRequiredFormOptions)
      }
    >
      <span className={styles.modalText}>{modalProps?.[modalOpen]?.text}</span>
      <div
        className={
          modalProps?.[modalOpen]?.buttons ? styles.modalButtons : null
        }
      >
        {modalProps?.[modalOpen]?.buttons
          ? modalProps?.[modalOpen]?.buttons
              .filter(
                b =>
                  !(
                    (!company.showAircraft && b.isAircraft) ||
                    (!company.showExposure && b.value === 'exposure') ||
                    (company.showAircraft && b.label === 'Spill') ||
                    (!company.hasLockoutTagout && b.value === 'lockoutTagout')
                  )
              )
              .map(x => (
                <>
                  <div data-for={x.label} data-tip="customTheme">
                    <Button
                      className={styles.modalButton}
                      color={
                        modalSelection?.includes(x.value) ||
                        alreadySelected?.includes(x.value)
                          ? 'blue'
                          : x.disabled
                          ? 'grey-stroke'
                          : ''
                      }
                      image={svgMapper(x.svg)}
                      imageClass={classnames([
                        styles.imageStyles,
                        x.disabled
                          ? styles.disabled
                          : modalSelection?.includes(x.value) ||
                            alreadySelected?.includes(x.value)
                          ? styles.selected
                          : styles.notSelected
                      ])}
                      inputClassName={styles.button}
                      text={<p className={styles.buttonText}>{x.label}</p>}
                      onClick={() =>
                        modalProps?.[modalOpen]?.onClick
                          ? modalProps?.[modalOpen]?.onClick(x.value)
                          : setModalSelection(x.value)
                      }
                      testID={x.label}
                      disabled={
                        x.disabled || alreadySelected?.includes(x.value)
                      }
                    />
                  </div>
                  <ReactTooltip
                    className={classnames(styles.tooltip, 'customTheme')}
                    id={x.label}
                  >
                    {x.label}
                  </ReactTooltip>
                </>
              ))
          : modalProps?.[modalOpen]?.dropdown ?? <></>}
      </div>
      {renderExtraOptions ? renderColumbusRecurrenceOptions() : null}
    </Modal>
  );
}
