import React from 'react';
import moment from 'moment';
import { Checkbox, Dropdown, EmployeeDropdown, TwoColumn } from '../../inputs';
import Card from '../../Card';
import { DatePicker } from '../../inputs/DateTimePicker';
import Signature from '../../inputs/Signature';
import Textbox from '../../inputs/Textbox';

export default function RecurrenceCard({
  onChange,
  employeeName,
  dateOfRecurrence,
  dateReported,
  datePhysicianAppt,
  physicianInfo,
  incidentDesc,
  reinjuryOccurred,
  injuryHistory,
  disabled,
  dateOfOriginalInjury,
  timeNotDetermined,
  name,
  missingRequired,
  isNa,
  incidentId,
  employeeSignature,
  availableApprovers,
  supervisorId,
  supervisorSignature,
  supervisorCanSign,
  group,
  employeeId,
  disableNa,
  _id,
  changeApprovers,
  changeEmployees,
  needsToCreate,
  handleRemoveForm,
  isColumbus,
  dayforceId,
  providerType,
  otherProviderType,
  isAssignment,
  isCreating
}) {
  const providerOptions = [
    { label: 'Doctor', value: 1 },
    { label: 'Physical Therapy', value: 2 },
    { label: 'Surgery', value: 3 },
    { label: 'Test', value: 4 },
    { label: 'Other', value: 5 }
  ];
  const renderInfoForIncident = (
    <>
      <Signature
        fieldLabel="Employee Signature"
        currentValue={employeeSignature}
        onChange={v => onChange({ employeeSignature: v })}
        ownerType="incidentCc"
        ownerId={_id}
        isRequired
        disabled={disabled || (employeeSignature && !isColumbus)}
        touched={missingRequired && !employeeSignature}
      />
      <EmployeeDropdown
        fieldLabel="Select Your Supervisor"
        onChange={v => onChange({ supervisorId: v, supervisorSignature: null })}
        currentValue={supervisorId}
        disabled={
          isNa ? true : !changeApprovers && (disabled || supervisorSignature)
        }
        searchable
        name="approver"
        required
        employees={availableApprovers}
        touched={missingRequired && !supervisorId}
      />
      {supervisorSignature || supervisorCanSign ? (
        <Signature
          fieldLabel="Supervisor Signature"
          currentValue={supervisorSignature}
          onChange={v => onChange({ supervisorSignature: v })}
          ownerType="incidentCc"
          ownerId={_id}
          disabled={!supervisorCanSign}
          isRequired
          touched={missingRequired && !supervisorSignature}
        />
      ) : null}
    </>
  );
  const renderColumbusFields = () => (
    <>
      <Textbox
        fieldLabel="Dayforce Employee Id"
        isRequired
        disabled={disabled}
        onChange={v => onChange({ dayforceId: v })}
        currentValue={dayforceId}
        touched={missingRequired && !dayforceId}
      />
      <DatePicker
        pickTime
        fieldLabel="Date of Recurrence"
        isRequired
        disabled={disabled}
        onChange={v => onChange({ dateOfRecurrence: v })}
        currentValue={dateOfRecurrence}
        name="dateOfRecurrence"
        touched={
          missingRequired &&
          (!dateOfRecurrence || moment().isBefore(dateOfRecurrence))
        }
        maxDate={moment()}
        errorMessage={
          moment().isBefore(dateOfRecurrence)
            ? 'Cannot be future date/time'
            : null
        }
        showWarning={moment().isBefore(dateOfRecurrence)}
      />
      <DatePicker
        pickTime
        fieldLabel="Date of Provider Appointment"
        isRequired
        disabled={disabled}
        onChange={v => onChange({ datePhysicianAppt: v })}
        currentValue={datePhysicianAppt}
        name="physicianAppointment"
        touched={missingRequired && !datePhysicianAppt}
      />
      <Dropdown
        isRequired
        fieldLabel="Type of Provider"
        disabled={disabled}
        onChange={v =>
          onChange({
            providerType: v,
            otherProviderType: v !== 5 ? '' : otherProviderType
          })
        }
        currentValue={providerType}
        options={providerOptions}
        touched={missingRequired && !providerType}
      />
      {providerType === 5 ? (
        <Textbox
          fieldLabel="Other Provider Type"
          isRequired
          disabled={disabled}
          onChange={v => onChange({ otherProviderType: v })}
          currentValue={otherProviderType}
          touched={missingRequired && !otherProviderType}
        />
      ) : null}
    </>
  );
  const renderStandardFields = () => (
    <>
      <DatePicker
        pickTime
        fieldLabel="Date of Recurrence"
        isRequired
        disabled={disabled}
        onChange={values => onChange({ dateOfRecurrence: values })}
        currentValue={dateOfRecurrence}
        name="dateOfRecurrence"
        touched={
          missingRequired &&
          (!dateOfRecurrence || moment().isBefore(dateOfRecurrence))
        }
        maxDate={moment()}
        errorMessage={
          moment().isBefore(dateOfRecurrence)
            ? 'Cannot be future date/time'
            : null
        }
        showWarning={moment().isBefore(dateOfRecurrence)}
      />
      <DatePicker
        pickTime
        fieldLabel="Date Reported"
        isRequired
        disabled={disabled}
        onChange={value => onChange({ dateReported: value })}
        currentValue={dateReported}
        name="dateReported"
        touched={
          missingRequired && (!dateReported || moment().isBefore(dateReported))
        }
        maxDate={moment()}
        errorMessage={
          moment().isBefore(dateReported) ? 'Cannot be future date/time' : null
        }
        showWarning={moment().isBefore(dateReported)}
      />
      <DatePicker
        pickTime
        fieldLabel="Date of Physician Appointment"
        isRequired
        disabled={disabled}
        onChange={value => onChange({ datePhysicianAppt: value })}
        currentValue={datePhysicianAppt}
        name="physicianAppointment"
        touched={missingRequired && !datePhysicianAppt}
      />
    </>
  );
  return (
    <Card
      name={name}
      showHeader
      title="Recurrence, Relapse During Recovery & Continuing Treatment"
      wide
      rightButton={
        isAssignment ? null : incidentId ? (
          needsToCreate ? (
            <img
              src={require('../../../assets/images/remove.png')}
              alt="remove row"
              onClick={handleRemoveForm}
              style={{ cursor: 'pointer' }}
            />
          ) : (
            <Checkbox
              fieldLabel="Not Applicable"
              currentValue={isNa}
              onChange={v => onChange({ isNa: v })}
              disabled={disableNa}
            />
          )
        ) : null
      }
    >
      <TwoColumn>
        {!employeeName ? (
          <EmployeeDropdown
            fieldLabel="Employee Name"
            onChange={v => onChange({ employeeId: v, employeeSignature: null })}
            currentValue={employeeId}
            disabled={
              isNa
                ? true
                : (!changeEmployees &&
                    (disabled || employeeSignature || isCreating)) ||
                  isColumbus
            }
            searchable
            name="approver"
            group={group}
            isRequired
            touched={missingRequired && !employeeId}
          />
        ) : (
          <Textbox
            fieldLabel="Employee Name"
            placeholder="ex. Jane Doe"
            isRequired
            onChange={v => onChange({ employeeName: v })}
            currentValue={employeeName}
            disabled={disabled}
            touched={missingRequired && !employeeName}
            error
          />
        )}
        {isColumbus ? renderColumbusFields() : renderStandardFields()}
      </TwoColumn>
      <DatePicker
        pickTime
        fieldLabel="Date of Original Injury"
        isRequired
        disabled={disabled}
        onChange={value =>
          onChange({
            dateOfOriginalInjury: value,
            timeNotDetermined: false
          })
        }
        currentValue={dateOfOriginalInjury}
        showNA
        isNA={timeNotDetermined}
        onNAChange={() =>
          onChange({
            timeNotDetermined: !timeNotDetermined,
            dateOfOriginalInjury: ''
          })
        }
        name="originalInjury"
        touched={
          missingRequired &&
          (!dateOfOriginalInjury || moment().isBefore(dateOfOriginalInjury))
        }
        maxDate={moment()}
        errorMessage={
          moment().isBefore(dateOfOriginalInjury)
            ? 'Cannot be future date/time'
            : null
        }
        showWarning={moment().isBefore(dateOfOriginalInjury)}
      />
      <Textbox
        fieldLabel={`${
          isColumbus ? 'Provider' : 'Physician'
        } Name and Information`}
        type="textarea"
        placeholder="Name, Specialization, Office Address, Contact Info"
        onChange={v => onChange({ physicianInfo: v })}
        currentValue={physicianInfo}
        disabled={disabled}
      />
      <Textbox
        fieldLabel="Describe the incident that caused this re-injury (include body parts affected)"
        placeholder="Type here"
        type="textarea"
        isRequired
        onChange={v => onChange({ incidentDesc: v })}
        currentValue={incidentDesc}
        disabled={disabled}
        touched={missingRequired && !incidentDesc}
      />
      <Textbox
        fieldLabel="Where the re-injury occurred"
        placeholder="(Work, Home, Grocery Store, etc.)"
        isRequired
        onChange={v => onChange({ reinjuryOccurred: v })}
        currentValue={reinjuryOccurred}
        disabled={disabled}
        touched={missingRequired && !reinjuryOccurred}
      />
      {isColumbus && !injuryHistory ? null : (
        <Textbox
          fieldLabel="Describe, in detail, the history of problems related to the injury"
          placeholder="Type here"
          type="textarea"
          isRequired
          onChange={v => onChange({ injuryHistory: v })}
          currentValue={injuryHistory}
          disabled={disabled}
          touched={missingRequired && !injuryHistory}
        />
      )}
      {incidentId ? renderInfoForIncident : null}
    </Card>
  );
}
