import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactLoading from 'react-loading';
import moment from 'moment';
import { emailAssignees, getManyContinuousCare } from '../../api/v4';
import history from '../../history';
import { PRE_MADE_DOCUMENTS } from '../../constants/constants';
import config from '../../config/config';
import {
  fetchIncidentByIdRequest,
  updateReportComponentRequest
} from '../../actions/incidents';
import { setActiveTaskRequest } from '../../actions/tasks';

import {
  getActiveIncidentSelector,
  getIncidentsLoadingSelector
} from '../../selectors/incidents';
import { getActiveReportsSelector } from '../../selectors/reports';
import { getAllUsers, getLoggedInUser } from '../../selectors/users';
import { getActiveCompany } from '../../selectors/company';
import { hasAccess } from '../../utils/incidentHelper';
import { isComplete } from '../../utils/reportHelper';
import { useSocket } from '../../utils/withSocket';
import { isFutureDate } from '../../utils/formValidation';
import Assignee from '../../components/Assignee';
import DashboardButtons from '../../components/Modal/dashboardButtons';
import Header from '../../components/Header';
import { HeaderAndFooter } from '../../components/HeaderAndFooter';
import LeftNav from '../../components/LeftNav';
import MarkAsCompleteModal from '../../components/Modal/markAsCompleteModal';
import Modal from '../../components/Modal';
import RecurrenceInfoCard from '../../components/recurrenceCards/RecurrenceInfoCard';
import { SaveCancelFooter } from '../../components/Footer';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import Textbox from '../../components/inputs/Textbox';
import TransitionalInfoCard from '../../components/transitionalCards/TransitionalInfoCard';
import ViewOnly from '../../components/ViewOnly';
import ContinuousCareCard from '../../components/inputs/ContinuousCareCard';
import { DatePicker } from '../../components/inputs/DateTimePicker';

export default function ReturnToWorkContainer(props) {
  const dispatch = useDispatch();
  const { stage } = useParams();
  const isSigning = history.location?.state?.isSigning;
  const updateReportComponent = payload =>
    dispatch(updateReportComponentRequest(payload));
  const setTask = payload => dispatch(setActiveTaskRequest(payload));

  const activeIncident = useSelector(getActiveIncidentSelector);
  const activeReport = useSelector(getActiveReportsSelector);
  const incidentLoading = useSelector(getIncidentsLoadingSelector);
  const loggedInUser = useSelector(getLoggedInUser);
  const company = useSelector(getActiveCompany);
  const users = useSelector(getAllUsers);
  const injuredEmployees =
    activeIncident.answers[config.staticIds.froiInjuredEmployeeQuestion];

  const [openModalType, setOpenModalType] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [report, setReport] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [missingRequired, setMissingRequired] = useState(false);
  const [formType, setFormType] = useState();
  const [forms, setForms] = useState([]);
  const [changeApprovers, setChangeApprovers] = useState(false);
  const [changeEmployees, setChangeEmployees] = useState(false);
  const [extraFormOptions, setExtraFormOptions] = useState({
    method: 1,
    usersToComplete: injuredEmployees
  });
  const [showDateChangeModal, setShowDateChangeModel] = useState(false);
  const [formIndex, setFormIndex] = useState();
  const [modalDueDate, setModalDueDate] = useState();

  const { socketDirty, socketLock, lockInfo, locked } = useSocket(
    activeIncident._id,
    activeReport?.report?.[0]?.[0]
  );

  useEffect(() => {
    if (activeReport?.report) {
      setReport([...activeReport.report]);
      getManyContinuousCare({
        ids: activeReport.report[0][1].forms?.map(d => d.docId)
      }).then(r => setForms(r?.map(d => ({ ...d, ...d.formData }))));
    }
  }, [activeReport]);

  const rc = report?.[0];
  const rcName = rc?.[0];
  const rcComplete = isComplete(report);

  const formHasApprover = forms?.some(
    f => (f?.supervisorSignature || f?.hrSignature) && !f.isNa
  );
  const formHasEmployee = forms?.some(f => f?.employeeSignature && !f.isNa);

  const canChangeApprovers =
    (loggedInUser?.accessLevel === 900 ||
      loggedInUser?._id === activeIncident?.incidentOwner) &&
    formHasApprover;

  const canChangeEmployees =
    (loggedInUser?.accessLevel === 900 ||
      loggedInUser?._id === activeIncident?.incidentOwner) &&
    formHasEmployee;

  const availableApprovers = users?.filter(u =>
    hasAccess(u, 'closure', activeIncident)
  );
  const reportTask = activeIncident?.incidentActivity?.openTasks?.filter(
    task => report.length > 0 && task.reportComponentName === rcName
  );
  const deactivatedUsers = users.filter(
    user =>
      user.isDeactivated &&
      extraFormOptions?.usersToComplete?.includes(user._id)
  );

  const handleModalSubmit = () => {
    if (company?.isColumbus) {
      let usersToComplete = extraFormOptions?.usersToComplete;
      if (extraFormOptions?.method === 2)
        usersToComplete = usersToComplete.filter(
          user =>
            !deactivatedUsers
              .map(du => {
                return du._id;
              })
              ?.includes(user)
        );
      const formsToRender = usersToComplete?.map(user => {
        return {
          label:
            formType === 'rec'
              ? 'Recurrence, Relapse During Recovery & Continuing Treatment'
              : 'Transitional Return to Work Program',
          needsToCreate: true,
          incidentId: activeIncident?._id,
          employeeId: user,
          dueBy: extraFormOptions?.formsDueBy,
          assignmentCard: extraFormOptions?.method === 2,
          groupId: activeIncident?.groupId
        };
      });
      setForms([...forms, ...formsToRender]);
      setExtraFormOptions({ usersToComplete: injuredEmployees, method: 1 });
    } else {
      setForms([
        ...forms,
        {
          label:
            formType === 'rec'
              ? 'Recurrence, Relapse During Recovery & Continuing Treatment'
              : 'Transitional Return to Work Program',
          needsToCreate: true,
          incidentId: activeIncident?._id
        }
      ]);
    }
    setOpenModalType('');
    setFormType();
    socketLock();
    setUnsavedChanges(true);
  };

  let modalProps = {
    addForm: {
      title: 'Create New Premade Document',
      buttons: PRE_MADE_DOCUMENTS?.filter(d => d.value !== 'exposure'),
      modalSubmit: handleModalSubmit
    }
  };

  if (company?.isColumbus)
    modalProps = {
      ...modalProps,
      formOptions: {
        renderExtraOptions: formType,
        extraFormOptions: extraFormOptions,
        setExtraFormOptions: value =>
          setExtraFormOptions({ ...extraFormOptions, ...value })
      }
    };

  const getEmployeeName = a => {
    let emp = users.find(u => u._id === a);
    return emp ? `${emp.firstName} ${emp.lastName}` : 'Unknown User';
  };

  const getForms = () => {
    return forms?.map((f, index) => ({
      label: `${f?.employeeId ? `${getEmployeeName(f.employeeId)} - ` : ''} ${
        f.label
      }`,
      complete: isFormComplete(f),
      id: `rtwCard${index}`
    }));
  };

  const isFormComplete = form => {
    if (form.isNa) return true;
    if (
      form.label ===
      'Recurrence, Relapse During Recovery & Continuing Treatment'
    ) {
      return form?.employeeId &&
        form?.dateOfRecurrence &&
        (form?.dateOfOriginalInjury || form?.timeNotDetermined === true) &&
        form?.datePhysicianAppt &&
        form?.incidentDesc &&
        form?.reinjuryOccurred &&
        form?.employeeSignature &&
        form?.supervisorId &&
        form?.supervisorSignature &&
        company?.isColumbus
        ? form?.dayforceId &&
            form?.providerType &&
            (form?.providerType !== 5 || form?.otherProviderType)
        : form?.injuryHistory && form?.dateReported;
    } else {
      return (
        form?.employeeId &&
        form?.workRestrictions &&
        form?.dateOfTransition &&
        form?.employeeSignature &&
        form?.supervisorId &&
        form?.hrRepId &&
        form?.supervisorSignature &&
        form.hrSignature
      );
    }
  };

  const sendEmail = () => {
    emailAssignees({
      messageContent: emailMessage,
      incidentId: activeIncident?._id,
      sentMessageToIds: reportTask?.[0]?.assignedTo
    }).then(() => {
      setOpenModalType(null);
      setEmailMessage('');
    });
  };

  const reopenReportSection = async () => {
    report[0][4].isComplete = false;
    await updateReportComponent({
      stage
    });
  };

  const handleSectionSubmit = async (markAsComplete, dontLeave = false) => {
    setUnsavedChanges(false);
    if (forms?.every(f => isFormComplete(f)) && markAsComplete === undefined) {
      setOpenModalType('markComplete');
      return;
    }
    if (markAsComplete) {
      report[0][4].isComplete = true;
    }

    report[0][1].forms = [...forms];
    await updateReportComponent({
      stage,
      dontLeavePage: dontLeave
    });
    socketDirty();
  };

  const handleDiscard = async () => {
    if (activeReport.returnPath) {
      history.push(activeReport.returnPath);
    } else {
      dispatch(fetchIncidentByIdRequest(activeIncident));
      history.push(`/app/incidentContainer/${stage}`);
    }
  };
  const headerCenter = (
    <ViewOnly
      canUnlock={
        !activeReport?.disabled &&
        rcComplete &&
        (loggedInUser?.accessLevel === 900 ||
          activeIncident.incidentOwner === loggedInUser?._id)
      }
      incident={activeIncident}
      loggedInUser={loggedInUser}
      onClick={() => setOpenModalType('unlockReport')}
      activeReport={activeReport}
    />
  );

  const pageActions = activeReport?.disabled
    ? []
    : [
        {
          visible: !rcComplete && canChangeApprovers,
          label: 'Change Approver(s)',
          text: 'Change Approver(s)',
          color: 'blue',
          onClick: () => setChangeApprovers(true)
        },
        {
          visible: !rcComplete && canChangeEmployees && !company.isColumbus,
          label: 'Change Employee(s)',
          text: 'Change Employee(s)',
          color: 'blue',
          onClick: () => setChangeEmployees(true)
        }
      ];

  const right =
    rc?.[2]?.assignedTo !== 'unassigned' ? (
      <Assignee
        user={rc?.[2]?.assignedTo}
        options={[
          {
            label: 'Email Assignee',
            onClick: () => setOpenModalType('emailAssignee'),
            visible: true
          },
          {
            label: 'Edit Task',
            visible: activeIncident?.incidentOwner === loggedInUser._id,
            onClick: () =>
              setTask({
                ...reportTask[0],
                stage
              })
          }
        ].concat(pageActions)}
      />
    ) : null;

  const header = (
    <Header
      title={activeIncident && activeIncident.incidentNumber}
      section={rcName}
      needsSaved={unsavedChanges}
      clickBack={() =>
        unsavedChanges ? setOpenModalType('unsavedChanges') : handleDiscard()
      }
      center={headerCenter}
      right={right}
      rightButtons={rc?.[2]?.assignedTo === 'unassigned' ? pageActions : []}
    />
  );

  const footer = (
    <SaveCancelFooter
      saveButtonClick={() => handleSectionSubmit(undefined)}
      cancelButtonClick={() =>
        unsavedChanges ? setOpenModalType('unsavedChanges') : handleDiscard()
      }
      editing={true}
      onMouseEnter={() =>
        setMissingRequired(forms?.some(f => !isFormComplete(f)))
      }
    />
  );

  const left = (
    <LeftNav
      items={getForms()}
      showAddRtwForm={
        !rcComplete && !locked && !isSigning && !activeReport?.disabled
      }
      onAddRtwForm={() => {
        setOpenModalType('addForm');
      }}
    />
  );

  const renderRecurrenceContainer = (doc, index) => {
    return (
      <RecurrenceInfoCard
        {...doc}
        onChange={values => {
          setUnsavedChanges(true);
          socketLock();
          let form = { ...forms[index], ...values, needsToUpdate: doc._id };
          let updatedForms = [...forms];
          updatedForms.splice(index, 1, form);
          setForms(updatedForms);
          if (missingRequired && form.isNa !== forms[index].isNa) {
            setMissingRequired(updatedForms?.some(f => !isFormComplete(f)));
          }
        }}
        availableApprovers={availableApprovers}
        supervisorCanSign={
          loggedInUser?._id === doc.supervisorId &&
          !doc.supervisorSignature &&
          !(locked && doc.isNa && rcComplete)
        }
        name={`rtwCard${index}`}
        disabled={
          locked ||
          doc.isNa ||
          rcComplete ||
          isSigning ||
          activeReport?.disabled ||
          doc.employeeFieldsComplete
        }
        disableNa={locked || rcComplete || isSigning || activeReport?.disabled}
        signatureDisabled={locked || rcComplete}
        missingRequired={missingRequired}
        changeApprovers={changeApprovers}
        changeEmployees={changeEmployees}
        handleRemoveForm={() => setForms(forms?.toSpliced(index, 1))}
        group={activeIncident?.groupId}
        isColumbus={company?.isColumbus}
        employeeId={doc?.employeeId}
      />
    );
  };

  const renderTransitionContainer = (doc, index) => {
    return (
      <TransitionalInfoCard
        {...doc}
        onChange={values => {
          setUnsavedChanges(true);
          socketLock();
          let form = { ...forms[index], ...values, needsToUpdate: doc._id };
          let updatedForms = [...forms];
          updatedForms.splice(index, 1, form);
          setForms(updatedForms);
          if (missingRequired && form.isNa !== forms[index].isNa) {
            setMissingRequired(updatedForms?.some(f => !isFormComplete(f)));
          }
        }}
        availableApprovers={availableApprovers}
        supervisorCanSign={
          loggedInUser?._id === doc.supervisorId &&
          !doc.supervisorSignature &&
          !(locked && doc.isNa && rcComplete)
        }
        name={`rtwCard${index}`}
        disabled={
          locked ||
          doc.isNa ||
          rcComplete ||
          isSigning ||
          activeReport?.disabled ||
          doc.employeeFieldsComplete
        }
        disableNa={locked || rcComplete || isSigning || activeReport?.disabled}
        hrCanSign={
          loggedInUser?._id === doc.hrRepId &&
          !doc.hrSignature &&
          !(locked && doc.isNa && rcComplete)
        }
        missingRequired={missingRequired}
        changeApprovers={changeApprovers}
        changeEmployees={changeEmployees}
        handleRemoveForm={() => setForms(forms?.toSpliced(index, 1))}
        group={activeIncident?.groupId}
        isColumbus={company?.isColumbus}
      />
    );
  };

  return (
    <>
      {incidentLoading && (
        <ReactLoading
          type="spinningBubbles"
          color="#2f8738"
          className="loading"
        />
      )}
      <HeaderAndFooter
        Header={header}
        Footer={footer}
        Left={left}
        lockInfo={lockInfo}
        showFooter={!rcComplete && !activeReport?.disabled}
      >
        {forms?.map((f, i) =>
          f.assignmentCard || (f.dueBy && !f.employeeFieldsComplete) ? (
            <ContinuousCareCard
              document={f}
              index={i}
              assignee={users.find(u => u._id === f.employeeId)}
              handleNa={v => {
                setUnsavedChanges(true);
                socketLock();
                const form = {
                  ...forms[i],
                  isNa: !forms[i].isNa,
                  needsToUpdate: f._id
                };
                const updatedForms = [...forms];
                updatedForms.splice(i, 1, form);
                setForms(updatedForms);
              }}
              onClick={() => {
                setShowDateChangeModel(!f.isNa);
                setFormIndex(i);
                setModalDueDate(f.dueBy);
              }}
              disabled={isSigning}
            />
          ) : f.label.includes(
              'Recurrence, Relapse During Recovery & Continuing Treatment'
            ) ? (
            renderRecurrenceContainer(f, i)
          ) : (
            renderTransitionContainer(f, i)
          )
        )}
      </HeaderAndFooter>
      <Modal
        title="Email Assignee"
        titleClassName="blueHeader"
        isOpen={openModalType === 'emailAssignee'}
        submitButtonColor="blue"
        submitButtonText="Send Email"
        onRequestClose={() => {
          setOpenModalType(null);
          setEmailMessage('');
        }}
        submitActions={sendEmail}
      >
        <div className="reportSectionContainer-emailAssigneeText">
          This email will go through the iReportSource system for record keeping
          purposes.
        </div>
        <Textbox
          fieldLabel="Email Content"
          type="textarea"
          placeholder="Type email content here"
          currentValue={emailMessage}
          onChange={setEmailMessage}
        />
      </Modal>
      <SaveChangesModal
        isOpen={openModalType === 'unsavedChanges'}
        onRequestClose={() => setOpenModalType(null)}
        submitActions={handleDiscard}
        savingWhat="a report"
      />
      <MarkAsCompleteModal
        isOpen={openModalType === 'markComplete'}
        onRequestClose={() => setOpenModalType(null)}
        submitActions={() => handleSectionSubmit(true)}
        cancelActions={() => handleSectionSubmit(false)}
      />
      <Modal
        title="Unlock and Re-Open Report"
        titleClassName="blueHeader"
        isOpen={openModalType === 'unlockReport'}
        submitButtonColor="blue"
        submitButtonText="Re-open Report"
        onRequestClose={() => setOpenModalType(null)}
        submitActions={() => reopenReportSection()}
      >
        <div className="reportSectionContainer-emailAssigneeText">
          Unlocking this report will allow you –and users with edit permission–
          to make changes and additions to the report.{' '}
          <span style={{ color: '#c74846', fontWeight: 'bold' }}>
            This also means that the report will no longer be considered
            completed until it is filled out, saved, and marked as complete once
            more. Re-Opening this report will also remove the currently assigned
            user.
          </span>{' '}
          You can reassign this report to the same or a different user as a new
          task.
        </div>
      </Modal>
      {openModalType === 'addForm' ? (
        <DashboardButtons
          modalOpen={openModalType}
          modalProps={modalProps}
          handleCloseModal={() => {
            setFormType();
            setOpenModalType();
            if (company?.isColumbus)
              setExtraFormOptions({
                usersToComplete: injuredEmployees,
                method: 1
              });
          }}
          modalSelection={formType}
          setModalSelection={setFormType}
          company={company}
          deactivatedUsers={deactivatedUsers}
          group={activeIncident?.groupId}
        />
      ) : null}
      <Modal
        title="Assign Due Date"
        titleClassName="blueHeader"
        isOpen={showDateChangeModal}
        submitButtonColor="blue"
        onRequestClose={() => {
          setShowDateChangeModel(false);
          setFormIndex();
          setModalDueDate();
        }}
        tall
        submitActions={() => {
          if (isFutureDate(modalDueDate)) return null;
          setUnsavedChanges(true);
          socketLock();
          const form = {
            ...forms[formIndex],
            dueBy: modalDueDate,
            needsToUpdate: forms[formIndex]?._id
          };
          const updatedForms = [...forms];
          updatedForms.splice(formIndex, 1, form);
          setForms(updatedForms);
          setFormIndex();
          setShowDateChangeModel(false);
          setModalDueDate();
        }}
        submitTooltip={
          !modalDueDate || isFutureDate(modalDueDate)
            ? 'Missing or Invalid Date/Time'
            : null
        }
      >
        <DatePicker
          pickTime
          fieldLabel="Due Date"
          isRequired
          onChange={setModalDueDate}
          currentValue={modalDueDate}
          name="ccDueDate"
          minDate={moment()}
          touched={!modalDueDate}
          errorMessage="Must be a future date/time"
          showWarning={isFutureDate(modalDueDate)}
        />
      </Modal>
    </>
  );
}
