import React from 'react';
import { Dropdown, TwoColumn } from '../inputs';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';

export const HierarchySelector = ({
  onGroupChange,
  groupId,
  groupDisabled,
  onProjectChange,
  projectId,
  projectDisabled,
  alwaysShowGroupDropdown = false,
  alwaysShowProjectDropdown = false,
  groupRequired = true,
  alphabetize = false,
  multi = false,
  filteredGroupDropdown,
  filteredProjectDropdown,
  showFilteredMessage,
  touched,
  noTwoColumn,
  groupTooltip,
  displayGroupTooltip,
  projectTooltip,
  displayProjectTooltip
}) => {
  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();

  const groupDropdown =
    filteredGroupDropdown ??
    activeCompany.groups
      ?.filter(g => !g.isHidden)
      ?.map(group => {
        return {
          value: group._id,
          label: group.name
        };
      });

  let projectDropdown = [];

  if (multi) {
    let groups = [];
    if (activeGroup) {
      groups = [activeGroup._id];
    } else {
      groups = groupId ?? [];
    }

    groups.forEach(g => {
      let group = activeCompany?.groups?.find(l => l._id === g);
      if (!group) return;

      group.projects.forEach(project => {
        if (!project.isHidden)
          projectDropdown.push({
            value: project._id,
            label: `${group.name} - ${project.name}`
          });
      });
    });
  } else {
    const selectedGroup = activeCompany.groups.filter(
      group => group._id === activeGroup._id || group._id === groupId
    );

    if (selectedGroup.length > 0) {
      projectDropdown = selectedGroup[0].projects
        ?.filter(p => !p.isHidden)
        ?.map(project => {
          return {
            value: project._id,
            label: project.name
          };
        });
      if (projectDropdown.length)
        projectDropdown.unshift({ value: '', label: '      ' });
    }
  }

  const ddInfo = (
    <>
      {(!activeGroup || alwaysShowGroupDropdown) && (
        <Dropdown
          searchable
          options={groupDropdown}
          fieldLabel="Group or Establishment:"
          currentValue={groupId}
          onChange={onGroupChange}
          isRequired={groupRequired}
          disabled={groupDisabled}
          alphabetize={alphabetize}
          multi={multi}
          selectButtons={multi}
          touched={touched}
          tooltip={groupTooltip}
          displayTooltip={displayGroupTooltip}
        />
      )}
      {(!activeProject || alwaysShowProjectDropdown) && (
        <Dropdown
          searchable
          options={filteredProjectDropdown ?? projectDropdown}
          fieldLabel="Area:"
          currentValue={projectId?.length ? projectId : null}
          onChange={onProjectChange}
          disabled={
            projectDisabled ||
            !projectDropdown.length ||
            (filteredProjectDropdown && !filteredProjectDropdown?.length)
          }
          placeholder={
            activeGroup
              ? ''
              : !groupId?.length
              ? 'Select a Group/Establishment'
              : !filteredProjectDropdown?.length && showFilteredMessage
              ? 'No Areas available for selection'
              : !projectDropdown.length
              ? 'No Areas in Group/Establishment'
              : ''
          }
          alphabetize={alphabetize}
          multi={multi}
          selectButtons={multi}
          tooltip={projectTooltip}
          displayTooltip={displayProjectTooltip}
        />
      )}
    </>
  );

  return noTwoColumn ? ddInfo : <TwoColumn>{ddInfo}</TwoColumn>;
};

export default HierarchySelector;
