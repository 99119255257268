import config from '../config/config';

const featureToggles = config.featureToggles;
export const leftNavigation = {
  sections: [
    {
      title: 'General',
      section: [
        {
          text: 'Home',
          image: 'Dashboard',
          link: '/app/dashboard',
          accessLevel: [100, 400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Tasks',
          image: 'Tasks',
          link: '/app/tasks/task',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Corrective Actions',
          image: 'Tasks',
          link: '/app/tasks/correctiveAction',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'OSHA Forms',
          image: 'oshaLogo',
          link: '/app/oshaLog',
          accessLevel: [500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Claims',
          image: 'Claims',
          link: '/app/claims',
          accessLevel: [500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Employees',
          image: 'Employees',
          link: '/app/settings/Employees',
          accessLevel: [500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Equipment',
          image: 'Equipment',
          link: '/app/equipmentList',
          accessLevel: [900, 500],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Equipment Exception Reports',
          image: 'Exception',
          navItem: 'Equipment Exception',
          link: '/app/auditException/equipment',
          accessLevel: [500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Announcements',
          image: 'Announcement',
          link: '/app/announcementList',
          accessLevel: [100, 400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Covid-19 Tracking',
          //image: 'BarChart',
          link: '/app/covidTracking',
          accessLevel: [900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: false
        }
      ],
      accessLevel: [100, 400, 500, 900]
    },
    {
      title: 'Incidents',
      section: [
        {
          text: 'All Incidents',
          image: 'Incidents',
          link: '/app/incidents',
          accessLevel: [100, 400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Employee Injury',
          image: 'first-report-of-injury',
          link: '/app/reportStages/2',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Vehicle Incident',
          image: 'auto-accident',
          link: '/app/reportStages/1',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Property Damage',
          image: 'property-damage',
          link: '/app/reportStages/3',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Near Miss',
          image: 'near-miss-report',
          link: '/app/reportStages/7',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Theft',
          image: 'theft',
          link: '/app/reportStages/4',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Third Party Injury',
          image: 'general-liability',
          link: '/app/reportStages/5',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Aircraft',
          image: '6',
          link: '/app/reportStages/6',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true,
          isAircraftItem: true
        },
        {
          text: 'Spill/Misfuel',
          navItem: 'Spill/Misfuel',
          image: '16',
          link: '/app/reportStages/16',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true,
          isAircraftItem: true
        },

        {
          text: 'Violence / Harassment',
          navItem: 'Violence/Harassment',
          image: '17',
          link: '/app/reportStages/17',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Spill',
          navItem: 'Spill',
          image: '18',
          link: '/app/reportStages/18',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'General Incident',
          navItem: 'General Incident',
          image: '19',
          link: '/app/reportStages/19',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Custom Incident Questions',
          image: 'CustomQuestions',
          link: '/app/customReportQuestionsList',
          accessLevel: [900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Witness Statements',
          image: 'witnessStatements',
          link: '/app/witnessStatementList',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        }
      ],
      accessLevel: [100, 400, 500, 900]
    },
    {
      title: 'Insights',
      section: [
        {
          text: 'My Analytics',
          image: 'Analytics',
          link: '/app/myAnalytics',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Leaderboards',
          image: 'Leaderboard',
          link: '/app/leaderboards',
          accessLevel: [500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        }
      ],
      accessLevel: [400, 500, 900]
    },
    {
      title: 'Audits',
      section: [
        {
          text: 'Safety Audits',
          image: 'jsa',
          link: '/app/audits/safety',
          accessLevel: [100, 400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Safety Audit Templates',
          navItem: 'Safety Audit Templates',
          image: 'CustomTemplate',
          link: '/app/auditTemplates/safety',
          accessLevel: [900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Safety Audit Exception Reports',
          image: 'Exception',
          navItem: 'Safety Exception',
          link: '/app/auditException/safety',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Quality Audits',
          navItem: 'Quality Audits',
          image: 'qualityAudit',
          link: '/app/audits/quality',
          accessLevel: [100, 400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Quality Audit Templates',
          navItem: 'Quality Audit Templates',
          image: 'CustomTemplate',
          link: '/app/auditTemplates/quality',
          accessLevel: [900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Quality Audit Exception Reports',
          image: 'Exception',
          navItem: 'Quality Exception',
          link: '/app/auditException/quality',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Lockout/Tagout Procedures',
          navItem: 'Lockout/Tagout',
          image: 'lockout-tagout',
          link: '/app/audits/loto',
          accessLevel: [100, 400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Lockout/Tagout Templates',
          navItem: 'Lockout/Tagout Templates',
          image: 'CustomTemplate',
          link: '/app/auditTemplates/loto',
          accessLevel: [900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        }
      ],
      accessLevel: [100, 400, 500, 900]
    },
    {
      title: 'Observations',
      section: [
        {
          text: 'Behavior Observations',
          image: 'bo',
          link: '/app/behaviorObservations',
          accessLevel: [100, 400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Behavior Observation Templates',
          image: 'CustomTemplate',
          link: '/app/behaviorObservation/templates',
          accessLevel: [900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Safety Walks',
          image: 'site-inspection',
          link: '/app/safetyWalkStages',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Safety Suggestions',
          image: 'safety-suggestion',
          link: '/app/safetySuggestions/list',
          accessLevel: [100, 400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        }
      ],
      accessLevel: [100, 400, 500, 900]
    },
    {
      title: 'Training',
      section: [
        {
          text: 'Training',
          image: 'safetyTraining',
          link: '/app/training/list',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: true,
          folderType: 'SafetyTraining',
          showFeature: true
        },
        {
          text: 'Training Templates',
          image: 'CustomTemplate',
          link: '/app/training/templates',
          accessLevel: [900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Training Courses',
          image: 'courses',
          link: '/app/trainingCourses',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          folderType: 'TrainingCourses',
          hasFolders: true,
          showFeature: true
        },
        {
          text: 'Training Course Templates',
          image: 'CustomTemplate',
          link: '/app/courseTemplates',
          accessLevel: [900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Training Report',
          image: 'Exception',
          link: '/app/trainingException',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Training Quiz Templates',
          image: 'quiz',
          link: '/app/quiz/quizList',
          accessLevel: [900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        }
      ],
      accessLevel: [400, 500, 900]
    },
    {
      title: 'Document Repository',
      section: [
        {
          text: 'My Documents',
          image: 'Tasks',
          link: '/app/myDocumentsStages',
          accessLevel: [100, 400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Custom Documents',
          image: 'CustomDocument',
          link: '/app/customDocumentList',
          accessLevel: [100, 400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: featureToggles.customDocuments
        },
        {
          text: 'Custom Document Templates',
          image: 'CustomTemplate',
          link: '/app/customDocumentTemplateList',
          accessLevel: [900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: featureToggles.customDocuments
        },
        {
          text: 'Safety Procedures',
          image: 'safety-instruction',
          link: '/app/documents/safetyInstructions',
          accessLevel: [100, 400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: true,
          folderType: 'SafetyInstruction',
          showFeature: true
        },
        {
          text: 'SDS',
          image: 'sds',
          link: '/app/documents/sds',
          accessLevel: [100, 400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: true,
          folderType: 'SDS',
          showFeature: true
        },
        {
          text: 'Qualifications',
          image: 'qualifications',
          link: '/app/documents/qualifications',
          accessLevel: [100, 400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: true,
          folderType: 'Qualification',
          showFeature: true
        },
        {
          text: 'OSHA Information',
          image: 'oshaLogo',
          link: '/app/documents/oshaInfoList',
          accessLevel: [100, 400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: true,
          folderType: 'oshaInfoDoc',
          disableAddFolders: true,
          showFeature: false
        },
        {
          text: 'Safety Meetings',
          image: 'jsa',
          link: '/app/safetyMeeting/list',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Safety Meeting Templates',
          image: 'CustomTemplate',
          link: '/app/safetyMeeting/templates',
          accessLevel: [900],
          appLevel: ['Division', 'Group', 'Project'],
          showFeature: true
        }
      ],
      accessLevel: [100, 400, 500, 900]
    },
    {
      title: 'Settings',
      section: [
        {
          text: 'Areas',
          image: 'Areas',
          link: '/app/settings/Projects',
          accessLevel: [900, 500],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Group / Establishment',
          image: 'Locations',
          link: '/app/settings/Locations',
          accessLevel: [500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Company Division',
          image: 'company',
          link: '/app/settings/CompanyDetails',
          accessLevel: [900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        }
      ],
      accessLevel: [500, 900]
    }
  ]
};
