import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router';
import classnames from 'classnames/bind';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getTrainingCourseById } from '../../../api/v4';
import history from '../../../history';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import BigHeader from '../../../components/BigHeader';
import svgMapper from '../../../utils/svgMapper';
import { Button } from '../../../components/inputs';
import { getLoggedInUser } from '../../../selectors/users';

import styles from './styles.module.scss';

export default function TrainingCourse() {
  const { id } = useParams();
  const location = useLocation();
  const fromScreen = location.state?.fromScreen;

  const user = useSelector(getLoggedInUser);

  const [course, setCourse] = useState({});
  const [trainings, setTrainings] = useState([]);

  useEffect(() => {
    if (id)
      getTrainingCourseById(id, user._id).then(r => {
        setCourse(r);
        setTrainings(
          r.trainings
            .map(t => ({
              ...t,
              selectedChild: t.children?.find(
                c => c._id && c.assignedTo === user._id
              )
            }))
            .filter(t => t.selectedChild && t.selectedChild?.isVisibleInCourse)
        );
      });
  }, [id, user]);

  const handleBack = () => {
    history.push(fromScreen ?? '/app/dashboard/');
  };
  const totalCompleted =
    trainings?.reduce((acc, curr) => {
      if (
        curr?.selectedChild?.completionDate ??
        curr?.selectedChild?.isCompleted
      ) {
        return acc + 1;
      }
      return acc;
    }, 0) ?? 0;

  const lastCompletedDate =
    trainings?.reduce((acc, curr) => {
      if (
        curr?.selectedChild?.completionDate &&
        moment(curr?.selectedChild?.completionDate).isAfter(acc)
      ) {
        return curr?.selectedChild?.completionDate;
      }
      return acc;
    }, moment(course.createdAt)) ?? 0;

  const header = (
    <BigHeader
      title="Training Course"
      center
      isComplete={course?.trainingTemplates?.length <= totalCompleted}
      date={
        course?.trainingTemplates?.length <= totalCompleted
          ? lastCompletedDate
          : course.dueDate
      }
      courseData={{
        ...course,
        pieData: [
          {
            label: 'Completed',
            number: totalCompleted,
            color: 'green'
          },
          {
            label: 'Total',
            number: course.trainingTemplates?.length - totalCompleted ?? 1,
            color: 'lightgrey'
          }
        ]
      }}
      clickBack={handleBack}
    />
  );

  return (
    <>
      <HeaderAndFooter
        Header={header}
        bigHeader
        showFooter
        className={styles.trainingTemplate}
      >
        <div className={styles.buttonContainer}>
          {trainings?.map(x => {
            const status =
              x.selectedChild?.isCompleted ?? x.selectedChild?.completionDate
                ? 'Completed'
                : x.selectedChild?.auditLog.length > 1
                ? 'In Progress'
                : null;
            return (
              <>
                <div data-for={x.text} data-tip="customTheme">
                  <Button
                    id={x.text}
                    className={styles.button}
                    image={svgMapper(
                      status === 'Completed'
                        ? 'checkmark'
                        : status === 'In Progress'
                        ? 'inProgress'
                        : 'safetyTraining'
                    )}
                    imageClass={classnames(
                      styles.imageStyles,
                      status === 'Completed'
                        ? styles.completed
                        : status === 'In Progress'
                        ? styles.inProgress
                        : styles.notStarted
                    )}
                    inputClassName={styles.button}
                    text={
                      <div>
                        <p className={styles.buttonText}>{x.title}</p>
                        <p className={styles.buttonSubtext}>{status}</p>
                      </div>
                    }
                    onClick={() => {
                      history.push(`/app/training/perform/${x._id}`);
                    }}
                    testID={x.title}
                  />
                </div>
                <ReactTooltip
                  className={classnames(styles.tooltip, 'customTheme')}
                  id={x.title}
                >
                  {x.title}
                </ReactTooltip>
              </>
            );
          })}
        </div>
      </HeaderAndFooter>
    </>
  );
}
