import React, { Component } from 'react';

import { REPORT_ACTION_ITEMS } from '../../constants/constants';
import { canEditTask } from '../../utils/incidentHelper';
import ActivityCard from '../reportCards/ActivityCard';
import AddButton from '../inputs/AddButton';
import AttachmentCard from '../reportCards/AttachmentCard';
import CommentCard from '../reportCards/CommentCard';
import Dropdown from '../inputs/Dropdown';
import Modal from '../Modal';
import PrivateLogCard from '../reportCards/PrivateLogCard';
import TaskCard from '../reportCards/TaskCard';
import Textbox from '../inputs/Textbox';
import UserBadge from '../UserBadge';

import './index.css';

export default class ReportActions extends Component {
  state = {
    action: 0,
    openViewCommentModal: false,
    openViewLogModal: false,
    openCreateLogModal: false,
    openCreateCommentModal: false,
    activeComment: {},
    activeLogEntry: {},
    createLogEntry: '',
    createCommentEntry: ''
  };

  addAttachment = async e => {
    const files = e.target.files;
    if (!files.length) return;

    let data = new FormData();

    Object.entries(files).forEach(async file => {
      data.append('attachments', file[1], file[1].name);
    });

    await this.props.addAttachment({
      data,
      ownerId: this.props.activeIncident._id,
      ownerType: 'incident'
    });
  };

  render() {
    const {
      action,
      openViewCommentModal,
      openViewLogModal,
      openCreateLogModal,
      openCreateCommentModal,
      activeComment,
      activeLogEntry,
      createLogEntry,
      createCommentEntry
    } = this.state;

    const {
      comments,
      notes,
      activityItems,
      attachments,
      tasks,
      loggedInUser,
      addNote,
      addComment,
      activeIncident,
      removeActionItem,
      handleReportSelection,
      setActiveTask,
      loadTask,
      disabled
    } = this.props;

    const getCompletedTasks = tasks => {
      return tasks.filter(task => task.isCompleted);
    };

    const getOtherTasks = tasks => {
      return tasks.filter(task => !task.isCompleted);
    };

    const getPrivateNotes = notes => {
      return notes.filter(note => {
        const user = note.auditLog[0].user ?? note.auditLog[0].userId;
        return user === loggedInUser._id && !note.isRemoved;
      });
    };

    const getRemovedAttachments = attachments => {
      return attachments.filter(attachment => attachment.isRemoved);
    };

    const getOtherAttachments = attachments => {
      return attachments.filter(attachment => !attachment.isRemoved);
    };

    const getRemovedComments = comments => {
      return comments.filter(comment => comment.isRemoved);
    };

    const getOtherComments = comments => {
      return comments.filter(comment => !comment.isRemoved);
    };

    return (
      <div className="reportActionsContainer">
        <Dropdown
          options={REPORT_ACTION_ITEMS}
          currentValue={action}
          onChange={value => this.setState({ action: value })}
          inputClassName="reportActions-ddInput"
        />
        <div className="reportActions">
          {action.toString() === '0' ? (
            <div>
              {activityItems.map((activity, index) => {
                if (activity.type === 'action') {
                  return (
                    <ActivityCard
                      activity={activity.resourceData}
                      key={index}
                    />
                  );
                } else if (activity.type === 'task') {
                  return (
                    <TaskCard
                      task={activity.resourceData}
                      key={index}
                      handleReportSelection={handleReportSelection}
                      canEditTask={
                        !disabled &&
                        canEditTask(
                          activeIncident,
                          loggedInUser,
                          activity.resourceData
                        )
                      }
                      loadTask={() => {
                        loadTask(activity?.resourceData?._id);
                      }}
                    />
                  );
                } else if (
                  activity.type === 'attachment' &&
                  (loggedInUser?.accessLevel === 900 ||
                    activeIncident.incidentOwner === loggedInUser._id ||
                    !activity?.resourceData?.isRemoved)
                ) {
                  return (
                    <AttachmentCard
                      attachment={activity.resourceData}
                      key={index}
                      removeAttachment={() =>
                        removeActionItem({
                          type: 'attachment',
                          ...activity.resourceData
                        })
                      }
                      canRemove={!disabled}
                    />
                  );
                } else {
                  return <></>;
                }
              })}
            </div>
          ) : action === 1 ? (
            <div>
              {getOtherAttachments(attachments).map((attachment, index) => (
                <AttachmentCard
                  attachment={attachment}
                  key={index}
                  removeAttachment={() =>
                    removeActionItem({ type: 'attachment', ...attachment })
                  }
                  canRemove={!disabled}
                />
              ))}
              {activeIncident.incidentOwner === loggedInUser._id &&
                getRemovedAttachments(attachments).length > 0 && (
                  <div>
                    <div className="reportActions-removedActionItems">
                      Removed
                    </div>
                    {getRemovedAttachments(attachments).map(
                      (attachment, index) => (
                        <AttachmentCard
                          attachment={attachment}
                          key={index}
                          removeAttachment={() =>
                            removeActionItem({
                              type: 'attachment',
                              ...attachment
                            })
                          }
                        />
                      )
                    )}
                  </div>
                )}
            </div>
          ) : action === 2 ? (
            <div>
              {getOtherComments(comments).map((comment, index) => (
                <CommentCard
                  comment={comment}
                  key={index}
                  setActiveComment={value =>
                    this.setState({
                      openViewCommentModal: true,
                      activeComment: value
                    })
                  }
                  removeComment={() =>
                    removeActionItem({
                      type: 'comment',
                      ...comment
                    })
                  }
                />
              ))}
              {getRemovedComments(comments).length > 0 && (
                <div>
                  <div className="reportActions-removedActionItems">
                    Removed
                  </div>
                  {getRemovedComments(comments).map((comment, index) => (
                    <CommentCard
                      comment={comment}
                      key={index}
                      setActiveComment={value =>
                        this.setState({
                          openViewCommentModal: true,
                          activeComment: value
                        })
                      }
                      removeComment={() =>
                        removeActionItem({
                          type: 'comment',
                          ...comment
                        })
                      }
                    />
                  ))}
                </div>
              )}
            </div>
          ) : action === 3 ? (
            <div>
              {getOtherTasks(tasks).map((task, index) => (
                <TaskCard
                  task={task}
                  key={index}
                  handleReportSelection={handleReportSelection}
                  canEditTask={canEditTask(activeIncident, loggedInUser, task)}
                  loadTask={() => {
                    loadTask(task?._id);
                  }}
                />
              ))}
              {getCompletedTasks(tasks).length > 0 && (
                <div>
                  <div className="reportActions-completedTasks">Completed</div>
                  {getCompletedTasks(tasks).map((task, index) => (
                    <TaskCard
                      task={task}
                      key={index}
                      handleReportSelection={handleReportSelection}
                      canEditTask={canEditTask(
                        activeIncident,
                        loggedInUser,
                        task
                      )}
                      loadTask={() => {
                        loadTask(task?._id);
                      }}
                    />
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div>
              {getPrivateNotes(notes).map((log, index) => (
                <PrivateLogCard
                  log={log}
                  key={index}
                  setActivePrivacyLog={value =>
                    this.setState({
                      openViewLogModal: true,
                      activeLogEntry: value
                    })
                  }
                  removeLogEntry={() =>
                    removeActionItem({
                      type: 'log',
                      ...log
                    })
                  }
                />
              ))}
            </div>
          )}
        </div>
        {disabled ? null : (
          <div className="reportActions-actionButtons">
            {action === 1 ? (
              <div className="reportActions-actionButton">
                <AddButton
                  text="Add Attachments"
                  onClick={() => document.getElementById(`myDoc`).click()}
                  circleSide="left"
                />
                <input
                  type="file"
                  id="myDoc"
                  onChange={e => {
                    this.addAttachment(e);
                    e.target.value = '';
                  }}
                  multiple
                />
              </div>
            ) : action === 2 ? (
              <div className="reportActions-actionButton">
                <AddButton
                  text="Create Comment"
                  circleSide="left"
                  onClick={() =>
                    this.setState({ openCreateCommentModal: true })
                  }
                />
              </div>
            ) : action === 3 ? (
              <div className="reportActions-actionButton">
                <AddButton
                  text="Add Custom Task"
                  circleSide="left"
                  onClick={() =>
                    setActiveTask({
                      ownerId: activeIncident._id,
                      ownerType: 'incident',
                      groupId: activeIncident.locationId,
                      schedule: 'immediate',
                      stage: 0,
                      type: 'task'
                    })
                  }
                />
              </div>
            ) : action === 4 ? (
              <div className="reportActions-actionButton">
                <AddButton
                  circleSide="left"
                  text="Add Log Entry"
                  onClick={() => this.setState({ openCreateLogModal: true })}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        )}
        <Modal
          title="Comment Expanded"
          titleClassName="blueHeader"
          isOpen={openViewCommentModal}
          onRequestClose={() => this.setState({ openViewCommentModal: false })}
          hideButtons={true}
        >
          <div className="reportActions-assignee">
            <UserBadge
              userId={activeComment?.userId}
              showImage
              showName
              byline="Commented By"
            />
            <div className="reportActions-assignee--createdAt">
              <div className="reportActions-normal">
                {`Added at ${activeComment?.date?.format?.('hh:mm A')}`}.
              </div>
              <div className="reportActions-bold">
                {activeComment?.date?.format?.('LL')}
              </div>
            </div>
          </div>
          <div className="reportActions-viewCommentNote">
            {activeComment?.value}
          </div>
        </Modal>
        <Modal
          title="Log Entry Expanded"
          titleClassName="blueHeader"
          isOpen={openViewLogModal}
          onRequestClose={() => this.setState({ openViewLogModal: false })}
          hideButtons={true}
        >
          <div className="reportActions-assignee">
            <div className="reportActions-assignee--createdAt">
              <div className="reportLog reportActions-normal">
                {`Added at ${activeLogEntry?.date?.format?.('hh:mm A')}`}.
              </div>
              <div className="reportLog reportActions-bold">
                {activeLogEntry?.date?.format?.('LL')}
              </div>
            </div>
          </div>
          <div className="reportActions-viewCommentNote">
            {activeLogEntry?.value}
          </div>
        </Modal>
        <Modal
          title="Add Log Entry"
          titleClassName="blueHeader"
          isOpen={openCreateLogModal}
          submitButtonColor="blue"
          submitButtonText="Save Log Entry"
          onRequestClose={() =>
            this.setState({ openCreateLogModal: false, createLogEntry: '' })
          }
          submitActions={() => {
            addNote(createLogEntry);
            this.setState({ openCreateLogModal: false, createLogEntry: '' });
          }}
          disableSubmit={!createLogEntry?.trim()?.length}
        >
          <div className="reportActions-createVerbage">
            <span className="reportActions-importantVerbage">
              Private log entries are not visible to other users, and are just
              for you to keep private notes on an incident.
            </span>{' '}
            If you want everyone else on the incident to see, try using the
            comments instead.
          </div>
          <Textbox
            fieldLabel="Log Entry"
            type="textarea"
            placeholder="Type your log entry here."
            currentValue={createLogEntry}
            onChange={v => this.setState({ createLogEntry: v })}
          />
        </Modal>
        <Modal
          title="Create Comment"
          titleClassName="blueHeader"
          isOpen={openCreateCommentModal}
          submitButtonColor="blue"
          submitButtonText="Save Comment"
          onRequestClose={() =>
            this.setState({
              openCreateCommentModal: false,
              createCommentEntry: ''
            })
          }
          submitActions={() => {
            addComment(createCommentEntry);
            this.setState({
              openCreateCommentModal: false,
              createCommentEntry: ''
            });
          }}
          disableSubmit={!createCommentEntry?.trim()?.length}
        >
          <div className="reportActions-createVerbage">
            <span className="reportActions-importantVerbage">
              Comments may be viewed by anyone who has access to the incident.
            </span>{' '}
            If you don't want other users to see it, try using the private log
            instead.
          </div>
          <Textbox
            fieldLabel="Comment Content"
            type="textarea"
            placeholder="Type your comment here."
            currentValue={createCommentEntry}
            onChange={v => this.setState({ createCommentEntry: v })}
          />
        </Modal>
      </div>
    );
  }
}
