import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';

import {
  ANALYTICS_QUARTERLY_FREQUENCY_OPTIONS,
  DAILY_OPTIONS,
  DAY_OF_WEEK_DROPDOWN,
  FREQUENCY_OPTIONS,
  MONTHLY_FREQUENCY_OPTIONS,
  YEARLY_FREQUENCY_OPTIONS
} from '../../constants/constants';
import { getActiveCompany } from '../../selectors/company';

import {
  Dropdown,
  EmployeeDropdown,
  Input,
  TwoColumn
} from '../../components/inputs';
import Modal from '.';
import UserBadge from '../../components/UserBadge';

import styles from './styles.module.scss';

export default function ReportModal({
  isOpen,
  onRequestClose,
  submitActions,
  topMessage,
  exceptionType,
  reports,
  titles,
  bareValuesTemplateDropdown = true
}) {
  const company = useSelector(getActiveCompany);

  const [editing, setEditing] = useState([]);
  const [missingRequired, setMissingRequired] = useState(false);

  useEffect(() => {
    setEditing(cloneDeep(reports));
  }, [reports]);

  const getOptions = frequency => {
    switch (frequency) {
      case 'daily':
        return DAILY_OPTIONS;
      case 'weekly':
        return DAY_OF_WEEK_DROPDOWN;
      case 'monthly':
        return MONTHLY_FREQUENCY_OPTIONS;
      case 'quarterly':
        return ANALYTICS_QUARTERLY_FREQUENCY_OPTIONS;
      case 'yearly':
        return YEARLY_FREQUENCY_OPTIONS;
      default:
        return [];
    }
  };

  const canSubmit = editing.every(
    e =>
      e.type?.length && e.frequency && e.sendOn && (e.userId || e.externalEmail)
  );

  return (
    <Modal
      title={`${exceptionType.charAt(0).toUpperCase() +
        exceptionType.slice(1)} Report`}
      titleClassName="blueHeader"
      isOpen={isOpen}
      submitButtonColor="blue"
      submitButtonText="Update"
      onRequestClose={() => {
        onRequestClose();
        setEditing([]);
      }}
      wide
      submitActions={() => (canSubmit ? submitActions(editing) : null)}
      onMouseEnter={() => setMissingRequired(!canSubmit)}
      submitTooltip={!canSubmit ? 'Missing or Invalid Fields' : null}
    >
      <div className={styles.topMessage}> {topMessage}</div>
      <EmployeeDropdown
        multi
        fieldLabel="Send to user"
        searchable
        permissions={[400, 500, 900]}
        exclude={[...editing.map(s => s.userId)]}
        placeholder="Select user(s) to schedule a report for"
        selectButtons
        onChange={userIds => {
          setEditing(current => [
            ...(current ?? []),
            ...userIds.map(userId => {
              return {
                userId,
                type: ['csv'],
                companyId: company?._id
              };
            })
          ]);
        }}
      />
      <Input fieldLabel="Reports" className={styles.report}>
        {editing?.map((s, i) => (
          <div key={i}>
            <span
              onClick={() => {
                setEditing(editing.toSpliced(i, 1));
              }}
            >
              &times;
            </span>
            <UserBadge userId={s.userId} showName showImage />
            <TwoColumn className={styles.specialGrid}>
              <Dropdown
                searchable
                selectButtons
                multi
                options={titles}
                currentValue={s.templates}
                onChange={v => {
                  let updated = [...editing];
                  updated[i].templates = v;
                  setEditing(updated);
                }}
                bareValues={bareValuesTemplateDropdown}
                touched={missingRequired && !s?.templates?.length}
                isRequired
              />
              <Dropdown
                options={[
                  { value: 'daily', label: 'Daily' },
                  ...FREQUENCY_OPTIONS
                ]}
                currentValue={s.frequency}
                onChange={frequency => {
                  let updated = [...editing];
                  updated[i].frequency = frequency;
                  updated[i].sendOn = null;
                  setEditing(updated);
                }}
                touched={missingRequired && !s?.frequency}
                isRequired
              />
              <Dropdown
                disabled={!s.frequency}
                options={getOptions(s.frequency)}
                currentValue={s.sendOn}
                onChange={sendOn => {
                  let updated = [...editing];
                  updated[i].sendOn = sendOn;
                  setEditing(updated);
                }}
                valueKey={s.frequency === 'weekly' ? 'label' : 'value'}
                touched={missingRequired && !s?.sendOn}
                isRequired
              />
            </TwoColumn>
          </div>
        ))}
      </Input>
    </Modal>
  );
}
