import { call, put, select } from 'redux-saga/effects';
import LogRocket from 'logrocket';
import config from '../config/config';
import history from '../history';
import { fetchCustomReportQuestions } from '../api/v4';
import { addUploadedAttachmentsResponse } from '../actions/attachments';
import {
  authSuccess,
  loginFailure,
  setSecurityQuestions,
  signOut
} from '../actions/auth';
import {
  fetchUserCompaniesResponse,
  setActiveCompany,
  setActiveLocation
} from '../actions/company';
import {
  fetchIncidentBasicsResponse,
  setActiveIncidentResponse
} from '../actions/incidents';
import { addMessage } from '../actions/messages';
import { setActiveEmployeeResponse } from '../actions/personnel';
import { loadUserResponse } from '../actions/user';
import { createReportResponse } from '../actions/reports';
import { setActiveSafetyWalk } from '../actions/safetyWalks';
import { loadTask } from '../actions/tasks';
import { fetchUserProfile } from '../actions/user';
import { getLoggedInUser } from '../selectors/users';
import {
  INVESTIGATION_COMPONENTS,
  ACTION_COMPONENTS,
  CLOSURE_COMPONENTS
} from '../constants/constants';
import { isConfidentialReport } from '../utils/incidentHelper';

export function* logInUser(apiV4, { payload }) {
  try {
    const referrer = payload.referrer;
    delete payload.referrer;

    const responseData = payload.isMicrosoft
      ? yield call(apiV4.userMicrosoftLogin, payload)
      : yield call(apiV4.loginUser, payload);
    // localStorage.clear();
    sessionStorage.setItem('token', responseData.token);
    sessionStorage.setItem('user', JSON.stringify(responseData.user));
    const response = responseData.user;
    response.securityQuestions = responseData.securityQuestions;

    yield put(authSuccess());
    yield put(fetchUserProfile(response));
    try {
      LogRocket.identify(response.username, {
        ...response
      });
    } catch (err) {
      console.log(err);
    }
    let companies = responseData.companies;

    companies.map(res => {
      res.allLocations = res.groups;
      res.locations = res.groups;
      res.allGroupsHidden =
        res?.groups?.length && res?.groups?.every(g => g.isHidden);
      return res;
    });

    const companyNames = companies.map(company => company.name);
    localStorage.setItem('companies', companyNames.join(', '));

    yield put(fetchUserCompaniesResponse(companies));

    yield setActiveLocations(
      responseData.user.accessLevel,
      companies,
      companies[0]?._id,
      companies[0]?.groups[0]?._id
    );

    if (responseData.user.needsToUpdatePassword) {
      history.push({
        pathname: '/home',
        state: { fromScreen: 'login' }
      });
    } else {
      yield handleEmailLinks(
        apiV4,
        referrer,
        companies,
        responseData.user.accessLevel
      );
    }
  } catch (e) {
    yield put(loginFailure(e));
  }
}

export function* validateSecurityQuestions(apiV4, { payload }) {
  try {
    const responseData = yield call(apiV4.validateSecurityQuestions, payload);
    // localStorage.clear();
    sessionStorage.setItem('token', responseData.token);

    const user = responseData.user;
    user.securityQuestions = responseData.securityQuestions;
    yield put(fetchUserProfile(user));

    let companies = responseData.companies;

    companies.map(res => {
      res.allLocations = res.groups;
      res.locations = res.groups;
      return res;
    });

    const companyNames = companies.map(company => company.name);
    localStorage.setItem('companies', companyNames.join(', '));

    yield put(fetchUserCompaniesResponse(companies));

    history.push('/home');
  } catch (e) {
    yield put(signOut());
    history.push('/login');
  }
}

export function* forgotPasswordReset(apiV4, { payload }) {
  try {
    const response = yield call(apiV4.forgotPasswordReset, payload.username);
    if (Array.isArray(response)) {
      yield put(setSecurityQuestions(response));
      history.push('/login');
    } else {
      yield put(
        addMessage({
          error: false,
          message: 'Email has been successfully sent!'
        })
      );
      history.push('/login');
    }
  } catch (e) {
    console.log('e', e);
  }
}

export function* updateUser(apiV4, { payload }) {
  try {
    const loggedInUser = yield select(getLoggedInUser);

    if (payload.data) {
      let profilePictureUrl = yield call(apiV4.uploadAttachment, payload.data, {
        ownerId: loggedInUser._id,
        ownerType: 'userProfile'
      });

      payload.profilePictureUrl = profilePictureUrl[0].source_url;
    }

    payload._id = loggedInUser._id;
    delete payload.data;
    if (payload.notifications === '') delete payload.notifications;
    delete payload?.notifications?.expoPushNotificationId;

    let response = yield call(apiV4.updateEmployee, payload);

    yield put(setActiveEmployeeResponse(response));
    yield put(fetchUserProfile(response));
    yield put(loadUserResponse(response));

    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e?.data ?? e }));
  }
}

export function* updatePassword(apiV4, { payload }) {
  try {
    const activeUser = yield select(getLoggedInUser);

    yield call(apiV4.updatePassword, activeUser._id, payload);
    yield put(
      addMessage({ error: false, message: 'Successfully Updated Password' })
    );
    activeUser.needsToUpdatePassword = false;

    yield put(fetchUserProfile(activeUser));
  } catch (e) {}
}

const isValidObjectId = id => {
  const idRegex = /\d/g;
  const validIdLength = 24;
  return id && idRegex.test(id) && id.length === validIdLength;
};

function* setActiveLocations(accessLevel, companies, companyId, groupId) {
  const company = companies.find(c => c._id === companyId) || companies[0];

  yield put(setActiveCompany(company));
  if (accessLevel !== 900) {
    const group = company.groups.find(g => g._id === groupId);
    yield put(setActiveLocation(group));
  }
}

function* handleEmailLinks(apiV4, referrer, companies, accessLevel) {
  const referrerUrl = referrer ? referrer.split('/') : [];
  const loggedInUser = yield select(getLoggedInUser);

  if (referrerUrl?.length > 0) {
    const id = referrerUrl[referrerUrl.length - 1];

    if (!isValidObjectId(id)) {
      history.push({
        pathname: '/home',
        state: { fromScreen: 'login' }
      });
      return;
    }

    // this makes sure that if you hit the back button you wont get redirected to the login screen
    history.push(`/app/dashboard`);

    if (referrerUrl.includes('taskContainer')) {
      const response = yield call(apiV4.fetchTaskById, id);

      if (response.isArchived && loggedInUser.accessLevel < 500) {
        history.push({
          pathname: '/app/dashboard',
          state: { fromScreen: 'login' }
        });
        yield put(
          addMessage({
            error: true,
            message: `This ${
              response.type === 'correctiveAction'
                ? 'Corrective Action'
                : 'Task'
            } has been archived and must be un-archived by an Administrator or Collaborator in order to be accessed.`
          })
        );
      } else {
        yield setActiveLocations(
          accessLevel,
          companies,
          response?.companyId,
          response?.groupId
        );
        yield put(loadTask(id));
      }
    }
    if (referrerUrl.includes('trainingCourses')) {
      try {
        const course = yield call(
          apiV4.getTrainingCourseById,
          id,
          loggedInUser._id
        );

        yield setActiveLocations(
          accessLevel,
          companies,
          course?.companyId,
          course?.groupIds
        );
        if (referrerUrl.includes('view')) {
          history.push(`/app/trainingCourses/view/${id}`);
        } else {
          history.push(`/app/trainingCourses/create/${id}`);
        }
      } catch (error) {
        history.push({
          pathname: '/app/dashboard',
          state: { fromScreen: 'login' }
        });
        yield put(
          addMessage({
            error: true,
            message:
              'Error getting Training Course, make sure it was not deleted'
          })
        );
      }
    } else if (
      referrerUrl.includes('training') ||
      referrerUrl.includes('Training')
    ) {
      const training = yield call(apiV4.fetchTrainingById, id);

      yield setActiveLocations(
        accessLevel,
        companies,
        training?.companyId,
        training?.groupIds
      );
      if (referrerUrl.includes('perform')) {
        history.push(`/app/training/perform/${id}`);
      } else if (referrerUrl.includes('trainingSummary')) {
        history.push(`/app/training/view/${id}`);
      } else if (referrerUrl.includes('approve')) {
        history.push({
          pathname: `/app/training/approve/${id}`,
          state: {
            isApproving: true
          }
        });
      } else if (referrerUrl.includes('view')) {
        history.push({
          pathname: `/app/training/view/${id}`
        });
      }
    } else if (
      referrerUrl.includes('incidentContainer') ||
      referrerUrl.includes('initialReportContainer')
    ) {
      const incidentResponse = yield call(apiV4.fetchIncidentById, id);
      const isConfidential = isConfidentialReport(incidentResponse);
      const userPermissions = incidentResponse.permissions.find(
        p => p.userId === loggedInUser._id
      );
      if (
        !userPermissions ||
        (userPermissions.investigation === 'None' &&
          userPermissions.action === 'None' &&
          userPermissions.closure === 'None')
      ) {
        yield put(
          addMessage({
            error: true,
            message: 'You do not have permisisons to view this incident'
          })
        );
        return;
      }

      yield setActiveLocations(
        accessLevel,
        companies,
        incidentResponse?.companyId,
        incidentResponse?.locationId
      );

      yield put(
        setActiveIncidentResponse({
          ...incidentResponse,
          backToDashboard: true
        })
      );

      yield put(
        addUploadedAttachmentsResponse(
          incidentResponse.incidentActivity.attachments.filter(
            a => !a.isRemoved
          )
        )
      );

      let incidentBasics = yield call(apiV4.fetchIncidentBasics);

      if (incidentResponse?.reportTemplateIds.includes('19')) {
        let index = incidentBasics[0]?.fields.findIndex(
          f => f._id?.toString() === config.staticIds.incidentSupervisor
        );
        incidentBasics[0].fields[index].hide = true;
      }

      if (!incidentResponse.reportTemplateIds.includes('17')) {
        let index = incidentBasics[0]?.fields.findIndex(
          f => f._id?.toString() === config.staticIds.confidentialReport
        );
        incidentBasics[0].fields[index].hide = true;
      } else {
        let index = incidentBasics[0]?.fields.findIndex(
          f => f._id?.toString() === config.staticIds.incidentSupervisor
        );
        incidentBasics[0].fields[index].hide = isConfidential;
      }

      let reportBasics = yield call(
        apiV4.findIncidentQuestionsByReportType,
        incidentResponse.reportTemplateIds
      );

      let customQuestions = yield call(
        fetchCustomReportQuestions,
        incidentResponse.reportTemplateIds,
        'Report Basics'
      );

      customQuestions = customQuestions?.reduce((acc, x) => acc.concat(x), []);
      let sections = [];

      if (customQuestions.length > 0) {
        let customQuestionFields = [];

        customQuestions.forEach(custom =>
          customQuestionFields.push(...custom.fields)
        );

        reportBasics.forEach(rb => {
          if (Array.isArray(rb)) {
            rb.forEach(r => sections.push(r));
          } else {
            sections.push(rb);
          }
        });

        sections = [
          ...sections,
          {
            label: 'Custom Questions',
            fields: customQuestionFields
          }
        ];
      } else {
        reportBasics.forEach(rb => {
          if (Array.isArray(rb)) {
            rb.forEach(r => sections.push(r));
          } else {
            sections.push(rb);
          }
        });
      }

      if (incidentResponse.reportTemplateIds.indexOf('17') > -1) {
        let basicsIndex = reportBasics?.findIndex(
          rb => rb.templateNumber === '17'
        );

        if (
          incidentResponse.answers[config.staticIds.werePoliceCalled]?.label ===
          'Yes'
        ) {
          let timeIndex = reportBasics?.[basicsIndex]?.fields?.findIndex(
            f => f.label === 'Time police were called?'
          );
          let arrivedIndex = reportBasics?.[basicsIndex]?.fields?.findIndex(
            f => f.label === 'Time police arrived?'
          );

          reportBasics[basicsIndex].fields[timeIndex].hide = false;
          reportBasics[basicsIndex].fields[arrivedIndex].hide = false;
        }
        if (
          incidentResponse.answers[config.staticIds.wereEmergencyCalled]
            ?.label === 'Yes'
        ) {
          let timeIndex = reportBasics?.[basicsIndex]?.fields?.findIndex(
            f => f.label === 'Time emergency personnel were called'
          );
          let arrivedIndex = reportBasics?.[basicsIndex]?.fields?.findIndex(
            f => f.label === 'Time emergency personnel arrived?'
          );
          reportBasics[basicsIndex].fields[timeIndex].hide = false;
          reportBasics[basicsIndex].fields[arrivedIndex].hide = false;
        }
        if (
          incidentResponse.answers[config.staticIds.otherViolence]?.findIndex(
            a => a.label === 'Other'
          ) > -1
        ) {
          let index = reportBasics?.[basicsIndex]?.fields?.findIndex(
            f => f.label === 'Other'
          );
          reportBasics[basicsIndex].fields[index].hide = false;
        }
      }

      if (incidentResponse.reportTemplateIds.indexOf('18') > -1) {
        let basicsIndex = reportBasics?.findIndex(
          rb => rb.templateNumber === '18'
        );

        let enviromentImpacted =
          incidentResponse.answers[config.staticIds.enviromentImpacted];
        let waterIndex = reportBasics?.[basicsIndex]?.fields?.findIndex(
          f =>
            f.label ===
            'Specify name of waterway and what is the length of area of waterway affected'
        );
        let landIndex = reportBasics?.[basicsIndex]?.fields?.findIndex(
          f =>
            f.label ===
            'Specify ground surface area in square feet or yards and depth of soil'
        );

        reportBasics[basicsIndex].fields[
          waterIndex
        ].hide = !enviromentImpacted?.includes('Water');
        reportBasics[basicsIndex].fields[
          landIndex
        ].hide = !enviromentImpacted?.includes('Land');
      }

      incidentResponse.sections = [...incidentBasics, ...sections];

      yield put(fetchIncidentBasicsResponse(incidentResponse));

      if (referrerUrl.includes('initialReportContainer'))
        history.push({
          pathname: '/app/initalReportContainer',
          state: { isSigning: true }
        });
      else history.push('/app/incidentContainer');
    } else if (referrerUrl.includes('safetyWalkContainer')) {
      const swResponse = yield call(apiV4.fetchSafetyWalkById, id);

      if (swResponse.isArchived && accessLevel < 500) {
        history.push({
          pathname: '/app/dashboard',
          state: { fromScreen: 'login' }
        });

        yield put(
          addMessage({
            error: true,
            message:
              'This Safety Walk has been archived and must be un-archived by an Administrator or Collaborator in order to be accessed.'
          })
        );
      } else {
        yield setActiveLocations(
          accessLevel,
          companies,
          swResponse?.companyId,
          swResponse?.groupId
        );

        yield put(
          setActiveSafetyWalk({
            ...swResponse,
            backToDashboard: true
          })
        );
        history.push('/app/safetyWalkContainer');
      }
    } else if (referrerUrl.includes('ViewEdit')) {
      const docResponse = yield call(apiV4.fetchDocumentById, id);

      yield setActiveLocations(
        accessLevel,
        companies,
        docResponse?.companyId,
        docResponse?.groupId
      );

      if (referrerUrl.includes('Safety Procedure')) {
        history.push('/app/Safety Procedure');
      } else if (
        referrerUrl.includes(`safetyInstructionContainer/${docResponse?._id}`)
      ) {
        history.push(`/app/safetyInstructionContainer/${docResponse?._id}`);
      } else if (referrerUrl.includes(`Qualifications`)) {
        history.push(`/app/qualificationContainer/${docResponse?._id}`);
      } else {
        history.push(`/app/sdsContainer/${docResponse?._id}`);
      }
    } else if (referrerUrl.includes('reportSectionContainer')) {
      const taskResponse = yield call(apiV4.fetchTaskById, id);
      const incidentResponse = yield call(
        apiV4.fetchIncidentById,
        taskResponse.ownerId
      );

      yield setActiveLocations(
        accessLevel,
        companies,
        taskResponse?.companyId,
        taskResponse?.groupId
      );

      const activeReport = incidentResponse.reportComponents.filter(
        reportComponent =>
          reportComponent[0] === taskResponse.reportComponentName
      );
      const label = taskResponse.label;
      if (INVESTIGATION_COMPONENTS.some(v => label.includes(v))) {
        activeReport.stage = 'investigation';
      } else if (ACTION_COMPONENTS.some(v => label.includes(v))) {
        activeReport.stage = 'action';
      } else if (CLOSURE_COMPONENTS.some(v => label.includes(v))) {
        activeReport.stage = 'closure';
      }

      yield put(setActiveIncidentResponse(incidentResponse));

      yield put(createReportResponse(activeReport));
      history.push('/app/reportSectionContainer');
    } else if (
      referrerUrl.includes('safetyAudits') ||
      referrerUrl.includes('audits')
    ) {
      const safetyAudit = yield call(apiV4.fetchSafetyAuditById, id);

      if (safetyAudit.isArchived && accessLevel < 500) {
        const auditTypeName = () => {
          if (safetyAudit.type === 'safety') return 'Safety Audit';
          else if (safetyAudit.type === 'quality') return 'Quality Audit';
          else return 'Lockout/Tagout Procedure';
        };
        history.push({
          pathname: '/app/dashboard',
          state: { fromScreen: 'login' }
        });
        yield put(
          addMessage({
            error: true,
            message: `This ${auditTypeName()} has been archived and must be un-archved by an Administrator or Collaborator in order to be accessed.`
          })
        );
      } else {
        yield setActiveLocations(
          accessLevel,
          companies,
          safetyAudit?.companyId,
          safetyAudit?.groupId
        );

        if (referrerUrl.includes('safety')) {
          if (referrerUrl.includes('summary')) {
            history.push(`/app/audits/safety/summary/${id}`);
          } else if (referrerUrl.includes('perform')) {
            const audit = yield call(apiV4.fetchSafetyAuditById, id);
            audit?.isCompleted
              ? history.push(`/app/audits/safety/summary/${id}`)
              : history.push(`/app/audits/safety/perform/${id}`);
          } else {
            history.push('/home');
          }
        } else if (referrerUrl.includes('quality')) {
          if (referrerUrl.includes('summary')) {
            history.push(`/app/audits/quality/summary/${id}`);
          } else if (referrerUrl.includes('perform')) {
            const audit = yield call(apiV4.fetchSafetyAuditById, id);
            audit?.isCompleted
              ? history.push(`/app/audits/quality/summary/${id}`)
              : history.push(`/app/audits/quality/perform/${id}`);
          } else {
            history.push('/home');
          }
        } else if (referrerUrl.includes('loto')) {
          if (referrerUrl.includes('summary')) {
            history.push(`/app/audits/loto/summary/${id}`);
          } else if (referrerUrl.includes('perform')) {
            const audit = yield call(apiV4.fetchSafetyAuditById, id);
            audit?.isCompleted
              ? history.push(`/app/audits/loto/summary/${id}`)
              : history.push(`/app/audits/loto/perform/${id}`);
          } else {
            history.push('/home');
          }
        }
      }
    } else if (referrerUrl.includes('witnessStatements')) {
      const witnessStatements = yield call(
        apiV4.fetchWitnessStatement,
        id,
        false
      );
      yield setActiveLocations(
        accessLevel,
        companies,
        witnessStatements?.companyId,
        witnessStatements?.groupId
      );

      history.push(`/app/witnessStatements/${id}`);
    } else if (referrerUrl.includes('behaviorObservations')) {
      // need to set company correctly to the template and group dropdown populate
      const response = yield call(apiV4.fetchBehaviorObservationById, id);

      if (response.isArchived && accessLevel < 500) {
        history.push({
          pathname: '/app/dashboard',
          state: { fromScreen: 'login' }
        });
        yield put({
          error: true,
          message:
            'This Behavior Observation has been archived and must be un-archived by an Administrator or Collaborator in order to be accessed.'
        });
      } else {
        yield setActiveLocations(
          accessLevel,
          companies,
          response?.companyId,
          response?.groupId
        );

        history.push(`/app/behaviorObservation/container/${id}`);
      }
    } else if (referrerUrl.includes('announcement')) {
      history.push({
        pathname: `/app/announcement/container/${id}`,
        state: { fromToast: true }
      });
    } else if (referrerUrl.includes('safetySuggestions')) {
      const response = yield call(apiV4.fetchSafetySuggestion, id);

      yield setActiveLocations(
        accessLevel,
        companies,
        response?.companyId,
        response?.groupId
      );

      history.push(`/app/safetySuggestions/${id}`);
    } else if (referrerUrl.includes('recurrenceContainer')) {
      const cc = yield call(apiV4.fetchContinuousCareById, id);
      yield setActiveLocations(
        accessLevel,
        companies,
        cc?.companyId,
        cc?.groupId
      );
      history.push({
        pathname: `/app/recurrenceContainer/${id}`,
        state: { isAssignment: true }
      });
    } else if (referrerUrl.includes('transitionContainer')) {
      const cc = yield call(apiV4.fetchContinuousCareById, id);
      yield setActiveLocations(
        accessLevel,
        companies,
        cc?.companyId,
        cc?.groupId
      );
      history.push({
        pathname: `/app/transitionContainer/${id}`,
        state: { isAssignment: true }
      });
    } else {
      history.push({
        pathname: '/home',
        state: { fromScreen: 'login' }
      });
    }
  } else {
    history.push({
      pathname: '/home',
      state: { fromScreen: 'login' }
    });
  }
}
